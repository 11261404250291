import heroImage from '../assets/images/other/home-signing.jpg';
import soldSignImage from '../assets/images/other/sold-sign.jpg';

export const data = {
    viewTitle: 'About Us',
    mainHeading: 'Get the Best Results with Bold Realty',
    hero: {
        image: heroImage,
        imageAlt: 'Documents being signed for home transaction',
        spokenHeadline: 'With You Every Step of the Way',
        headline: 'With You Every Step of the Way',
        headlinePos: 'bottomLeft',
        headlineColor: '#04386D',
        headlineShadowColor: '#FFFFFF'
    },
    sections: {
        "sect001": {
            meta: 'paragraph',
            heading: 'Why Your Next Move Should Be a Bold Move',
            paragraphs: {
                "para001": {
                    content: 'If you are in the market to buy or sell a home you’ve come to the right place. Our website contains a great wealth of information for home buyers and sellers along with frequently asked questions.'
                },
                "para002": {
                    content: 'Keep in mind however, that no real estate site, no matter how detailed can replace the personal guidance and advice of a real estate professional. As experts in the greater Phoenix real estate market, Bold Realty can provide you with information customized to fit your personal home buying and selling needs.'
                },
                "para003": {
                    content: 'Buying or selling a home is one of the biggest events in your life. As top REALTORS® with more than two decades of experience, Bold Realty is committed to delivering elite performance so you can experience Bold results.'
                }
            },
            subsections: {
                "subsection001": {
                    meta: 'paragraph',
                    heading: 'Our Mission Statement',
                    paragraphs: {
                        "para001": {
                            content: 'To build a successful business by exceeding client expectations—one home at a time.'
                        }
                    }
                },
                "subsection002": {
                    meta: 'paragraph',
                    heading: 'Our Vision',
                    paragraphs: {
                        "para001": {
                            content: 'To be THE real estate professionals that best delivers an elite client experience through communication, responsiveness and expertise—every time.'
                        }
                    }
                },
                "subsection003": {
                    meta: 'unorderedList',
                    heading: 'Our Values',
                    unorderedList: {
                        "listItem001": {
                            content: 'Integrity is essential'
                        },
                        "listItem002": {
                            content: 'Reputation matters -- always '
                        },
                        "listItem003": {
                            content: 'Profitability is critical'
                        },
                        "listItem004": {
                            content: 'Service is required'
                        },
                        "listItem005": {
                            content: 'Communication is paramount'
                        }
                    }
                }
            }
        }
    },
    testimonialCarousel: {
        leadText: 'From My Clients...'
    },
    contactUsBlock: {
        contactUsImage: {
            image: soldSignImage,
            imageAlt: 'home sold sign'
        },
        contactUsSpan1: 'Thank you for your interest in our services. Our specialists can help evaluate your needs and identify the appropriate solution. All consultations are free and last approximately 30 to 90 minutes depending on your unique needs. ',
        contactUsButtonText: 'Contact Us',
        screenReaderText: 'Contact Us Button. Click this button to navigate to our Contact Us form.',
        contactUsSpan2: ' to schedule a meeting with one of our professional partners.'
    }
}
