import React, { useState, useRef } from 'react';

import Logo from '../Logo/Logo';
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import DrawerToggle from '../Navigation/SideDrawer/DrawerToggle/DrawerToggle';
import SideDrawer from '../Navigation/SideDrawer/SideDrawer';
import classes from './Header.module.css';

const Header = (props) => {
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    const drawerToggleRef = useRef();

    const toggleSideDrawer = () => {
        setShowSideDrawer( (showSideDrawer) => !showSideDrawer);
        focusDrawerToggle();
    }

    const closeSideDrawer = () => {
        setShowSideDrawer(false);
        focusDrawerToggle();
    }

    const focusDrawerToggle = () => {
        drawerToggleRef.current.focus();
    }

    const headerClasses = [classes.Header];
    if (props.showSkipLinks) {
        headerClasses.push(classes.PushDown);
    }
    if (props.hide) {
        headerClasses.push(classes.Hide);
    }

    return (
        <>
            <header className={headerClasses.join(' ')}>
                <Logo size='small' />
                <a className={classes.PhoneContact} href={"tel:602-373-0115"}>Call Us at 602-373-0115</a>
                <div className={classes.DesktopOnly}>
                    <NavigationItems />
                </div>
                <div className={classes.HamburgerMenu}>
                    <DrawerToggle toggledOpen={showSideDrawer} ref={drawerToggleRef} clicked={toggleSideDrawer} />
                </div>
            </header>
            <SideDrawer show={showSideDrawer} close={closeSideDrawer} />
        </>
    );
}

export default Header;
