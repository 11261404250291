import React from 'react';

import classes from './Hero.module.css';

const Hero = ( props ) => {
    const { image, imageAlt, headline, spokenHeadline, headlinePos, headlineColor, headlineShadowColor } = props;

    const defaultHeadlineColor = '#d59e59';
    const defaultHeadlineShadowColor = '#04386D';

    const textShadowCss =
     `-1px 0 1px ${headlineShadowColor},
     0 1px 1px ${headlineShadowColor},
     1px 0 1px ${headlineShadowColor},
     0 -1px 1px ${headlineShadowColor}`;

    const defaultTextShadowCss =
    `-1px 0 1px ${defaultHeadlineShadowColor},
    0 1px 1px ${defaultHeadlineShadowColor},
    1px 0 1px ${defaultHeadlineShadowColor},
    0 -1px 1px ${defaultHeadlineShadowColor}`;

    let overlayClasses = [classes.Overlay];

    if (headlinePos === 'topLeft') {
        overlayClasses.push(classes.TopLeftPos);
    }
    else if (headlinePos === 'bottomLeft') {
        overlayClasses.push(classes.BottomLeftPos);
    }
    else if (headlinePos === 'topRight') {
        overlayClasses.push(classes.TopRightPos);
    }
    else if (headlinePos === 'bottomRight') {
        overlayClasses.push(classes.BottomRightPos);
    }

    return (
        <div className={classes.Hero}>
                <span
                className={overlayClasses.join(' ')}
                    aria-label={spokenHeadline}
                    style={{ color: `${headlineColor ? headlineColor : defaultHeadlineColor}`,
                            textShadow: `${headlineShadowColor ? textShadowCss : defaultTextShadowCss}` }}>
                    {headline}
                </span>

            <img src={image} alt={imageAlt} />
        </div>
    )
}

export default Hero;
