import React, { forwardRef } from 'react';

import TeamCard from './TeamCard/TeamCard';
import { teamData } from '../../../data/Team';
import classes from './Team.module.css';

const Team = forwardRef((props, teamRef) => {
    return (
        <div
            className={classes.Team}
            ref={teamRef}
            aria-label={teamData.heading}
        >
            <h2>{teamData.heading}</h2>
            <div className={classes.TeamList}>
                {teamData.teamDetails.map( (memberDetails) =>
                    (
                        <TeamCard
                            memberDetails={memberDetails}
                            key={memberDetails.id}
                        />
                    )
                )}
            </div>
        </div>
    );
})

export default Team;
