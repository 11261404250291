import React, { forwardRef } from 'react';

import classes from './MainContent.module.css';

const MainContent = forwardRef(( props, mainRef ) => {
    const mainClasses = [classes.MainContent];

    if (props.showSkipLinks) {
        mainClasses.push(classes.PushDown);
    }

    return (
        <div
            className={mainClasses.join(' ')}
            ref={mainRef}
            aria-label='main content'
        >
            {props.children}
        </div>
    );
})

export default MainContent;
