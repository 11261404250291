import React from 'react';

import { Link } from 'react-router-dom';
import companyLogo from '../../assets/images/logos/Bold-Realty-Logo-transparent.png';

import classes from './Logo.module.css';

function Logo(props) {

    const logoClasses = [classes.Logo];
    if (props.size === 'large') {
        logoClasses.push(classes.Large);
    }
    else if (props.size === 'small') {
        logoClasses.push(classes.Small);
    }

    return (
        <div className={logoClasses.join(' ')}>
            <Link
                to='/'
                aria-label={'Bold Realty logo - press Enter key to navigate to the home page'}
            >
                <img src={companyLogo} alt="Bold Realty company logo" />
            </Link>
        </div>
    )
}

export default Logo;
