import React, { forwardRef } from 'react';

import classes from './SkipLinks.module.css';

const SkipLinks = forwardRef(( props, skipLinksRef ) => {
    const skipLinksClasses = [classes.SkipLinks];
    if (props.showSkipLinks) {
        skipLinksClasses.push(classes.Show);
    }

    return (
        <>
            <ul
                className={skipLinksClasses.join(' ')}
                ref={skipLinksRef}
            >
                {
                    Object.keys(props.config).map((configKey, configIdx) => {
                        const linkConfig = props.config[configKey];
                        return (
                            <li key={configIdx}>
                                <a
                                    href={linkConfig.href}
                                    onClick={(event) => props.skipLinkClicked(event, linkConfig.targetRef)}
                                    onFocus={props.handleShowSkipLinks}
                                    onBlur={props.handleHideSkipLinks}
                                >
                                    {linkConfig.name}
                                </a>
                            </li>
                        );
                    })
                }
            </ul>
            {
                props.showSkipLinks
                    ? <div className={classes.Backdrop}></div>
                    : null
            }
        </>
    );
});

export default SkipLinks;
