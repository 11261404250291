import React from 'react';

const SharedIcon = (
    {
        iconHeight,
        iconWidth,
        iconViewBox,
        path,
        iconFill,
        classNames = []
    }
) => {

    return (
        <svg
            className={classNames.join(' ')}
            height={iconHeight}
            width={iconWidth}
            viewBox={iconViewBox}
            role="img"
            focusable="false"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d={path}
                fill={iconFill}
            />
        </svg>
    );
}

export default SharedIcon;
