import React, { useState, forwardRef } from 'react';

import classes from './Pagination.module.css';

const Pagination = forwardRef(( {
    navigateToId,
    itemCountPerPage = 4,
    items,
    pageButtonClicked,
    name
}, itemSelectedRef ) => {
    const targetPageNumber = (navigateToId)
        ? Math.ceil(navigateToId / itemCountPerPage)
        : 1;
    let targetListIndex = navigateToId % itemCountPerPage;
    if (targetListIndex === 0) {
        targetListIndex = itemCountPerPage;
    }

    const [currentPageNum, setCurrentPageNum] = useState(targetPageNumber);

    const numberOfPages = Math.ceil(items.length / itemCountPerPage);
    const multiplePages = (numberOfPages > 1);

    let currentPageLastItemIndex;
    let currentPageFirstItemIndex;

    if (multiplePages) {
        currentPageLastItemIndex = currentPageNum * itemCountPerPage;
        currentPageFirstItemIndex = currentPageLastItemIndex - itemCountPerPage;
    }
    else {
        currentPageLastItemIndex = items.length;
        currentPageFirstItemIndex = 0;
    }
    const currentItemsDisplayed = items.slice(currentPageFirstItemIndex, currentPageLastItemIndex);

    const pageNumbers = [];
    for (let i = 1; i <= numberOfPages; i++) {
        pageNumbers.push(i);
    }

    const handleClickPageButton = (pageNumber) => {
        pageButtonClicked(() => setCurrentPageNum(pageNumber));
    }

    return (
        <div
            className={classes.Pagination}
            aria-label={name}
        >
            <ul className={classes.Items}>
                {
                    currentItemsDisplayed.map((item, idx) => {
                        const itemIdx = idx + 1; // idx starts at zero
                        return (
                            <li
                                key={idx}
                                ref={(itemIdx === targetListIndex) ? itemSelectedRef : null}
                            >
                                {item}
                            </li>
                        )
                    })
                }
            </ul>
            {multiplePages &&
                <div className={classes.PageButtons}>
                <span>Page</span> {
                    pageNumbers.map( (pageNumber) => {
                        return (
                            <button
                                key={pageNumber}
                                onClick={(event) => handleClickPageButton(pageNumber)}
                                className={[classes.PageButton, (currentPageNum === pageNumber) ? classes.SelectedPage : null].join(' ')}
                                // aria-current={(currentPageNum === pageNumber) ? 'page' : 'false'}
                                aria-label={`page number ${pageNumber} of ${numberOfPages} pages`}
                            >
                                {pageNumber}
                            </button>
                        )
                    })
                }
            </div>}
        </div>
    );
})

export default Pagination;
