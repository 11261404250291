import React from 'react';
import useScrollTracking from '../../hooks/useScrollTracking';

const ScrollTracker = ({ onScroll }) => {
    useScrollTracking(onScroll);
    return null;
}

const HideableHeader = ({ hasScrollTracking, scrollCallback, children }) => {
    const renderedResult = (hasScrollTracking)
        ? <>
            <ScrollTracker onScroll={scrollCallback} />
            {children}
        </>
        : null;

        return renderedResult;
    }

export default HideableHeader;
