import React from 'react';

import classes from './TextButton.module.css';

function TextButton(
    {
        buttonStyles,
        clicked = () => {},
        screenReaderText = '',
        children
    }
) {
    return (
        <>
            <button
                style={buttonStyles}
                className={classes.TextButton}
                onClick={clicked}
                aria-label={screenReaderText}
            >
                {children}
            </button>
        </>
    );
}

export default TextButton;
