export const testimonialData = [
        {
            id: 1,
            headline: 'Would Not Hesitate to Recommend Caryn',
            review: 'Caryn Kommers successfully facilitated a short sale investment purchase for us with efficiency, mitigating any issues along the way to get us to a prompt closing. During the process she maintained excellent communication with all parties, the highest work ethic, and a professional demeanor, regardless of the obstacles. We would not hesitate to recommend Caryn and will continue to work with her towards future purchases.',
            sourceName: 'Sandy and Mike J.',
            sourceLocation: null
        },
        {
            id: 2,
            headline: 'Doggedness, Diligence, and Resolution',
            review: 'If I were to write a Thesaurus one of my first synonym entries would be: Caryn Kommers or the qualities of Persistence, Perseverance, Determination, Doggedness, Diligence, and Resolution. If I were to go to war, I’d want Caryn Kommers on my side as an ally. And, if I wanted to purchase or sell a real estate property in the greater Phoenix area, I’d want Caryn Kommers to represent my interests. In fact, Caryn Kommers has represented my real estate interests for the past two years in a down market with great success! She is both tenacious and determined to obtain the best deal for those whom she represents – I know this from experience. Without any reservation whatsoever I highly recommend Caryn Kommers for any of your real estate needs.',
            sourceName: 'Fred R.',
            sourceLocation: 'now of Virginia'
        },
        {
            id: 3,
            headline: 'You Are My Heroes',
            review: 'You are my heroes…. You really are the underdog trying to make it all work.',
            sourceName: 'Ann T.',
            sourceLocation: 'Phoenix, Arizona'
        },
        {
            id: 4,
            headline: 'Both Times You Made Our Dreams Come True',
            review: 'Thank you for coming through for us in such timely manner and with great results. Not only did you deliver, but you did it with such proficiency and care. It meant the world to us. We didn’t find that special care in other offices. Our situation was dire. We had a very tight time frame. You handled it just right. This is the second time doing business with you and both times you made our dreams come true.',
            sourceName: 'Susan and Dagmar D.',
            sourceLocation: 'Gilbert, Arizona'
        }
    ]
