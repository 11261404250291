import React from 'react';

import Hero from '../../components/Hero/Hero';
import StickyNavContent from '../../components/StickyNavContent/StickyNavContent';

import { data } from '../../data/Buyers';

const Buyers = () => {
    return (
        <>
            <Hero {...data.hero} />
            <StickyNavContent
                view={data.view}
                leadContent={data.leadText}
                mainContent={data.sections}
            />
        </>
    );
}

export default Buyers;
