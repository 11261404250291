import { useEffect } from 'react';
import { useHistory, matchPath } from 'react-router-dom';

const ScrollToTop = ({ normalPageLoad }) => {
    const history = useHistory();

    useEffect(() => {
        const scrollToTopOfView = history.listen(() => {
            const viewResourceMatch = matchPath(history.location.pathname, {
                path: "/:view/:viewResource",
                exact: true,
                strict: false
            });

            if (history.location.hash && history.location.hash.includes('#')) {
                const id = history.location.hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({behavior: 'smooth'});
                }
            }
            else if (viewResourceMatch === null) {
                window.scrollTo(0, 0);
                normalPageLoad();
            }
        });
        return () => {
            scrollToTopOfView();
        }
    // }, [ history.location.pathname, history.location.hash, normalPageLoad ]);
    }, [ history, normalPageLoad ]);

    return null;
}

export default ScrollToTop;
