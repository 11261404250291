import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import classes from './CollapsibleSubmenu.module.css';

function CollapsibleSubmenu( props ) {
    const [sectionNavHovered, setSectionNavHovered] = useState(false);
    const [sectionNavFocused, setSectionNavFocused] = useState(false);
    const [sectionNavMouseDown, setSectionNavMouseDown] = useState(false);
    const { hash } = useLocation();

    let submenuClasses = [classes.Submenu];
    if (props.showSubmenu) {
        submenuClasses.push(classes.Open);
    }

    let iconImage;
    let sectionNavSpanClasses = [classes.SectionNavSpan];

    if (hash === `#${props.data.hash}`) {
        iconImage = props.data.activeIcon;
        sectionNavSpanClasses.push(classes.SectionNavSpanActive);
    }
    else if (sectionNavMouseDown) {
        iconImage = props.data.clickIcon;
        sectionNavSpanClasses.push(classes.SectionNavSpanPressed);
    }
    else if (sectionNavHovered || sectionNavFocused) {
        iconImage = props.data.hoverIcon;
        sectionNavSpanClasses.push(classes.SectionNavSpanHoverFocus);
    }
    else {
        iconImage = props.data.icon;
        sectionNavSpanClasses.push(classes.SectionNavSpanInactive);
    }

    return (
        <div>
            <NavLink
                to={{hash: `#${props.data.hash}`}}
                className={classes.SectionNav}
                onMouseEnter={() => {setSectionNavHovered(true);}}
                onMouseLeave={() => {setSectionNavHovered(false)}}
                onMouseDown={() => {setSectionNavMouseDown(true)}}
                onMouseUp={() => {setSectionNavMouseDown(false)}}
                onDragStart={(event) => {setSectionNavMouseDown(false); event.target.focus()}}
                onFocus={() => setSectionNavFocused(true)}
                onBlur={() => setSectionNavFocused(false)}
                onClick={() => {props.sectionNavClicked()}}
                isActive={() => {
                    return hash === `#${props.data.hash}`;
                }}
                activeClassName={classes.active}
                tabIndex={props.screenReaderVisible ? null : '-1'}
                aria-label={`${props.data.readableName} section`}
                aria-current={(hash === `#${props.data.hash}`) ? 'page' : null}
            >
                <img
                    src={iconImage}
                    alt={props.data.alt}
                    aria-hidden='true'
                />
                <span
                    className={sectionNavSpanClasses.join(' ')}
                    aria-hidden='true'
                >
                    {props.data.displayName}
                </span>
            </NavLink>
            {(Object.keys(props.data.subsections).length > 1) &&
                <ul className={submenuClasses.join(' ')}>
                    {Object.values(props.data.subsections).map( (value, subsectionKey) =>
                        <li key={subsectionKey}>
                            <NavLink
                                to={{hash: `#${value.hash}`}}
                                onClick={props.subsectionNavClicked}
                                isActive={() => {
                                    return hash === `#${value.hash}`;
                                }}
                                activeClassName={classes.active}
                                aria-label={`${value.readableName} section`}
                                aria-current={(hash === `#${value.hash}`) ? 'page' : null}
                            >
                                {value.displayName}
                            </NavLink>
                        </li>
                    )}
                </ul>
            }
        </div>
    );
}

export default CollapsibleSubmenu;
