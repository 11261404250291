import heroImage from '../assets/images/other/family-buying-home.jpg';
import eyeIcon from '../assets/images/icons/icon-eye-FFFFFF.png';
import eyeIconHover from '../assets/images/icons/icon-eye-B09032.png';
import eyeIconActive from '../assets/images/icons/icon-eye-D0B052.png';
import eyeIconClicked from '../assets/images/icons/icon-eye-E0C062.png';
import resourcesIcon from '../assets/images/icons/icon-tools-FFFFFF.png';
import resourcesIconHover from '../assets/images/icons/icon-tools-B09032.png';
import resourcesIconActive from '../assets/images/icons/icon-tools-D0B052.png';
import resourcesIconClicked from '../assets/images/icons/icon-tools-E0C062.png';
import faqsIcon from '../assets/images/icons/icon-help-FFFFFF.png';
import faqsIconHover from '../assets/images/icons/icon-help-B09032.png';
import faqsIconActive from '../assets/images/icons/icon-help-D0B052.png';
import faqsIconClicked from '../assets/images/icons/icon-help-E0C062.png';
import agentQuestionsImage from '../assets/images/other/Questions-to-ask-every-real-estate-agent-before-hiring-them.png';
import questionsToAskEveryRealEstateAgentPdf from '../assets/documents/Questions-To-Ask-Every-Real-Estate-Agent-Before-Hiring-Them.pdf';

export const data = {
    viewTitle: 'Buyer Resources',
    view: 'Buyers',
    hero: {
        image: heroImage,
        imageAlt: 'A family dreaming of buying a home',
        spokenHeadline: 'Make Your Next Move a Bold One',
        headline: 'Make Your Next Move a Bold One',
        headlinePos: 'bottomLeft',
        headlineColor: '#ffff66',
        headlineShadowColor: '#04386D'
    },
    leadText: {
        name: 'Are You Thinking about Buying a New Home?',
        content: {
            cont001: {
                meta: 'paragraph',
                content: [
                    'If you are currently thinking about home ownership you have probably been deluged with the opinions of family, friends and the news about the housing market. We would like to share some of the truths about home ownership.',
                    'While your home isn’t the stock market and home ownership is not usually a realistic avenue to get rich quick, history has proven that over time, real estate has consistently appreciated.',
                    'The number one reason people purchase homes is the pride and freedom of ownership. When a home becomes yours you can paint the walls any color you like and decorate your home to reflect your personal taste. Few landlords are so indulgent.',
                    'Another significant reason people choose to purchase a home is because it’s cheaper than renting. The 2012 Harvard University Joint Center for Housing Studies report stated “At some point, renters are going to realize they’re losing money each month they continue to rent.”',
                    'If home ownership is something that interests you, now may be the time for you to stop dreaming and start acting. With mortgage rates remaining at–or–near historic lows you can afford more house than ever before!  Now may be the time to finally purchase that dream home you’ve had your eye on. It would be our pleasure to offer you a free consultation to help you determine if now—is the right time—for you.'
                ]
            }
        }
    },
    sections: {
        sect001: {
            icon: eyeIcon,
            hoverIcon: eyeIconHover,
            activeIcon: eyeIconActive,
            clickIcon: eyeIconClicked,
            alt: 'Overview',
            displayName: 'Overview',
            readableName: 'Overview',
            hash: 'overview',
            subsections: {
                subsect001: {
                    displayName: 'The Purchase Process',
                    readableName: 'The Purchase Process',
                    hash: 'purchase-process',
                    content: {
                        cont001: {
                            meta: 'heading',
                            content: 'Are You Ready to Shop for a New Home?'
                        },
                        cont002: {
                            meta: 'paragraph',
                            content: [
                                'Studies show that Americans move an average of every seven years. This means that every seven years, people just like you are driving around neighborhoods, checking out schools, walking through complete strangers’ homes, calculating commute times, talking with lenders, and hiring home inspectors—all in search of the perfect home. The process is sometimes long and can on occasion be difficult—however, it is also an experience that can bring with it a lot of excitement and joy when you find your dream home.',
                                'Our job at Bold Realty is to help you experience the excitement while minimizing the anxiety. It has been our experience that clients who understand the process have a better experience than those who do not. To that end, we have prepared this brief summary to help explain the process of purchasing a home.'
                            ]
                        },
                        cont003: {
                            meta: 'heading',
                            content: 'Contact a REALTOR®'
                        },
                        cont004: {
                            meta: 'paragraph',
                            content: [
                                'Bold Realty can help you through the entire process of buying a home, starting with getting qualified for a mortgage and continuing right through closing – and beyond. We can help you shop for the best interest rate and terms and, if you wish, suggest mortgage lenders.'
                            ]
                        },
                        cont005: {
                            meta: 'heading',
                            content: 'Get Preapproved for a Loan'
                        },
                        cont006: {
                            meta: 'paragraph',
                            content: [
                                'Your lender will obtain a copy of your credit report and your FICO score and, if necessary, guide you to do what is needed to improve it. The higher your FICO score, the better interest rate you can command. If you are not currently working with a lender we would be pleased to provide you with referrals to professional and qualified lenders who we routinely trust to provide superior service to our clients.'
                            ]
                        },
                        cont007: {
                            meta: 'heading',
                            content: 'Determine Your Price Range and Area(s) in which you’re Interested'
                        },
                        cont008: {
                            meta: 'paragraph',
                            content: [
                                'After speaking with your lender, you will have a good idea of how much home you can afford. This will help you narrow down your home search. You should also begin researching the neighborhoods in which you might want to live. Your Bold Realty Professional Partner can help.'
                            ]
                        },
                        cont009: {
                            meta: 'heading',
                            content: 'Decide which Amenities and Features you must have – and which ones would be nice but not necessary'
                        },
                        cont010: {
                            meta: 'paragraph',
                            content: [
                                'How many bedrooms and bathrooms do you want? Would you prefer a newer home or an older one with established landscaping? Are tile floors a must? How about granite countertops in the kitchen? Walk-in closets? Lots of storage space? How about a pool? Which features would you be willing to give up if you find the otherwise-perfect home?'
                            ]
                        },
                        cont011: {
                            meta: 'heading',
                            content: 'Begin Testing the Market'
                        },
                        cont012: {
                            meta: 'paragraph',
                            content: [
                                'Right here on BoldRealty.com you can find listings that meet your criteria as well as neighborhood data. Identify properties that seem to be a fit for you, take an afternoon and go on a driving tour. See what’s available in your price range and explore neighborhoods.'
                            ]
                        },
                        cont013: {
                            meta: 'heading',
                            content: 'With Your Agent, Begin Seriously Looking at Homes'
                        },
                        cont014: {
                            meta: 'paragraph',
                            content: [
                                'Your Bold Realty Professional Partner can add to the list of homes you’ve already identified – including ones that have just come on the market. Look at homes with a critical eye – does the floor plan work for you, is the property in good condition, would it be right for your lifestyle? In short, can you imagine yourself and your family living there?  Take notes at each home you visit. What do you like and not like? Narrow down your choices; re-visit homes in which you’re interested. If possible, observe them at different times of the day.'
                            ]
                        },
                        cont015: {
                            meta: 'heading',
                            content: 'Make an Offer'
                        },
                        cont016: {
                            meta: 'paragraph',
                            content: [
                                'When you’ve identified the home you want to buy, be prepared to make an offer quickly. Bold Realty is familiar with market values and will help you arrive at a price that gives your offer the best chance of being accepted. Your Professional Partner will help you negotiate with the seller. Our current market is highly competitive, and it’s always possible your offer may not be accepted for any number of reasons. It is important to have backup homes in mind.'
                            ]
                        },
                        cont017: {
                            meta: 'heading',
                            content: 'Once your offer is accepted:'
                        },
                        cont018: {
                            meta: 'unorderedList',
                            content: [
                                'You’ll be asked to submit an earnest money deposit',
                                'Have the property professionally inspected',
                                'If necessary, request repairs',
                                'Obtain homeowner’s insurance',
                                'Begin making moving arrangements',
                                'Contact utilities (phone, water, power, etc.)',
                                'Arrange with Escrow Company for any funds you will need to bring to closing',
                                'A few days before closing, conduct a final walk-through'
                            ]
                        },
                        cont019: {
                            meta: 'heading',
                            content: 'At Closing:'
                        },
                        cont020: {
                            meta: 'unorderedList',
                            content: [
                                'Make sure the terms and conditions of the loan statement are correct.',
                                'Carefully read everything before you sign.'
                            ]
                        },
                        cont021: {
                            meta: 'boldParagraph',
                            content: 'ENJOY YOUR NEW HOME!'
                        },
                    }
                },
                subsect002: {
                    displayName: 'The Home Loan Process',
                    readableName: 'The Home Loan Process',
                    hash: 'home-loan-process',
                    content: {
                        cont001: {
                            meta: 'heading',
                            content: 'What You Should Know About Your Mortgage Loan Application'
                        },
                        cont002: {
                            meta: 'paragraph',
                            content: [
                                'There are a number of important steps involved in making the dream of homeownership a reality, and one of them is completing your mortgage application. The mortgage application includes several sections that capture information about you, your finances, and details of your potential mortgage. It’s lengthy and at first glance seems complicated.',
                                'Be sure to work with your loan officer to complete the application accurately and completely, and take your time when answering the questions on the application. If you put false or inaccurate information on your mortgage application, it can seriously harm your chances of being approved and it is illegal. All of the personal information on your application is confidential and protected by federal law'
                            ]
                        },
                        cont003: {
                            meta: 'heading',
                            content: 'Important Documents to Complete Your Application'
                        },
                        cont004: {
                            meta: 'paragraph',
                            content: [
                                'You will most likely need the following information to provide to your loan officer in order to complete Sections IV–VI of the mortgage loan application:'
                            ]
                        },
                        cont005: {
                            meta: 'unorderedList',
                            content: [
                                'Paycheck stubs for the past 30 days',
                                'W-2 forms for the past two years',
                                'Information about long-term debts, like car loans, student loans, etc.',
                                'Recent statements from all of your bank accounts',
                                'Tax returns for the past two years if you’re self-employed',
                                'Proof of any supplemental income'
                            ]
                        },
                        cont006: {
                            meta: 'heading',
                            content: 'Pre-Approval, and It’s on to the Next Step'
                        },
                        cont007: {
                            meta: 'paragraph',
                            content: [
                                'Once the application is complete, your loan officer will review it with you and ask you and any co-borrowers to sign it. Your loan officer will then send it through their organization to obtain approvals. If it’s approved, you will receive a pre-approval letter, which is the lender’s conditional commitment to lend you a specific amount of money for the purchase of your home. With that pre-approval, you will know just how much house your can afford to buy.'
                            ]
                        },
                        cont008: {
                            meta: 'heading',
                            content: 'Understanding Your Costs through Estimates, Disclosures, and More'
                        },
                        cont009: {
                            meta: 'paragraph',
                            content: [
                                'Once you have completed the mortgage loan application process, your loan officer will provide you with a variety of documents outlining the costs associated with your loan.'
                            ]
                        },
                        cont010: {
                            meta: 'heading',
                            content: 'The Good Faith Estimate'
                        },
                        cont011: {
                            meta: 'paragraph',
                            content: [
                                'Within three business days of completing the application, your loan officer must provide you with a Good Faith Estimate. The Good Faith Estimate provides you with an estimate of your mortgage loan terms and settlement charges (also called closing charges, or costs to complete your mortgage transaction) if you are approved for a mortgage loan.'
                            ]
                        },
                        cont012: {
                            meta: 'heading',
                            content: 'Truth-in-Lending Disclosure Statement'
                        },
                        cont013: {
                            meta: 'paragraph',
                            content: [
                                'You will also receive a Truth-in-Lending Disclosure Statement within three business days after completing your mortgage loan application. This is where you are able to see the total cost of your mortgage under the terms of your particular mortgage loan.',
                                'The Truth-in-Lending Disclosure Statement contains the most significant characteristics of your mortgage loan: (1) the amount being financed, (2) the annual percentage rate (APR), (3) the finance charge, and (4) the payment schedule.'
                            ]
                        },
                        cont014: {
                            meta: 'heading',
                            content: 'The Commitment Letter'
                        },
                        cont015: {
                            meta: 'paragraph',
                            content: [
                                'After your lender has approved your mortgage loan application, you should receive a commitment letter that specifies the amount of the mortgage loan, the number of years to repay the mortgage loan (the term), the interest rate, the APR, and the monthly charges. You usually must accept the commitment by returning a signed copy to the lender within five to 10 days, and you might have to pay part or all of the origination fees at this time. Once the commitment letter has been received, you are assured the financing needed to complete the purchase of your home and can now focus on completing the details required for closing.'
                            ]
                        },
                        cont016: {
                            meta: 'heading',
                            content: 'The Appraisal Disclosure'
                        },
                        cont017: {
                            meta: 'paragraph',
                            content: [
                                'This document will inform you that you have the right to get a copy of the appraisal report that was obtained in conjunction with your application for credit.'
                            ]
                        },
                        cont018: {
                            meta: 'heading',
                            content: 'The Final Document before Closing: The HUD-1 Settlement Statement'
                        },
                        cont019: {
                            meta: 'paragraph',
                            content: [
                                'Your loan officer should provide you with a copy of the HUD-1 Settlement Statement at least 24 hours before you sign the mortgage loan documents at your closing. This document discloses the actual dollar amounts you will pay for the various fees and services associated with the closing of your mortgage loan. Your closing costs can typically range from 3 percent to 7percent of the mortgage loan amount, so it’s important that you are aware of these costs and ask questions about them. This statement includes a section that compares the charges on the Good Faith Estimate to the charges on the HUD-1. The purpose of this section is to determine if the actual HUD-1 settlement charges fall within the allowable limits on how much certain fees can change from those that were specified on the Good Faith Estimate. If the charges are too high, you will need to contact your loan officer to correct the charges. The HUD-1 Settlement Statement also lists the date and time of the closing. In many places, the closing takes place at a title company or an escrow office. The escrow officer is an impartial third party in the transaction, who will be able to answer general questions about the terms of your mortgage loan, but won’t be able to give you legal advice.'
                            ]
                        },
                        cont020: {
                            meta: 'heading',
                            content: 'The Documents in More Detail'
                        },
                        cont021: {
                            meta: 'paragraph',
                            content: [
                                'Here’s a little more detail about some of the paperwork you’ll be asked to sign at your closing. Remember, every person who buys a home has to sign this paperwork, no matter the country of origin, income level, or native language.'
                            ]
                        },
                        cont022: {
                            meta: 'heading',
                            content: 'The Mortgage Note'
                        },
                        cont023: {
                            meta: 'paragraph',
                            content: [
                                'The mortgage note is a legal document that provides evidence of your indebtedness and your formal promise to repay the mortgage loan, according to the terms you’ve agreed to. These terms include the amount you owe, the interest rate of the mortgage loan, the dates when the payments are to be made, the length of time for repayment, and the place where the payments are to be sent. The note also explains the consequences of failing to make your monthly mortgage payments.'
                            ]
                        },
                        cont024: {
                            meta: 'heading',
                            content: 'The Mortgage or Deed of Trust'
                        },
                        cont025: {
                            meta: 'paragraph',
                            content: [
                                'The mortgage or deed of trust is the security instrument that you give to the lender that protects the lender’s interest in your property. When you sign the mortgage or the deed of trust (depending on the state where you live), you are giving the lender the right to take the property by foreclosure if you fail to pay your mortgage according to the terms you’ve agreed to. Financing a house is very similar to financing an automobile; in both cases the property is the security for the loan. The mortgage or deed of trust states most of the information contained in the note. It also establishes your responsibility to keep the house in good repair, insure it, and make your payments on time.'
                            ]
                        },
                        cont026: {
                            meta: 'heading',
                            content: 'The Deed'
                        },
                        cont027: {
                            meta: 'paragraph',
                            content: [
                                'A deed is a document that transfers ownership of the property to you. It contains the names of the previous and new owners and a legal description of the property, and is signed by the person transferring the property. The deed gives you title to the property, but the title is conveyed to a neutral third party (called a trustee) until you pay the mortgage loan in full. The closing agent will be responsible for recording this document so that it can be filed as part of your county’s public records. You will receive a copy at closing and another copy after it has been recorded.'
                            ]
                        },
                        cont028: {
                            meta: 'heading',
                            content: 'Affidavits and Declarations'
                        },
                        cont029: {
                            meta: 'paragraph',
                            content: [
                                'Affidavits and declarations are statements declaring something to be true, like the fact that the property will be your principal place of residence or that all the repairs needed on the property were completed prior to closing. In most cases you’ll have to sign one or more affidavits at your closing.'
                            ]
                        },
                        cont030: {
                            meta: 'boldItalicParagraph',
                            content: 'This information was excerpted from the Your Step-by-Step Mortgage Guide, published by Freddie Mac.'
                        },
                    }
                },
                subsect003: {
                    displayName: 'Escrow Process',
                    readableName: 'Escrow Process',
                    hash: 'escrow-process',
                    content: {
                        cont001: {
                            meta: 'paragraph',
                            content: [
                                'Arizona is a Deed of Trust State which means that buyers, sellers, and lenders use escrow companies to facilitate the transfer of ownership of real estate. For this reason it is important to understand what escrow is and how it works.'
                            ]
                        },
                        cont002: {
                            meta: 'heading',
                            content: 'Why Do I Need an Escrow?'
                        },
                        cont003: {
                            meta: 'paragraph',
                            content: [
                                'Whether you are the buyer, seller, lender or borrower, you want the assurance that no funds or property will change hands until ALL of the terms of the contract have been met. It is the escrow company that is tasked with the obligation to safeguard the funds and documents during the term of the contract. The escrow company is also responsible for the correct disbursement of money and the conveyance of title only after all provisions of the contract have been complied with.'
                            ]
                        },
                        cont004: {
                            meta: 'heading',
                            content: 'How does it Work?'
                        },
                        cont005: {
                            meta: 'paragraph',
                            content: [
                                'The escrow officer will process the escrow, in accordance with the escrow instructions, and once all required conditions have been met, the escrow will be “closed.” Each escrow, although following a similar pattern, will be different in some respects, as it deals with the specific terms of your purchase agreement.'
                            ]
                        },
                        cont006: {
                            meta: 'paragraph',
                            content: [
                                'The duties of an escrow holder include:'
                            ]
                        },
                        cont007: {
                            meta: 'unorderedList',
                            content: [
                                'Following the lawful instructions given by the Sellers, Buyers and Real Estate Brokers',
                                'Handling the funds and/or documents in accordance with the contract',
                                'Paying all contract related bills as authorized in the contract',
                                'Preparing the Closing Settlement Statement',
                                'Transferring and recording of the deed once all funds are available and when all terms of the contract have been met'
                            ]
                        },
                        cont008: {
                            meta: 'heading',
                            content: 'Who Chooses the Escrow Company?'
                        },
                        cont009: {
                            meta: 'paragraph',
                            content: [
                                'The selection of the escrow holder is normally done by agreement between the buyer and seller. It is the right of the Buyer and Seller to use an escrow company who is competent and who is experienced in handling the type of escrow at hand. In order to protect the Buyers and Sellers and afford them the best possible escrow service, there are laws that strictly prohibit the payment of referral fees to escrow companies.'
                            ]
                        },
                        cont010: {
                            meta: 'heading',
                            content: 'What Do I have to do while in Escrow?'
                        },
                        cont011: {
                            meta: 'paragraph',
                            content: [
                                'The key to any transaction as important as the sale or purchase of your home is to read and understand your contract along with the escrow instructions that have been prepared to facilitate the transaction. If you do not understand the escrow instructions, you should ask your escrow officer to explain them. However, keep in mind that your escrow officer is not an attorney and cannot practice law; you should consult your lawyer for legal advice.',
                                'Don’t forget to respond quickly to all correspondence from the escrow officer. This will assist in the timely closing of the transaction.',
                                'If you are required to deliver funds in order to close the transaction, make sure that you provide “good” funds in the form required by the escrow officer. Company procedures differ in this regard, so be sure to ask your escrow officer what form of payment is acceptable. One thing is for sure, do not give the escrow officer a personal check and expect the escrow to close immediately; the escrow can only close on cleared funds, and the processing of a personal check can take days, possibly even a week or more.'
                            ]
                        },
                        cont012: {
                            meta: 'heading',
                            content: [
                                'Escrow and Your New Loan'
                            ]
                        },
                        cont013: {
                            meta: 'paragraph',
                            content: [
                                'If you are obtaining a new loan, your escrow officer will be in contact with the lender who will need copies of the escrow instructions, the preliminary title report and all contract documents. In the processing and the closing of the escrow, the escrow holder is obligated to comply with the lender’s instructions.',
                                'It has become a practice of some lenders to forward their loan documents to escrow for signing. You should be aware that these papers are lender’s documents and cannot be explained or interpreted by the escrow officer. You have the option of requesting a representative from the lender’s office to be present for explanation, or arrange to meet with your lender to sign the documents in their office.'
                            ]
                        },
                        cont014: {
                            meta: 'header',
                            content: 'What Fees and Costs will be Charged?'
                        },
                        cont015: {
                            meta: 'paragraph',
                            content: [
                                'Escrow companies, like any other businesses, charge fees that are commensurate with the costs of producing their service, the liability undertaken, and the overhead expenses which include a profit factor. Therefore, the fees will vary between companies and from county to county.'
                            ]
                        },
                        cont016: {
                            meta: 'header',
                            content: 'What about Title Insurance?'
                        },
                        cont017: {
                            meta: 'paragraph',
                            content: [
                                'Title Insurance is usually obtained when real property is purchased. The owner’s policy insures the owner and the lender’s policy insures the lender. The title insurance policy fee is a one-time fee, paid at the close of escrow. The determination of who pays for the policy is not uniform and in almost every case, the question of who pays closing costs is a matter of agreement between the parties through the purchase contract.'
                            ]
                        },
                        cont018: {
                            meta: 'header',
                            content: 'What is Close of Escrow?'
                        },
                        cont019: {
                            meta: 'paragraph',
                            content: [
                                'Once all of the terms and conditions of the escrow instructions have been met the escrow company will record the deed with the county recorder’s office, signifying the legal transfer of the property. This process is called recordation and it is the moment buyers and sellers have been waiting for! This is when the keys change hands and the buyer has a new place to call home, and the seller embarks on a new journey.'
                            ]
                        },
                    }
                },
                subsect004: {
                    displayName: 'Price Changes',
                    readableName: 'Price Changes',
                    hash: 'price-changes',
                    content: {
                        cont001: {
                            meta: 'heading',
                            content: 'Has Your Dream Home Been Reduced in Price?'
                        },
                        cont002: {
                            meta: 'paragraph',
                            content: [
                                'We all know that sellers want the most money possible and buyers want to pay the least amount possible for the same home. This just makes good sense!',
                                'Sometimes, Sellers desire to test the market to ensure they receive the absolute highest and best price for their home only to later reduce the price to be more competitive with the prevailing real estate market.',
                                'When a property is initially overpriced, Buyers often skip over the property and then never realize their dream home just got competitive!',
                                'For this reason we are pleased to offer you a search portal which will automatically email you whenever a property in your preferred neighborhood(s) arrives on the market or changes price. Contact us today and we can arrange to have a customized search created just for you.'
                            ]
                        }
                    }
                }
            }
        },
        sect002: {
            icon: resourcesIcon,
            hoverIcon: resourcesIconHover,
            activeIcon: resourcesIconActive,
            clickIcon: resourcesIconClicked,
            alt: 'Resources',
            displayName: 'Resources',
            readableName: 'Resources',
            hash: 'resources',
            subsections: {
                subsect001: {
                    displayName: 'Choosing the Right Realtor',
                    readableName: 'Choosing the Right Realtor',
                    hash: 'choosing-the-right-realtor',
                    content: {
                        cont001: {
                            meta: 'paragraph',
                            content: ['If you are considering working with real estate agents, you should carefully vet them. It has been our experience that too few people take the time to interview agents. Instead, they often work with the first person they encounter or a family member or friend, never knowing how skilled and experienced that person is.']
                        },
                        cont002: {
                            meta: 'boldItalicParagraph',
                            content: ['You wouldn’t invest your money with a part-time or inexperienced financial planner-so why would you trust your largest asset to an inexperienced or unskilled real estate agent?']
                        },
                        cont003: {
                            meta: 'paragraph',
                            content: ['The document below will help you identify the right realtor for you. Of course, if you are buying or selling real estate in the near future, we at Bold Realty would love an opportunity to earn your business!']
                        },
                        cont004: {
                            meta: 'files',
                            content: {
                                cont001: {
                                    meta: 'file',
                                    file: {
                                        name: 'Questions to Ask Every Real Estate Agent Before Hiring Them',
                                        image: agentQuestionsImage,
                                        pdfFile: questionsToAskEveryRealEstateAgentPdf,
                                        htmlTo: 'questions-to-ask-every-real-estate-agent-before-hiring-them',
                                        screenReaderPdfAnnouncement: 'Press the Enter key to open, in a new tab, a PDF version of Questions To Ask Every Real Estate Agent Before Hiring Them',
                                        screenReaderHtmlAnnouncement: 'Press the Enter key to open, in a new tab, a Web Page version of Questions To Ask Every Real Estate Agent Before Hiring Them'
                                    }
                                }
                            }
                        },
                    }
                }
            }
        },
        sect003: {
            icon: faqsIcon,
            hoverIcon: faqsIconHover,
            activeIcon: faqsIconActive,
            clickIcon: faqsIconClicked,
            alt: 'FAQS',
            displayName: 'FAQS',
            readableName: 'Frequently Asked Questions',
            hash: 'faqs',
            subsections: {
                subsect001: {
                    content: {
                        cont001: {
                            meta: 'paragraph',
                            content: ['Have questions around buying a home?  Below, we have categorized many frequently asked questions.  Contact us for a more thorough understanding of the home buying process.']
                        },
                        cont002: {
                            meta: 'accordion',
                            config: {
                                id: 'getting-started',
                                buttonText: 'Getting Started',
                                nested: [
                                    {
                                        config: {
                                            id: 'how-do-i-know-if-im-ready-to-buy-a-home',
                                            buttonText: 'How do I know if I\'m ready to buy a home?',
                                            panelContent: {
                                                 cont001: {
                                                     meta: 'paragraph',
                                                     content: ['You can find out by asking yourself some questions:']
                                                 },
                                                 cont002: {
                                                     meta: 'ordered-list',
                                                     content: [
                                                         'Do I have a steady source of income (usually a job)? Have I been employed on a regular basis for the last 2-3 years? Is my current income reliable?',
                                                         'Do I have a good record of paying my bills?',
                                                         'Do I have few outstanding long-term debts, like car payments?',
                                                         'Do I have money saved for a down payment?',
                                                         'Do I have the ability to pay a mortgage every month, plus additional costs?'
                                                     ]
                                                 },
                                                 cont003: {
                                                     meta: 'paragraph',
                                                     content: ['If you can answer “yes” to these questions, you are probably ready to buy your own home.']
                                                 }
                                             }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-do-i-begin-the-process-of-buying-a-home',
                                            buttonText: 'How do I begin the process of buying a home?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Start by thinking about your situation. Are you ready to buy a home? How much can you afford in a monthly mortgage payment (see Question 4 for help)? How much space do you need? What areas of town do you like? After you answer these questions, make a “To Do” list and start doing casual research. Talk to friends and family, drive through neighborhoods, and look in the “Homes” section of the newspaper.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-does-purchasing-a-home-compare-with-renting',
                                            buttonText: 'How does purchasing a home compare with renting?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['The two don’t really compare at all. The one advantage of renting is being generally free of most maintenance responsibilities. But by renting, you lose the chance to build equity, take advantage of tax benefits, and protect yourself against rent increases. Also, you may not be free to decorate without permission and may be at the mercy of the landlord for housing.', 'Owning a home has many benefits. When you make a mortgage payment, you are building equity. And that’s an investment. Owning a home also qualifies you for tax breaks that assist you in dealing with your new financial responsibilities- like insurance, real estate taxes, and upkeep- which can be substantial. But given the freedom, stability, and security of owning your own home, they are worth it.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-does-the-lender-decide-the-maximum-loan-amount-that-i-can-afford',
                                            buttonText: 'How does the lender decide the maximum loan amount that I can afford?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['The lender considers your debt-to-income ratio, which is a comparison of your gross (pre-tax) income to housing and non-housing expenses. Non-housing expenses include such long-term debts as car or student loan payments, alimony, or child support. According to the FHA, monthly mortgage payments should be no more than 29% of gross income, while the mortgage payment, combined with non-housing expenses, should total no more than 41% of income. The lender also considers cash available for down payment and closing costs, credit history, etc. when determining your maximum loan amount.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-do-i-select-the-right-real-estate-agent',
                                            buttonText: 'How do I select the right real estate agent?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Look for an agent who listens well and understands your needs, and whose judgment you trust. The ideal agent knows the local area well and has resources and contacts to help you in your search. Overall, you want to choose an agent that makes you feel comfortable and can provide all the knowledge and services you need.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-can-i-determine-my-housing-needs-before-i-begin-the-search',
                                            buttonText: 'How can I determine my housing needs before I begin the search?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Your home should fit the way you live, with spaces and features that appeal to the whole family.', 'Before you begin looking at homes, make a list of your priorities – things like location and size. Should the house be close to certain schools? Your job? To public transportation? How large should the house be? What type of lot do you prefer?', 'What kinds of amenities are you looking for? Establish a set of minimum requirements and a ‘wish list.” Minimum requirements are things that a house must have for you to consider it, while a “wish list” covers things that you’d like to have but aren’t essential.']
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            readableName: 'Getting Started'
                        },
                        cont003: {
                            meta: 'accordion',
                            config: {
                                id: 'beginning-the-search',
                                buttonText: 'Beginning the Search',
                                nested: [
                                    {
                                        config: {
                                            id: 'what-should-i-look-for-when-deciding-on-a-community',
                                            buttonText: 'What should I look for when deciding on a community?',
                                            panelContent: {
                                            cont001: {
                                                meta: 'paragraph',
                                                content: ['Select a community that will allow you to best live your daily life. Many people choose communities based on schools. Do you want access to shopping and public transportation? Is access to local facilities like libraries and museums important to you? Or do you prefer the peace and quiet of a rural community? When you find places that you like, talk to people that live there. They know the most about the area and will be your future neighbors. More than anything, you want a neighborhood where you feel comfortable in.']
                                            }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-can-i-find-out-about-local-schools',
                                            buttonText: 'How can I find out about local schools?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['You can get information about school systems by contacting the city or county school board or the local schools. Your Bold Realty Partner will also be able to assist you in gathering the school information you need.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-can-i-find-out-how-much-homes-are-selling-for-in-certain-communities-and-neighborhoods',
                                            buttonText: 'How can I find out how much homes are selling for in certain communities and neighborhoods?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Bold Realty is happy to assist you with this information. Simply call us at 602.373.0115 or email us at carynkommers@gmail.com.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-can-i-find-information-on-the-property-tax-liability',
                                            buttonText: 'How can I find information on the property tax liability?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['The total amount of the previous year’s property taxes is included in the listing information. Tax rates can change from year to year, so these figures may be approximate.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'is-an-older-home-a-better-value-than-a-new-home',
                                            buttonText: 'Is an older home a better value than a new home?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Generally, older homes may be in more established neighborhoods, and offer more ambiance. People who buy older homes, however, shouldn’t mind maintaining their home and making some repairs. Newer homes tend to use more modern architecture and systems, are usually easier to maintain, and may be more energy-efficient. People who buy new homes often don’t want to worry initially about upkeep and repairs. However, a new home will generally require some initial investment in landscaping.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-should-i-look-for-when-walking-through-a-home',
                                            buttonText: 'What should I look for when walking through a home?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['In addition to comparing the home to your minimum requirement and wish lists, use the HUD Home Scorecard and consider the following:']
                                                },
                                                cont002: {
                                                    meta: 'ordered-list',
                                                    content: [
                                                        'Is there enough room for both the present and the future?',
                                                        'Are there enough bedrooms and bathrooms?',
                                                        'Is the yard big enough?',
                                                        'Do you like the floor plan?',
                                                        'Will your furniture fit in the space? Is there enough storage space? (Bring a tape measure to better answer these questions.)',
                                                        'Imagine the house in good weather and bad, and in each season. Will you be happy with it year-round?'
                                                    ]
                                                },
                                                cont003: {
                                                    meta: 'paragraph',
                                                    content: ['Take your time and think carefully about each house you see. Ask your Bold Realty Partner to point out the pros and cons of each home from a professional standpoint.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-questions-should-i-ask-when-looking-at-homes',
                                            buttonText: 'What questions should I ask when looking at homes?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Ask about the house and neighborhood, focusing on quality of life issues. Be sure the seller’s or Bold Realty Partner’s answers are clear and complete. Ask questions until you understand all of the information you’ve been given.After contract acceptance, you will be provided an opportunity to conduct a professional home inspection which will focus on potential problems and maintenance issues and determining if anything need to be replaced?']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-can-i-keep-track-of-all-the-homes-i-see',
                                            buttonText: 'How can I keep track of all the homes I see?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Your Bold Realty Partner will assist you by taking photographs of each house: the outside, the major rooms, the yard, and extra features that you like. Along with the property information sheet, you will receive pictures that provide a wonderful memory jog.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-many-homes-should-i-consider-before-choosing-one',
                                            buttonText: 'How many homes should I consider before choosing one?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['There isn’t a set number of houses you should see before you decide. Visit as many as it takes to find the one you want. On average, homebuyers see up to 15 houses before choosing one. Just be sure to communicate often with your Bold Realty Partner about everything you’re looking for. It will help avoid wasting your time.']
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            readableName: 'Beginning the Search'
                        },
                        cont004: {
                            meta: 'accordion',
                            config: {
                                id: 'youve-found-it',
                                buttonText: 'You\'ve Found It!',
                                nested: [
                                    {
                                        config: {
                                            id: 'what-does-a-home-inspector-do-and-how-does-an-inspection-figure-into-the-purchase-of-a-home',
                                            buttonText: 'What does a home inspector do, and how does an inspection figure into the purchase of a home?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['An inspector checks the safety of your potential new home. Home Inspectors focus especially on the structure, construction, and mechanical systems of the house and will make you aware of only repairs, that are needed. The Inspector does not evaluate whether or not you’re getting good value for your money. Be sure to hire a home inspector that is qualified and experienced.Generally, an inspector checks: the electrical system, plumbing and waste disposal, the water heater, insulation and ventilation, the HVAC system, water source and quality, doors, windows, ceilings, walls, and floors.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'do-i-need-to-be-there-for-the-inspection',
                                            buttonText: 'Do I need to be there for the inspection?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['It’s not required, but it’s a good idea to be there for at least the summary at the end of the inspection as this will allow you to ask the inspector questions about the report and any problem areas. This is also an opportunity to hear an objective opinion on the home you’d I like to purchase and it is a good time to ask general, maintenance questions.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'are-other-types-of-inspections-required',
                                            buttonText: 'Are other types of inspections required?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['If your home inspector discovers a serious problem, a more specific Inspection may be recommended.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'are-power-lines-a-health-hazard',
                                            buttonText: 'Are power lines a health hazard?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['There are no definitive research findings that indicate exposure to power lines results in greater instances of disease or illness.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'do-i-need-a-lawyer-to-buy-a-home',
                                            buttonText: 'Do I need a lawyer to buy a home?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Arizona does not require Attorneys to be involved in the purchase or sale of real estate as long as a qualified real estate professional is involved. If it becomes prudent to involve an attorney, your Bold Realty Partner will be able to recommend a competent real estate lawyer']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'do-i-really-need-homeowners-insurance',
                                            buttonText: 'Do I really need homeowner\'s insurance?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Yes. A paid homeowner’s insurance policy (or a paid receipt for one) is required at closing, so arrangements will have to be made prior to that day. Plus, involving the insurance agent early in the home buying process can save you money.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-do-I-make-an-offer',
                                            buttonText: 'How do I make an offer?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Your Bold Realty Partner will assist you in making an offer. Remember that a sale commitment depends on negotiating a satisfactory contract with the seller, not just making an offer.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-do-i-determine-the-initial-offer',
                                            buttonText: 'How do I determine the initial offer?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Your Bold Realty Partner will advise you on constructing a fair price and terms for your offer. Calculating your offer will involve several factors:']
                                                },
                                                cont002: {
                                                    meta: 'ordered-list',
                                                    content: [
                                                        'What homes sell for in the area',
                                                        'The home’s condition',
                                                        'How long it’s been on the market',
                                                        'Financing terms'
                                                    ]
                                                },
                                                cont003: {
                                                    meta: 'paragraph',
                                                    content: ['By the time you’re ready to make an offer, you should have a good idea of what the home is worth and what you can afford. And, be prepared for give-and-take negotiation, which is very common when buying a home. The buyer and seller may often go back and forth until they can agree on a price.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-is-earnest-money-how-much-should-I-set-aside',
                                            buttonText: 'What is earnest money?  How much should I set aside?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Earnest money is money put down to demonstrate your seriousness about buying a home. It must be substantial enough to demonstrate good faith and is usually between 1-5% of the purchase price. Your Bold Realty Partner will help you determine the appropriate amount of earnest money to offer.Once your offer is accepted, the earnest money becomes part of your down payment or closing costs. If the offer is rejected, your money is returned to you']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-are-home-warranties-and-should-I-consider-them',
                                            buttonText: 'What are "home warranties", and should I consider them?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Home warranties offer you protection for a specific period of time (e.g., one year) against potentially costly problems, like unexpected repairs on appliances or home systems, which are not covered by homeowner’s insurance. Warranties are becoming more popular because they offer protection during the time immediately following the purchase of a home, a time when many people find themselves cash-strapped.']
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            readableName: 'You\'ve Found It!'
                        },
                        cont005: {
                            meta: 'accordion',
                            config: {
                                id: 'general-financing-questions',
                                buttonText: 'General Financing Questions',
                                nested: [
                                    {
                                        config: {
                                            id: 'what-is-a-mortgage',
                                            buttonText: 'What is a mortgage?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Although the term “mortgage” is commonly used to describe the loan against the property, generally speaking, Arizona is a Deed of Trust state rather than a mortgage state and borrowers (buyers) will generally sign a Deed of Trust at closing. For most consumers the difference between a mortgage and a Deed of Trust are negligible and only has significance as it relates to the foreclosure process in the event of a borrow default. Both a Mortgage and a Deed of trust are liens (a legal claim) on the home or property that secures the promise to pay the debt.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-is-a-loan-to-value',
                                            buttonText: 'What is a loan to value (LTV)?  How does it determine the size of my loan?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['The loan to value ratio is the amount of money you borrow compared with the price or appraised value of the home you are purchasing. Each loan has a specific LTV limit. For example: With a 95% LTV loan on a home priced at $100,000, you could borrow up to $95,000 (95% of $100,000), and would have to pay, $5,000 as a down payment. To protect lenders against potential loss in case of default, higher LTV loans (80% or more) usually require a mortgage insurance policy.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-types-of-loans-are-available-and-what-are-the-advantages-of-each',
                                            buttonText: 'What types of loans are available and what are the advantages of each?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['The lending market is fluid and the types of loans available will vary. These descriptions are intended to explain the different types of loans however, not loan products may be available at the time of purchase.']
                                                },
                                                cont002: {
                                                    meta: 'paragraph',
                                                    content: ['Fixed Rate Mortgages: Payments remain the same for the life of the loan. There are two types:']
                                                },
                                                cont003: {
                                                    meta: 'ordered-list',
                                                    content: [
                                                        '15-year',
                                                        '30-year'
                                                    ]
                                                },
                                                cont004: {
                                                    meta: 'paragraph',
                                                    content: ['Adjustable Rate Mortgages (ARMS): Payments increase or decrease on a regular schedule with changes in interest rates; increases subject to limits. There are several types:']
                                                },
                                                cont005: {
                                                    meta: 'ordered-list',
                                                    content: [
                                                        'Balloon Mortgage- Offers very low rates for an Initial period of time and when time has elapsed, the balance is due and must be paid off or refinanced',
                                                        'Two-Step Mortgage- Interest rate adjusts only once and remains the same for the life of the loan',
                                                        'ARMS linked to a specific index or margin'
                                                    ]
                                                },
                                                cont006: {
                                                    meta: 'paragraph',
                                                    content: ['The advantages of ARM loans are: generally offer lower initial interest rates, monthly payments can be lower and may allow the borrower to qualify for a larger loan amount.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'when-do-arms-make-sense',
                                            buttonText: 'When do ARMS make sense?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['An ARM may make sense if you are confident that your income will increase steadily over the years or if you anticipate a move in the near future and aren’t concerned about potential increases in interest rates.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-are-the-advantages-of-15-and-30-year-loan-terms',
                                            buttonText: 'What are the advantages of 15- and 30-year loan terms?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['30-Year: In the first 23 years of the loan, more interest is paid off than principal, meaning larger tax deductions. As inflation and costs of living increase, mortgage payments become a smaller part of overall expenses.15-year: Loan is usually made at a lower interest rate and equity is built faster because early payments pay more principal.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'can-I-pay-off-my-loan-ahead-of-schedule',
                                            buttonText: 'Can I pay off my loan ahead of schedule?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Yes. By sending in extra money each month or making an extra payment at the end of the year, you can accelerate the process of paying off the loan. When you send extra money, be sure to indicate that the excess payment is to be applied to the principal. Most lenders allow loan prepayment, though you may have to pay a prepayment penalty to do so. Ask your lender for details.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'are-there-special-mortgages-for-first-time-homebuyers',
                                            buttonText: 'Are there special mortgages for first-time homebuyers?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Yes. Lenders now offer several affordable mortgage options which can help first-time homebuyers overcome obstacles that made purchasing a home difficult in the past. Lenders may now be able to help borrowers who don’t have a lot of money saved for the down payment and closing costs, have no or a poor credit history, have quite a bit of long-term debt, or have experienced income irregularities.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-large-of-a-down-payment-do-i-need',
                                            buttonText: 'How large of a down payment do I need?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Loan programs are subject to change on an ongoing basis. Currently, the lowest possible down payment for those to qualify is 3.5% which may possibly be covered by the seller through a seller assist contribution .In general, the larger the down payment, the less you have to borrow, and the more equity you’ll have. Mortgages with less than a 20% down payment generally require a mortgage insurance policy to secure the loan which adds to the bottom line cost of the loan. When considering the size of your down payment, consider that you’ll also need money for closing costs, moving expenses, and – possibly -repairs and decorating.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-is-included-in-a-monthly-mortgage-payment',
                                            buttonText: 'What is included in a monthly mortgage payment?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['The monthly mortgage payment mainly pays off principal and interest. But most lenders also include local real estate taxes, homeowner’s insurance, and mortgage insurance (if applicable).']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-does-the-interest-rate-factor-into-securing-a-mortgage-loan',
                                            buttonText: 'How does the interest rate factor into securing a mortgage loan?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['A lower interest rate allows you to borrow more money than a high rate with the some monthly payment. Interest rates can fluctuate as you shop for a loan, so ask-lenders if they offer a rate “lock” which guarantees a specific interest rate for a certain period of time. Remember that a lender must disclose the Annual Percentage Rate (APR) of a loan to you. The APR shows the cost of a mortgage loan by expressing it in terms of a yearly interest rate. It is generally higher than the interest rate because it also includes the cost of points, mortgage insurance, and other fees included in the loan.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-happens-if-interest-rates-decrease-and-i-have-a-fixed-rate-loan',
                                            buttonText: 'What happens if interest rates decrease and I have a fixed rate loan?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['If interest rates drop significantly, you may want to investigate refinancing. Most experts agree that if you plan to be in your house for at least 18 months and you can get a rate 2% less than your current one, refinancing is smart. However, refinancing may involve paying many of the same fees paid at the original closing, plus origination and application fees.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-are-discount-points',
                                            buttonText: 'What are discount points?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Discount points allow you to lower your interest rate. They are essentially prepaid interest, with each point equaling 1% of the total loan amount. Generally, for each point paid on a 30-year mortgage, the interest rate is reduced by 1/8 (or.125) of a percentage point. Discount points are smart if you plan to stay in a home for some time since they can lower the monthly loan payment. Points are tax deductible when you purchase a home and you may be able to negotiate for the seller to pay for some of them.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-is-an-escrow-account-do-i-need-one',
                                            buttonText: 'What is an escrow account?  Do I need one?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Established by your lender, an escrow account is a place to set aside a portion of your monthly mortgage payment to cover annual charges for homeowner’s insurance, mortgage insurance (if applicable), and property taxes. Escrow accounts are a good idea because they assure money will always be available for these payments. If you use an escrow account to pay property tax or homeowner’s insurance, make sure you are not penalized for late payments since it is the lender’s responsibility to make those payments.Keep in mind despite the similar name of escrow account this is not the same as the escrow account which will be opened with the escrow company upon contract acceptance.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-steps-need-to-be-taken-to-secure-a-loan',
                                            buttonText: 'What steps need to be taken to secure a loan?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['The first step in securing a loan is to complete a loan application. To do so, you’ll need the following information:']
                                                },
                                                cont002: {
                                                    meta: 'ordered-list',
                                                    content: [
                                                        'Pay stubs for the past 2-3 months',
                                                        'W-2 forms for the past 2 years',
                                                        'Information on long-term debts',
                                                        'Recent bank statements',
                                                        'tax returns for the past 2 years',
                                                        'Proof of any other income',
                                                        'Address and description of the property you wish to buy',
                                                        'Sales contract'
                                                    ]
                                                },
                                                cont003: {
                                                    meta: 'paragraph',
                                                    content: ['During the application process, the lender will order a report on your credit history and a professional appraisal of the property you want to purchase.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-do-i-choose-the-right-lender-for-me',
                                            buttonText: 'How do I choose the right lender for me?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Choose your lender carefully. Look for financial stability and a reputation for customer satisfaction. Be sure to choose a company that gives helpful advice and that makes you feel comfortable. A lender that has the authority to approve and process your loan locally is preferable, since it will be easier for you to monitor the status of your application and ask questions. Plus, it’s beneficial when the lender knows home values and conditions in the local area. Do research and ask family, friends, and your Bold Realty Partner for recommendations. Your Bold Realty Partner will help you check the licensing and history of any lender you are considering to ensure you are working with a professional.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-are-pre-qualifying-and-pre-approval-different',
                                            buttonText: 'How are pre-qualifying and pre-approval different?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Pre-qualification is an informal way to see how much you may be able to borrow. You can be ‘pre-qualified’ over the phone with no paperwork by telling a lender your income, your long-term debts, and how large a down payment you can afford. Without any obligation, this helps you arrive at a ballpark figure of the amount you may have available to spend on a house.Pre-approval is a lender’s actual commitment to lend to you. It involves assembling the necessary financial records and going through a preliminary approval process.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-can-I-find-out-information-about-my-credit-history',
                                            buttonText: 'How can I find out information about my credit history?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['There are three major credit reporting companies: Equifax, Experian, and Trans Union. Obtaining your credit report is as easy as calling and requesting one. Once you receive the report, it’s important to verify its accuracy. Double check the “high credit limit, “‘total loan,” and ‘past due” columns. It’s a good idea to get copies from all three companies to assure there are no mistakes since any of the three could be providing a report to your lender. Fees, ranging from $5-$20, are usually charged to issue credit reports but some states permit citizens to acquire a free one.',
                                                    'CREDIT REPORTING COMPANIES',
                                                    'Experian 1-888-397-3742',
                                                    'Equifax 1-800-685-1111',
                                                    'Trans Union 1-800-916-8800'
                                                    ]
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-if-I-find-a-mistake-in-my-credit-history',
                                            buttonText: 'What if I find a mistake in my credit history?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Simple mistakes are easily corrected by writing to the reporting company, pointing out the error, and providing proof of the mistake. You can also request to have your own comments added to explain problems.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-is-a-FICO-score-and-how-do-lenders-use-them',
                                            buttonText: 'What is a FICO score and how do lenders use them?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['A credit bureau score is a number, based upon your credit history, that represents the possibility that you will be unable to repay a loan. Lenders use it to determine your ability to qualify for a mortgage loan. The better the score, the better your chances are of getting a loan. Ask your lender for details.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-can-i-improve-my-score',
                                            buttonText: 'How can I improve my score?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['There are no easy ways to improve your credit score, but you can work to keep it acceptable by maintaining a good credit history. This means paying your bills on time and not overextending yourself by buying more than you can afford.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-do-i-choose-the-best-loan-program-for-me',
                                            buttonText: 'How do I choose the best loan program for me?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Your personal situation will determine the best kind of loan for you. By asking yourself a few questions, you can help narrow your search among the many options available and discover which loan suits you best.']
                                                },
                                                cont002: {
                                                    meta: 'ordered-list',
                                                    content: [
                                                        'Do you expect your finances to change over the next few years?',
                                                        'Are you planning to live in the home for a long period of time?',
                                                        'Are you comfortable with the idea of a changing mortgage payment amount?',
                                                        'Do you wish to be free of mortgage debt as your children approach college age or as you prepare for retirement?',
                                                    ]
                                                },
                                                cont003: {
                                                    meta: 'paragraph',
                                                    content: ['Your lender can help you use your answers to questions such as these to decide which loan best fits your needs.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'are-there-any-costs-or-fees-associated-with-the-loan-origination-process',
                                            buttonText: 'Are there any costs or fees associated with the loan origination process?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Yes. When you turn in your application, you’ll be required to pay a loan application fee to cover the costs of underwriting the loan. This fee pays for the home appraisal, a copy of your credit report, and any additional charges that may be necessary. The application fee is generally non-refundable.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-is-RESPA',
                                            buttonText: 'What is RESPA?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['RESPA stands for Real Estate Settlement Procedures Act. It requires lenders to disclose information to potential customers throughout the mortgage process. By doing so, it protects borrowers from abuses by lending institutions. RESPA mandates that lenders fully inform borrowers about all closing costs, lender servicing and escrow account practices, and business relationships between closing service providers and other parties to the transaction.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-is-a-good-faith-estimate-and-how-does-it-help-me',
                                            buttonText: 'What is a good faith estimate, and how does it help me?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['It’s an estimate that lists all fees paid before closing, all closing costs, and any escrow costs you will encounter when purchasing a home. The lender must supply it within three days of your application so that you can make accurate judgments when shopping for a loan.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'besides-RESPA-does-the-lender-have-any-additional-responsibilities',
                                            buttonText: 'Besides RESPA, does the lender have any additional responsibilities?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Lenders are not allowed to discriminate in any way against potential borrowers. If you believe a lender is refusing to provide his or her services to you on the basis of race, color, nationality, religion, sex, familial status, or disability, contact HUD’s Office of Fair Housing at 1-800-669-9777 (or 1-800-927-9275 for the hearing impaired).']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-responsibilities-do-i-have-during-the-lending-process',
                                            buttonText: 'What responsibilities do I have during the lending process?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['To ensure you won’t fall victim to loan fraud, be sure to follow all of these steps as you apply for a loan:']
                                                },
                                                cont002: {
                                                    meta: 'ordered-list',
                                                    content: [
                                                        'Be sure to read and understand everything before you sign.',
                                                        'Refuse to sign any blank documents.',
                                                        'Do not buy property for someone else.',
                                                        'Do not overstate your income.',
                                                        'Do not overstate how long you have been employed.',
                                                        'Do not overstate your assets.',
                                                        'Accurately report your debts.',
                                                        'Do not change your income tax returns for any reason. Tell the whole truth about gifts. Do not list fake co-borrowers on your loan application.',
                                                        'Be truthful about your credit problems, past and present.',
                                                        'Be honest about your intention to occupy the house',
                                                        'Do not provide false supporting documents.'
                                                    ]
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-happens-after-ive-applied-for-my-loan',
                                            buttonText: 'What happens after I\'ve applied for my loan?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['It usually takes a lender between 1-6 weeks to complete the evaluation of your application. It’s not unusual for the lender to ask for more information once the application has been submitted. The sooner you can provide the information, the faster your application will be processed. Once all the information has been verified the lender will call you to let you know the outcome of your application.']
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            readableName: 'General Financing Questions'
                        },
                        cont006: {
                            meta: 'accordion',
                            config: {
                                id: 'closing',
                                buttonText: 'Closing',
                                nested: [
                                    {
                                        config: {
                                            id: 'what-should-i-look-out-for-during-the-final-walk-through',
                                            buttonText: 'What should I look out for during the final walk-through?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['This will likely be the first opportunity to examine the house without furniture, giving you a clear view of everything. Check the walls and ceilings carefully, as well as any work the seller agreed to do in response to the inspection. Any problems discovered previously that you negotiated with the seller to correct that have not been corrected should be brought up prior to closing. It is the seller’s responsibility to fix them. Your Bold Realty Partner will assist you with this process.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-makes-up-closing-costs',
                                            buttonText: 'What makes up closing costs?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Closing costs are usually made up of the following:']
                                                },
                                                cont002: {
                                                    meta: 'ordered-list',
                                                    content: [
                                                        'Escrow fees',
                                                        'Property taxes (to cover tax period to date)',
                                                        'Interest (paid from date of closing to 30 days before first monthly payment)',
                                                        'Loan Origination fee (covers lenders administrative cost)',
                                                        'Recording fees',
                                                        'Appraisal fee',
                                                        'First premium of mortgage Insurance (if applicable)',
                                                        'Title Insurance (yours and the lender’s)',
                                                        'Loan discount points (if applicable)',
                                                        'First payment to escrow account for future real estate taxes and insurance',
                                                        'Any documentation preparation fees',
                                                    ]
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-do-i-get-at-closing',
                                            buttonText: 'What do I get at closing?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'ordered-list',
                                                    content: [
                                                        'Settlement Statement',
                                                        'HUD-1 Form (itemizes all fees; it is filled out by the closing agent and must be given to you at or before closing)',
                                                        'Truth-in-Lending Statement',
                                                        'Mortgage Note',
                                                        'Deed of Trust',
                                                        'Binding Sales Contract'
                                                    ]
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            readableName: 'Closing'
                        },
                        cont007: {
                            meta: 'accordion',
                            config: {
                                id: 'understanding-fha-and-fha-loan-products',
                                buttonText: 'Understanding FHA and FHA Loan Products',
                                nested: [
                                    {
                                        config: {
                                            id: 'how-can-the-fha-assist-me-in-buying-a-home',
                                            buttonText: 'How can the FHA assist me in buying a home?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['The FHA works to make homeownership a possibility for more Americans. With the FHA, you don’t need perfect credit or a high-paying job to qualify for a loan. The FHA also makes loans more accessible by requiring smaller down payments than conventional loans. In fact, an FHA down payment could be as little as a few months rent. And your monthly payments may not be much more than rent.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'who-can-qualify-for-fha-loans',
                                            buttonText: 'Who can qualify for FHA loans?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Anyone who meets the credit requirements, can afford the mortgage payments and cash investment, and who plans to use the mortgaged property as a primary residence may apply for an FHA-insured loan.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-is-the-fha-loan-limit',
                                            buttonText: 'What is the FHA loan limit?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['FHA loan limits vary throughout the country, from $115,200 in low-cost areas to $208,800 in high-cost areas. Because these maximums are linked to the conforming loan limit and average area home prices, FHA loan limits are periodically subject to change. Ask your lender for details and confirmation of current limits.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-are-the-steps-involved-in-the-fha-loan-process',
                                            buttonText: 'What are the steps involved in the FHA loan process?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['With the exception of a few additional forms, the FHA loan application process is similar to that of a conventional loan.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-much-income-do-i-need-to-have-to-qualify-for-an-fha-loan',
                                            buttonText: 'How much income do I need to have to qualify for an FHA loan?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['There is no minimum income requirement. But you must prove steady income for at least three years, and demonstrate that you’ve consistently paid your bills on time.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-qualifies-as-an-income-source-for-the-fha',
                                            buttonText: 'What qualifies as an income source for the FHA?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Seasonal pay, child support, retirement pension payments, unemployment compensation, VA benefits, military pay, Social Security income, alimony, and rent paid by family all qualify as income sources. Part-time pay, overtime, and bonus pay also count as long as they are steady. Special savings plans-such as those set up by a church or community association – qualify, too. Income type is not as important as income steadiness with the FHA.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'can-i-carry-debt-and-still-qualify-for-fha-loans',
                                            buttonText: 'Can I carry debt and still qualify for FHA loans?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Yes. Short-term debt doesn’t count as long as it can be paid off within 10 months. And some regular expenses, like child care costs, are not considered debt. Talk to your lender about meeting the FHA debt-to-income ratio.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-is-the-debt-to-income-ratio-for-fha-loans',
                                            buttonText: 'What is the debt-to-income ratio for FHA loans?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['The FHA allows you to use 29% of your income towards housing costs and 41% towards housing expenses and other long-term debt. With a conventional loan, this qualifying ratio allows only 28% toward housing and 36% towards housing and other debt']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'can-i-exceed-this-ratio',
                                            buttonText: 'Can I exceed this ratio?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['You may qualify to exceed if you have:']
                                                },
                                                cont002: {
                                                    meta: 'ordered-list',
                                                    content: [
                                                        'A large down payment',
                                                        'A demonstrated ability to pay more toward your housing expenses',
                                                        'Substantial cash reserves',
                                                        'Net worth enough to repay the mortgage regardless of income',
                                                        'Evidence of acceptable credit history or limited credit use',
                                                        'Less-than-maximum mortgage terms',
                                                        'Funds provided by an organization',
                                                        'A decrease in monthly housing expenses'
                                                    ]
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-large-a-down-payment-do-i-need-with-an-fha-loan',
                                            buttonText: 'How large a down payment do I need with an FHA loan?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['You must have a down payment of at least 3% of the purchase price of the home. Most affordable loan programs offered by private lenders require between a 3%-5% down payment, with a minimum of 3% coming directly from the borrower’s own funds.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-can-i-use-to-pay-the-down-payment-and-closing-costs-of-an-fha-loan',
                                            buttonText: 'What can I use to pay the down payment and closing costs of an FHA loan?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Besides your own funds, you may use cash gifts or money from a private savings club.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-does-my-credit-history-impact-my-ability-to-qualify',
                                            buttonText: 'How does my credit history impact my ability to qualify?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['The FHA is generally more flexible than conventional lenders in its qualifying guidelines. In fact, the FHA allows you to re-establish credit if:']
                                                },
                                                cont002: {
                                                    meta: 'ordered-list',
                                                    content: [
                                                        'Two years have passed since a bankruptcy has been discharged',
                                                        'All judgments have been paid',
                                                        'Any outstanding tax liens have been satisfied or appropriate arrangements have been made to establish a repayment plan with the IRS or state Department of Revenue',
                                                        'Three years have passed since a foreclosure or a deed-in-lieu has been resolved'
                                                    ]
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'Can-i-qualify-for-an-fha-loan-without-a-credit-history',
                                            buttonText: 'Can I qualify for an FHA loan without a credit history?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Yes. If you prefer to pay debts in cash or are too young to have established credit, there are other ways to prove your eligibility. Talk to your lender for details.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-types-of-closing-costs-are-associated-with-fha-insured-loans',
                                            buttonText: 'What types of closing costs are associated with FHA-insured loans?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Except for the addition of an FHA mortgage insurance premium, FHA closing costs are similar to those of a conventional loan. The FHA requires a single, upfront mortgage insurance premium equal to 2.25% of the mortgage to be paid at closing (or 1.75% if you complete the HELP program). After closing, you will then be responsible for an annual premium – paid monthly.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'can-i-roll-closing-costs-into-my-fha-loan',
                                            buttonText: 'Can I roll closing costs into my FHA loan?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['No. Though you can’t roll closing costs into your FHA loan, you may be able to use the amount you pay for them to help satisfy the down payment requirement. Ask your lender for details']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-is-a-203b-loan',
                                            buttonText: 'What is a 203(b) loan?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['This is the most commonly used FHA program. It offers a low down payment, flexible qualifying guidelines, limited lender’s fees, and a maximum loan amount.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-is-a-203k-loan',
                                            buttonText: 'What is a 203(k) loan?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['This is a loan that enables the homebuyer to finance both the purchase and rehabilitation of a home through a single mortgage. A portion of the loan is used to pay off the seller’s existing mortgage and the remainder is placed in an escrow account and released as rehabilitation is completed. Basic guidelines for 203(k) loans are as follows:']
                                                },
                                                cont002: {
                                                    meta: 'ordered-list',
                                                    content: [
                                                        'The home must be at least one year old.',
                                                        'The cost of rehabilitation must be at least $5,000, but the total property value – including the cost of repairs – must fall within the FHA maximum mortgage limit.',
                                                        'The 203(k) loan must follow many of the 203(b) eligibility requirements.',
                                                        'Talk to your lender about specific improvement, energy efficiency, and structural guidelines.'
                                                    ]
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            readableName: 'Understanding FHA and FHA Loan Products'
                        },
                        cont008: {
                            meta: 'accordion',
                            config: {
                                id: 'mortgage-insurance',
                                buttonText: 'Mortgage Insurance',
                                nested: [
                                    {
                                        config: {
                                            id: 'what-is-mortgage-insurance',
                                            buttonText: 'What is mortgage insurance?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Mortgage insurance is a policy that protects lenders against some or most of the losses that result from defaults on home mortgages. It’s required primarily for borrowers making a down payment of less than 20%.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-does-mortgage-insurance-work-is-it-like-home-or-auto-insurance',
                                            buttonText: 'How does mortgage insurance work?  Is it like home or auto insurance?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Like home or auto insurance, mortgage insurance requires payment of a premium, is for protection against loss, and is used in the event of an emergency. If a borrower can’t repay an insured mortgage loan as agreed, the lender may foreclose on the property and file a claim with the mortgage insurer for some or most of the total losses.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'do-i-need-mortgage-insurance-how-do-i-get-it',
                                            buttonText: 'Do I need mortgage insurance?  How do I get it?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['You need mortgage insurance only if you plan to make a down payment of less than 20% of the purchase price of the home. The FHA offers several loan programs that may meet your needs. Ask your lender for details.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-can-i-receive-a-discount-on-the-fha-initial-mortgage-insurance-premium',
                                            buttonText: 'How can I receive a discount on the FHA initial mortgage insurance premium?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Ask your lender for information on the HELP program from the FHA. HELP – Homebuyer Education Learning Program – is structured to help people like you begin the home buying process. It covers such topics as budgeting, finding a home, getting a loan, and home maintenance. In most cases, completion of this program may entitle you to a reduction in the initial FHA mortgage insurance premium from 2.25% to 1.75% of the purchase price of your new home.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'what-is-PMI',
                                            buttonText: 'What is PMI?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['PMI stands for Private Mortgage Insurance or Insurer. These are privately-owned companies that provide mortgage insurance. They offer both standard and special affordable programs for borrowers. These companies provide guidelines to lenders that detail the types of loans they will insure. Lenders use these guidelines to determine borrower eligibility. PMI’s usually have stricter qualifying ratios and larger down payment requirements than the FHA, but their premiums are often lower and they insure loans that exceed the FHA limit.']
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            readableName: 'Mortgage Insurance'
                        },
                        // cont009: {
                        //     meta: 'accordion',
                        //     config: {
                        //         id: 'main-topic',
                        //         buttonText: 'Other',
                        //         nested: [
                        //             {
                        //                 config: {
                        //                     id: 'first-topic',
                        //                     buttonText: 'Closing',
                        //                     nested: [
                        //                         {
                        //                             config: {
                        //                                 id: 'first-item',
                        //                                 buttonText: 'What should I look out for during the final walk-through?',
                        //                                 panelContent: {
                        //                                     cont001: {
                        //                                         meta: 'paragraph',
                        //                                         content: ['This will likely be the first opportunity to examine the house without furniture, giving you a clear view of everything. Check the walls and ceilings carefully, as well as any work the seller agreed to do in response to the inspection. Any problems discovered previously that you negotiated with the seller to correct that have not been corrected should be brought up prior to closing. It is the seller’s responsibility to fix them. Your Bold Realty Partner will assist you with this process.']
                        //                                     }
                        //                                 }
                        //                             }
                        //                         },
                        //                         {
                        //                             config: {
                        //                                 id: 'second-item',
                        //                                 buttonText: 'What makes up closing costs?',
                        //                                 panelContent: {
                        //                                     cont001: {
                        //                                         meta: 'paragraph',
                        //                                         content: ['Closing costs are usually made up of the following:']
                        //                                     },
                        //                                     cont002: {
                        //                                         meta: 'ordered-list',
                        //                                         content: [
                        //                                             'Escrow fees',
                        //                                             'Property taxes (to cover tax period to date)',
                        //                                             'Interest (paid from date of closing to 30 days before first monthly payment)',
                        //                                             'Loan Origination fee (covers lenders administrative cost)',
                        //                                             'Recording fees',
                        //                                             'Appraisal fee',
                        //                                             'First premium of mortgage Insurance (if applicable)',
                        //                                             'Title Insurance (yours and the lender’s)',
                        //                                             'Loan discount points (if applicable)',
                        //                                             'First payment to escrow account for future real estate taxes and insurance',
                        //                                             'Any documentation preparation fees',
                        //                                         ]
                        //                                     }
                        //                                 }
                        //                             }
                        //                         },
                        //                         {
                        //                             config: {
                        //                                 id: 'third-item',
                        //                                 buttonText: 'What do I get at closing?',
                        //                                 panelContent: {
                        //                                     cont001: {
                        //                                         meta: 'ordered-list',
                        //                                         content: [
                        //                                             'Settlement Statement',
                        //                                             'HUD-1 Form (itemizes all fees; it is filled out by the closing agent and must be given to you at or before closing)',
                        //                                             'Truth-in-Lending Statement',
                        //                                             'Mortgage Note',
                        //                                             'Deed of Trust',
                        //                                             'Binding Sales Contract'
                        //                                         ]
                        //                                     }
                        //                                 }
                        //                             }
                        //                         }
                        //                     ]
                        //                 }
                        //             },
                        //             {
                        //                 config: {
                        //                     id: 'second-topic',
                        //                     buttonText: 'What is a mortgage?',
                        //                     panelContent: {
                        //                         cont001: {
                        //                             meta: 'paragraph',
                        //                             content: ['Although the term “mortgage” is commonly used to describe the loan against the property, generally speaking, Arizona is a Deed of Trust state rather than a mortgage state and borrowers (buyers) will generally sign a Deed of Trust at closing. For most consumers the difference between a mortgage and a Deed of Trust are negligible and only has significance as it relates to the foreclosure process in the event of a borrow default. Both a Mortgage and a Deed of trust are liens (a legal claim) on the home or property that secures the promise to pay the debt.']
                        //                         }
                        //                     }
                        //                 }
                        //             },
                        //             {
                        //                 config: {
                        //                     id: 'third-topic',
                        //                     buttonText: 'What is mortgage insurance?',
                        //                     panelContent: {
                        //                         cont001: {
                        //                             meta: 'paragraph',
                        //                             content: ['Mortgage insurance is a policy that protects lenders against some or most of the losses that result from defaults on home mortgages. It’s required primarily for borrowers making a down payment of less than 20%.']
                        //                         }
                        //                     }
                        //                 }
                        //             }
                        //         ]
                        //     },
                        //     readableName: 'Closing'
                        // },
                    }
                }
            }
        }
    }
}
