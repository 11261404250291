import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import smoothscroll from 'smoothscroll-polyfill';
import focusoptions from 'focus-options-polyfill'; // eslint-disable-line no-unused-vars

const app = (
        <App />
);

smoothscroll.polyfill();
ReactDOM.render(app, document.getElementById('root'));
