import leadImage from '../assets/images/resources/HowToHireQualifiedRealtorLeadImage.png';

import logo from '../assets/images/resources/DocumentLogo.png';

export const data = {
    viewTitle: 'Buyer Resources',
    view: 'Buyers',
    leadImage: leadImage,
    leadImageAltText: 'home with beautiful pool',
    logo: logo,
    leadText: [
        'Congratulations on your decision to investigate how to better interview real estate agents.',
        'Bold Realty is a team of professional real estate brokers with three decades combined experience. It has been our experience that there are three critical areas of competence--Experience, Marketing and Service--that significantly impact how well you are represented.',
        'In the following pages, you will learn some of the most important questions to ask any REALTOR® you are considering hiring to represent you.',
        'Hiring the right professional to represent you can potentially save you thousands of dollars.',
        'To fully represent you, your agent will need to have the experience and the knowledge to price your property correctly, negotiate for your best interest, and understand the local real estate market.',
        'We believe the information in this guide will provide you with the confidence you need to hire the right real estate agent.',
        'Of course, if you are looking to sell a home in the metro Phoenix area we sincerely hope your choice will be Bold Realty.'
    ],
    sections: {
        sect001: {
            subsections: {
                subsect001: {
                    content: {
                        cont001: {
                            meta: 'heading',
                            content: 'How long have you been in business?'
                        },
                        cont002: {
                            meta: 'paragraph',
                            content: [
                                'There is a great deal of knowledge that only comes from years of experience. So be sure to ask your agent what year they were licensed.'
                            ]
                        },
                        cont003: {
                            meta: 'heading',
                            content: 'What are your stats?'
                        },
                        cont004: {
                            meta: 'paragraph',
                            content: [
                                'Nothing speaks louder than results. Few things can be manipulated more than statistics. If you simply ask how much business an agent did in the previous year they may tell you they are a multi-million-dollar producer. What they may not tell you is that it was a single sale. It takes many transactions—closed over many years to gain the knowledge and experience needed to maximum results. So, when interviewing your future real estate agent be sure to go a little deeper and ask:'
                            ]
                        },
                        cont005: {
                            meta: 'unorderedList',
                            content: [
                                'How many listings did you close last year?',
                                'How many properties did you list last year that didn’t close?'
                            ]
                        },
                        cont006: {
                            meta: 'heading',
                            content: 'What sets you apart from other agents?'
                        },
                        cont007: {
                            meta: 'paragraph',
                            content: [
                                'A great agent won’t hesitate to answer this question and will be ready to share with you what sets them apart from other agents. Be sure to also look for expertise which comes from experience—not just eagerness. While a great can-do attitude is invaluable and you want someone who is going to work hard for you—it cannot effectively substitute for good oldfashioned know-how.'
                            ]
                        },
                        cont008: {
                            meta: 'heading',
                            content: 'How many clients are you currently working with?'
                        },
                        cont009: {
                            meta: 'paragraph',
                            content: [
                                'A busy agent is the sign of a full-time professional. While it may feel wonderful to be the single recipient of the agent’s time it may work to your disadvantage when it comes to results.'
                            ]
                        },
                        cont010: {
                            meta: 'heading',
                            content: 'What type of training or education do you have in real estate?'
                        },
                        cont011: {
                            meta: 'paragraph',
                            content: [
                                'Most successful real estate agents devote time to continuing education. A well-educated agent will know more about the laws, practices and nuances of the real estate market. Commitment to education also indicates a higher degree of professionalism.'
                            ]
                        },
                        cont012: {
                            meta: 'heading',
                            content: 'On average, how close is the selling price to the asking price for your listings?'
                        },
                        cont013: {
                            meta: 'paragraph',
                            content: [
                                'This will help you determine whether the agent is skilled at calculating the value of real estate. This is important because an overpriced house will sit on the market way to long—ultimately affecting its value—while an underpriced home will keep you from maximizing your equity. You will want to look first for an agent that knows their number and second, has a number that is neither too high or too low. Too high may mean that they tend to recommend low prices to move properties quickly and too low may mean that they are unfamiliar with the market.'
                            ]
                        },
                        cont014: {
                            meta: 'heading',
                            content: 'On average how long does it take for your listings to sell?'
                        },
                        cont015: {
                            meta: 'paragraph',
                            content: [
                                'You will want to first ask what the market average is and then ask the agent their own statistic. Again, this will help you determine the skill of the agent.'
                            ]
                        },
                        cont016: {
                            meta: 'heading',
                            content: 'What is your track record in the market place?'
                        },
                        cont017: {
                            meta: 'paragraph',
                            content: [
                                'Because you want your home sold quickly and for the best possible price you should be asking the agents you interview how many homes they have sold. National statistics reveal that many agents sell fewer than 5 homes a year. This low production volume makes it difficult for them to do full impact marketing on your home because they are unlikely to have the financial resources necessary to maximize exposure for your home.'
                            ]
                        },
                        cont018: {
                            meta: 'heading',
                            content: 'What are your marketing plans for my home?'
                        },
                        cont019: {
                            meta: 'paragraph',
                            content: [
                                'An agent should have ready—and be willing to show you their written marketing plan for your home. If they cannot—or—do not show you their written plan it is probably safe to assume they do not have one. An agent without a plan probably only intends to place your home in the multiple listing service (MLS).'
                            ]
                        },
                        cont020: {
                            meta: 'heading',
                            content: 'Does you broker control your advertising or do you?'
                        },
                        cont021: {
                            meta: 'paragraph',
                            content: [
                                'If the agent does not have control of their own advertising, then your home will be competing with all the other listings of all of the agents in the brokerage.'
                            ]
                        },
                        cont022: {
                            meta: 'heading',
                            content: 'Are you placing pictures on your website and other listings services?'
                        },
                        cont023: {
                            meta: 'paragraph',
                            content: [
                                'More than 80% of all buyers start the search for their home on the internet. In a survey by the National Association of Realtors, homebuyers rated photos as the feature they use most when searching for a home on the web. For this reason, it is imperative that the agent you choose to market your home is ready and able to use photographs to help market your home. Profession photos similarly impact future buyers so make sure your future real estate agent is committed to professional photos for your home.'
                            ]
                        },
                        cont024: {
                            meta: 'heading',
                            content: 'How do you decide what price range to recommend for marketing my home?'
                        },
                        cont025: {
                            meta: 'paragraph',
                            content: [
                                'Choosing a price range is a key consideration when choosing an agent. There are three possibilities to choosing the list price:'
                            ]
                        },
                        cont026: {
                            meta: 'unorderedList',
                            content: [
                                'Market Value–-ideal market time',
                                'Overpriced—-will linger on market',
                                'Underpriced—-will sacrifice equity'
                            ]
                        },
                        cont027: {
                            meta: 'paragraph',
                            content: [
                                'Some agents will tell you what you want to hear just to get the listing. Ideally, you are looking for an agent who understands the market and uses comparable sales information to establish the suggested asking price.'
                            ]
                        },
                        cont028: {
                            meta: 'heading',
                            content: 'What is your commission?'
                        },
                        cont029: {
                            meta: 'paragraph',
                            composite: true,
                            content: [
                                {
                                    type: 'span',
                                    content: 'There’s an old adage that you get what you pay for and this is often true when selecting the professional that will represent you. Be sure to compare the services being offered and then '
                                },
                                {
                                    type: 'bold',
                                    content: 'choose the service'
                                },
                                {
                                    type: 'span',
                                    content: '—not the price—that best fits your needs. Selecting a lesser service may end up costing more than the full-service option.'
                                }
                            ]
                        },
                        cont030: {
                            meta: 'heading',
                            content: 'Do you have time to devote to my needs?'
                        },
                        cont031: {
                            meta: 'paragraph',
                            content: [
                                'Be sure to probe how much business the agent is currently handling in order to determine if the agent will have enough time to meet your needs. However, keep in mind a busy agent may be just what you need to get the job done. After all, they probably have the systems, tools and resources that are needed. Also, find out who you will be working with throughout the transaction. May agents “pass off” the agreement is signed so you will want to know who you will be working with. Few assistants or employees will embrace your home sale like a business owner will.'
                            ]
                        },
                        cont032: {
                            meta: 'heading',
                            content: 'What happens if I’m not happy with the job you are doing? Can I cancel my contract?'
                        },
                        cont033: {
                            meta: 'paragraph',
                            content: [
                                'In Arizona, listing contracts are not able to be unilaterally cancelled. This means that if you are unhappy with the service being provided by the agent you may be stuck with them until the end of the listing period unless you negotiate a cancellation clause in your listing contract. Be sure to select a listing agent that is confident enough in the service they provide that they are willing to back it with a guarantee. '
                            ]
                        },
                        cont034: {
                            meta: 'heading',
                            content: 'How can I prepare to show my home?'
                        },
                        cont035: {
                            meta: 'paragraph',
                            composite: true,
                            content: [
                                {
                                    type: 'span',
                                    content: 'If an agent is not willing to give you honest feedback when necessary about what makes a home more appealing to buyers there’s a good chance they will be meek in other aspects of the transaction. You will want to select and agent who’s not afraid to tell you to clean out your closets so they look less crowded or keep the dishes done. At the end of the day if your agent is afraid to stand-up '
                                },
                                {
                                    type: 'bold',
                                    content: 'TO'
                                },
                                {
                                    type: 'span',
                                    content: '—there’s a good chance they will be unwilling or unable to stand up '
                                },
                                {
                                    type: 'bold',
                                    content: 'FOR'
                                },
                                {
                                    type: 'span',
                                    content: ' you.'
                                }
                            ]
                        },
                        cont036: {
                            meta: 'heading',
                            content: 'Can I have the names and phone numbers of the last 3 people that listed with you?'
                        },
                        cont037: {
                            meta: 'paragraph',
                            content: [
                                'Every agent has clients whom they have served well and whom they can use as references. A great agent serves nearly all their clients well. Getting an agent’s last three to five clients will give you a more realistic picture of the agent’s service than a list of pre-selected references.'
                            ]
                        }
                    }
                }
            }
        }
    }
}
