import React, { useState, useRef, forwardRef, useEffect, useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import Input from '../UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import AbsolutePositionModal from '../../components/UI/AbsolutePositionModal/AbsolutePositionModal';
import ContactFormContext from '../../context/ContactFormContext';
import axios from 'axios';
import classes from './ContactForm.module.css';
import successIcon from '../../assets/images/statuses/success-icon.png';
import warningIcon from '../../assets/images/statuses/warning-icon.png';
import errorIcon from '../../assets/images/statuses/error-icon.png';

const ContactForm = forwardRef(( {props}, contactFormRefs ) => {
    const { scrollToContactFormResult } = useContext(ContactFormContext);
    const phoneNumberInputRef = useRef();
    const formSubmissionStatusRef = useRef();
    const formSubmissionOkButtonRef = useRef();

    const [contactForm, setContactForm] = useState({
        name: {
            elementType: 'text',
            label: 'Name',
            spokenLabel: 'Name, please enter your name',
            placeholder: 'Enter Your Name',
            valueType: 'text',
            value: '',
            autoComplete: 'name',
            ref: contactFormRefs.firstInputRef,
            validationRules: [
                {required: true, errorMessage: 'Required'}
            ],
            validationErrorMsg: null
        },
        email: {
            elementType: 'text',
            label: 'Email Address',
            spokenLabel: 'Email Address, please enter your email address',
            placeholder: 'Enter Your Email Address',
            valueType: 'email',
            value: '',
            autoComplete: 'email',
            ref: null,
            validationRules: [
                {required: true, errorMessage: 'Required'},
                {format: 'emailAddress', errorMessage: 'Invalid Entry'}
            ],
            validationErrorMsg: null
        },
        phone: {
            elementType: 'text',
            label: 'Phone Number',
            spokenLabel: 'Phone Number, please enter your phone number',
            placeholder: 'Enter Your Phone Number',
            valueType: 'tel',
            value: '',
            autoComplete: 'tel-national',
            ref: phoneNumberInputRef,
            validationRules: [
                {format: 'phoneNumber', errorMessage: 'Invalid Entry'}
            ],
            validationErrorMsg: null
        },
        message: {
            elementType: 'textarea',
            label: 'How Can We Help?',
            spokenLabel: 'How Can We Help? please enter your message',
            placeholder: 'Enter Your Message',
            valueType: 'text',
            value: '',
            autoComplete: null,
            ref: null,
            validationRules: [
                {required: true, errorMessage: 'Required'}
            ],
            validationErrorMsg: null
        }
    });

    const [validationErrors, setValidationErrors] = useState([]);
    const [formSubmissionStatus, setFormSubmissionStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [lastFocused, setLastFocused] = useState(null);
    const location = useLocation();

    const clearForm = useCallback(() => {
        const updatedContactForm = {
            ...contactForm
        };
        for (let formElementIdentifier in updatedContactForm) {
            const updatedFormElement = {
                ...updatedContactForm[formElementIdentifier]
            };
            updatedFormElement.value = '';
            updatedFormElement.validationErrorMsg = null;
            updatedContactForm[formElementIdentifier] = updatedFormElement;
        }
        setContactForm(updatedContactForm);
        setValidationErrors([]);
    }, [contactForm]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ( !event.keyCode || event.keyCode === 27 ) {
                event.preventDefault();
                setFormSubmissionStatus(null);
            }
            if (event.key === 'Tab' || event.keyCode === 9) {
                if (event.shiftKey) {
                    if (document.activeElement === formSubmissionStatusRef.current) {
                        event.preventDefault();
                        formSubmissionOkButtonRef.current.focus();
                    }
                }
                else if (document.activeElement === formSubmissionOkButtonRef.current) {
                    event.preventDefault();
                    event.stopPropagation(); // need both preventDefault and stopPropagation ???
                    formSubmissionStatusRef && formSubmissionStatusRef.current.focus();
                }
            }
        }

        const handleClick = (event) => {
            if (contactFormRefs.contactFormRef.current && !contactFormRefs.contactFormRef.current.contains(event.target)) {
                event.preventDefault();
                setLastFocused(null);
                setFormSubmissionStatus(null);
            }
        }
        if (formSubmissionStatus !== null) {
            formSubmissionStatusRef.current.focus();
            document.addEventListener('keydown', handleKeyDown);
            document.addEventListener('click', handleClick);
        }
        else {
            if (lastFocused) {
                lastFocused.focus();
            }
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('click', handleClick);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('click', handleClick);
        }
    }, [formSubmissionStatus, contactFormRefs.contactFormRef, lastFocused]);

    useEffect(() => {
        clearForm();
    }, [location]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedContactForm = {
            ...contactForm
        };
        const updatedFormElement = {
            ...updatedContactForm[inputIdentifier]
        };

        let updatedValue = event.target.value;

        const validationErrorArr = validationErrors;
        if (validationErrorArr.some(error => error.inputId === inputIdentifier)) {
            updatedFormElement.validationErrorMsg = null;
        }

        if (inputIdentifier === 'phone') {
            updatedValue = formatPhoneNumber(updatedValue);
        }

        updatedFormElement.value = updatedValue;
        updatedContactForm[inputIdentifier] = updatedFormElement;

        setContactForm(updatedContactForm);
        setValidationErrors(validationErrorArr);
    };

    const checkValidity = (value, rules) => {
        let errMsg = null;
        if (rules) {
            for (let i = 0; i < rules.length; i++) {
                const rule = rules[i];
                if (rule.required && value === '') {
                    errMsg = rule.errorMessage;
                    break;
                }
                else if (value && rule.format === 'phoneNumber' && !validatePhoneNumber(value)) {
                    errMsg = rule.errorMessage;
                    break;
                }
                else if (rule.format === 'emailAddress' && !validateEmailAddress(value)) {
                    errMsg = rule.errorMessage;
                    break;
                }
            }
        }
        return errMsg;
    }

    const checkInputValidity = (value, inputIdentifier) => {
        const updatedContactForm = {
            ...contactForm
        };
        const updatedFormElement = {
            ...updatedContactForm[inputIdentifier]
        };

        const validationErrorArr = validationErrors;

        const trimmedValue = updatedFormElement.value.trim();
        updatedFormElement.value = trimmedValue;

        // remove any existing error for said input, prior to validating
        const currentInputErrorIndex = validationErrorArr.findIndex(element => element.inputId === inputIdentifier);
        if (currentInputErrorIndex !== -1) {
            validationErrorArr.splice(currentInputErrorIndex, 1);
        }

        const rules = updatedFormElement.validationRules;
        const validationErrorMsg = checkValidity(value, rules);

        if (validationErrorMsg) {
            validationErrorArr.push({inputId: inputIdentifier, errorMessage: validationErrorMsg});
            updatedFormElement.validationErrorMsg = validationErrorMsg;
        }
        else {
            updatedFormElement.validationErrorMsg = null;
        }

        updatedContactForm[inputIdentifier] = updatedFormElement;

        setContactForm(updatedContactForm);
        setValidationErrors(validationErrorArr);
    }

    const checkFormValidity = () => {
        const updatedContactForm = {
            ...contactForm
        };

        const validationErrorArr = validationErrors;

        for (let formElementIdentifier in contactForm) {
            const updatedFormElement = {
                ...updatedContactForm[formElementIdentifier]
            };
            const value = updatedFormElement.value;

            // remove any existing error for the input, prior to validating
            const currentInputErrorIndex = validationErrorArr.findIndex(element => element.inputId === formElementIdentifier);
            if (currentInputErrorIndex !== -1) {
                validationErrorArr.splice(currentInputErrorIndex, 1);
            }

            const rules = updatedFormElement.validationRules;
            const errorMessage = checkValidity(value, rules);

            if (errorMessage) {
                updatedFormElement.validationErrorMsg = errorMessage;
                validationErrorArr.push({inputId: formElementIdentifier, errorMessage: errorMessage});
            }
            else {
                updatedFormElement.validationErrorMsg = null;
            }
            updatedContactForm[formElementIdentifier] = updatedFormElement;
        }
        setContactForm(updatedContactForm);
        setValidationErrors(validationErrorArr);

        if (validationErrors.length !== 0) {
            setFormSubmissionStatus({
                type: 'warning',
                icon: warningIcon,
                iconAlt: 'warning',
                message: 'One or more fields have errors.  Please check your entries and try again.'
            });
        }
    }

    const sendMessageHandler = (event) => {
        event.preventDefault();
        setLastFocused(event.target);
        scrollToContactFormResult(() => sendMessage());
    }

    const sendMessage = (event) => {
        checkFormValidity();
        if (validationErrors.length === 0) {
            setLoading(true);
            const completedContactForm = {
                ...contactForm
            };
            const emailDetails = {
                'customerDetails': {
                    name: completedContactForm['name'].value,
                    phoneNumber: completedContactForm['phone'].value,
                    emailAddress: completedContactForm['email'].value
                },
                'messageDetails': {
                    subject: 'Customer Message -- Bold Realty Contact Us Form',
                    message: completedContactForm['message'].value
                }
            };
            axios({
                method: 'POST',
                url: 'https://6o49ksfy7h.execute-api.us-west-2.amazonaws.com/v1',
                data: emailDetails
            })
            .then( (response) => {
                setLoading(false);
                if (response.status === 204) {
                    setFormSubmissionStatus({
                        type: 'success',
                        icon: successIcon,
                        iconAlt: 'success',
                        message: 'We received your message and will reach out to you soon!'
                    });
                    clearForm();
                }
                else {
                    setFormSubmissionStatus({
                        type: 'error',
                        icon: errorIcon,
                        iconAlt: 'error',
                        message: 'We\'re sorry, but an error occurred while sending your message.'
                    });
                }
            })
            .catch( (error) => {
                setLoading(false);
                setFormSubmissionStatus({
                    type: 'error',
                    icon: errorIcon,
                    iconAlt: 'error',
                    message: 'We\'re sorry, but an error occurred while sending your message.'
                });
                console.log(error);
            });
        }
    }

    const clearFormHandler = (event) => {
        event.preventDefault();
        clearForm();
    }

    const formatPhoneNumber = (value) => {
        value = value.trim();
        value = value.replace(/\D/g,'');
        if (value.length > 3 && value.length <= 6) {
            value = "(" + value.slice(0,3) + ") " + value.slice(3);
        }
        else if (value.length > 6) {
            value = "(" + value.slice(0,3) + ") " + value.slice(3,6) + "-" + value.slice(6);
        }
        return (value.length > 14) ? value.slice(0, -1) : value;
    }

    const validatePhoneNumber = (value) => {
        // var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
        var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/;
        var digits = value.replace(/\D/g, "");
        return phoneRe.test(digits);
    }

    const validateEmailAddress = (value) => {
        // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value.toLowerCase());
    }

    const formElementsArray = [];
    for (let key in contactForm) {
        const inputElementDetails = contactForm[key];

        formElementsArray.push({
            id: key,
            label: inputElementDetails.label,
            config: inputElementDetails,
            validationErrorMsg: inputElementDetails.validationErrorMsg
        });
    }

    let content = (
        <div
            style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/images/coffee-computer.jpg'})`}}
            className={classes.ContactForm}
            ref={contactFormRefs.contactFormRef}
        >
            {loading
                ?   <AbsolutePositionModal clicked={() => {} }>
                        <Spinner />
                    </AbsolutePositionModal>
                :   null
            }
            {(formSubmissionStatus !== null)
                ?   <AbsolutePositionModal
                        clicked={() => setFormSubmissionStatus(null)}
                        role='dialog'
                    >
                        <div
                            ref={formSubmissionStatusRef}
                            className={classes.FormSubmissionStatus}
                            tabIndex='0'
                            aria-label={formSubmissionStatus.message}
                        >
                            <button
                                className={classes.CloseButton}
                                onClick={() => {setFormSubmissionStatus(null)}}
                                aria-label='close'
                            >
                                &#10005;
                            </button>
                            <img className={classes.FormSubmissionStatusIcon} src={formSubmissionStatus.icon} alt={formSubmissionStatus.iconAlt} />
                            <span className={classes.Message}>
                                {formSubmissionStatus.message}
                            </span>
                            <Button
                                ref={formSubmissionOkButtonRef}
                                types={['contained', 'large']}
                                clicked={() => {setFormSubmissionStatus(null)}}
                            >
                                OK
                            </Button>
                        </div>
                    </AbsolutePositionModal>
                :   null
            }
            <form
                onSubmit={sendMessageHandler}
                // aria-label='Contact Us Form.  We would love to hear from you!  Use this form to send us a message, and we will get back to you shortly'
                aria-describedby='formDescription'
                // tabIndex='0'
            >
                <h3>Get In Touch...</h3>
                <p
                    id='formDescription'
                    className={classes.FormDescription}
                >
                    We'd love to hear from you!<br></br>
                    Send us a message and we'll get back to you shortly.
                </p>
                <div
                    className={classes.Inputs}
                    aria-label='Contact Us Form'
                >
                    {
                        Object.keys(contactForm).map((inputKey) => {
                            const formInput = contactForm[inputKey];
                            return <Input
                                key={inputKey}
                                id={inputKey}
                                name={inputKey}
                                label={formInput.label}
                                screenReaderText={formInput.spokenLabel}
                                elementType={formInput.elementType}
                                elementConfig={{
                                    type: formInput.valueType,
                                    placeholder: formInput.placeholder,
                                    value: formInput.value,
                                    autoComplete: formInput.autoComplete
                                }}
                                validationRules={formInput.validationRules}
                                errMessage={formInput.validationErrorMsg}
                                inputRef={formInput.ref}
                                changed={(event) => inputChangedHandler(event, inputKey)}
                                blurred={(event) => checkInputValidity(event.target.value, inputKey)}
                            />
                        })
                    }
                </div>
                <div className={classes.ButtonContainer}>
                    <Button
                        types={['contained', 'large']}
                        clicked={sendMessageHandler}
                        screenReaderText='Submit button.  Use this button to send to our team the contact info and message you entered on our contact form.'
                    >
                        Submit
                    </Button>
                    <Button
                        types={['contained', 'large']}
                        clicked={clearFormHandler}
                        screenReaderText='Clear button.  Use this button to clear the information you entered on our contact form.'
                    >
                        Clear
                    </Button>
                </div>
            </form>
        </div>
    );

    return (
        <div>
            {content}
        </div>
    )
})

export default ContactForm;
