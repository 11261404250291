import { useState } from 'react';

const useDeclarativeScrollAndFocus = () => {
    const [hideHeader, setHideHeader] = useState(false);
    const [enableHideHeader, setEnableHideHeader] = useState(true);

    const handleScroll = (scrollTarget = null, focusTarget = null, callback = () => {}) => {
        if (scrollTarget) {
            window.scrollTo(
                {
                    top: scrollTarget.current.offsetTop,
                    left: 0,
                    behavior: 'smooth'
                });
            hideHeaderOnScroll(focusTarget, callback);
        }
    }

    const scrollAndFocus = (scrollTarget, focusTarget, callback = () => {}) => {
        const scrollTargetOffset = scrollTarget.current.offsetTop - window.pageYOffset;
        if (scrollTargetOffset !== 0) {
            handleScroll(scrollTarget, focusTarget, callback);
        }
        else {
            focusOnTarget(focusTarget, callback);
        }
    }

    const scrollTo = (scrollTarget, callback = () => {}) => {
        const scrollTargetOffset = scrollTarget.current.offsetTop - window.pageYOffset;
        if (scrollTargetOffset !== 0) {
            handleScroll(scrollTarget, null, callback);
        }
        else {
            callback();
        }
    }

    const hideHeaderOnScroll = (focusTarget, callback = () => {}) => {
        let scrollTimeout;
        const awaitScrollStop = (event) => {
            window.clearTimeout(scrollTimeout);
            setHideHeader(true);
            setEnableHideHeader(false);

            scrollTimeout = setTimeout(function() {
                scrollTimeout = null;
                setEnableHideHeader(true);
                window.clearTimeout(scrollTimeout);
                window.removeEventListener('scroll', awaitScrollStop);
                if (focusTarget) {
                    focusOnTarget(focusTarget, callback);
                }
                else {
                    callback();
                }
            }, 400);
        }
        window.addEventListener('scroll', awaitScrollStop, false);
    }

    const focusOnTarget = (target, callback = () => {}) => {
        if (target.current.tabIndex !== 0) {
            target.current.setAttribute('tabindex', '-1');
            target.current.focus({ preventScroll: true });
        }
        else {
            target.current.focus({ preventScroll: true });
        }
        callback();
    }

    return { scrollTo, scrollAndFocus, focusOnTarget, hideHeader, setHideHeader, enableHideHeader, hideHeaderOnScroll};
};

export default useDeclarativeScrollAndFocus;
