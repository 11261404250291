import React, { useEffect, useState, useRef, forwardRef } from 'react';

import classes from './Testimonial.module.css';

const Testimonial = forwardRef((
    {
        clicked,
        focused = () => {},
        blurred = () => {},
        expand,
        onMobileViewport,
        testimonialDetails
    }, testimonialContentRef ) => {
    const [expanded, setExpanded] = useState(false);
    const [expandable, setExpandable] = useState(false);
    const testimonial = {...testimonialDetails};
    const testimonialWrapperRef = useRef(testimonial.id);
    const testimonialBodyRef = useRef();

    const DESKTOP_BODY_HIDE_OFFSET_HEIGHT = 48;
    const MOBILE_BODY_HIDE_OFFSET_HEIGHT = 36;

    const spokenTestimonial = `a testimonial, by ${testimonial.sourceName}
        ${(testimonial.sourceLocation !== null) ? `, in ${testimonial.sourceLocation}` : ''},
        on ${testimonial.headline}, saying ${testimonial.review}`;

    const handleClick = (event) => {
        event.preventDefault();
        if (clicked) {
            clicked();
        }
        else if (expandable) {
            setExpanded( (expandedCurrentValue) => !expandedCurrentValue );
        }
    }

    useEffect( () => {
        if (onMobileViewport && testimonialBodyRef.current.offsetHeight > MOBILE_BODY_HIDE_OFFSET_HEIGHT) {
            setExpandable(true);
            setExpanded(false);
        }
        else if (!onMobileViewport && testimonialBodyRef.current.offsetHeight > DESKTOP_BODY_HIDE_OFFSET_HEIGHT) {
            setExpandable(true);
            setExpanded(false);
        }
        else {
            setExpandable(false);
            setExpanded(true);
        }

        if (expand) {
            setExpanded(true);
        }
        else {
            setExpanded(false);
        }
    }, [onMobileViewport, testimonialDetails, expand]);

    const testimonialWrapperClasses = [classes.TestimonialWrapper];
    if (!expanded) {
        testimonialWrapperClasses.push(classes.HideFullTestimonial);
    }
    if (expandable) {
        testimonialWrapperClasses.push(classes.Expandable);
    }

    return (
        <div
            className={classes.Testimonial}
            ref={testimonialContentRef}
            onFocus={focused}
            onBlur={blurred}
            aria-label={spokenTestimonial}
        >
            <b className={classes.TestimonialHeadline}>
                {testimonial.headline}
            </b>
            <div
                className={testimonialWrapperClasses.join(' ')}
                ref={testimonialWrapperRef}
            >
                <div
                    ref={testimonialBodyRef}
                    className={classes.TestimonialBody}
                >
                    {testimonial.review}
                </div>
            </div>
            <div className={classes.ButtonPanel}>
            {expandable
                ? <button
                    className={classes.ExpandButton}
                    onClick={handleClick}
                    onFocus={focused}
                    onBlur={blurred}
                >
                    {expanded
                        ? 'Read Less'
                        : 'Read More'}
                </button>
                : null}
            </div>
            <b className={classes.TestimonialSource}>
                <i>
                    {`${testimonial.sourceName}${(testimonial.sourceLocation !== null) ? ` - ${testimonial.sourceLocation}` : ''}`}
                </i>
            </b>
        </div>
    );
});

export default Testimonial;
