import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationItem.module.css';

const NavigationItem = (props) => {
    return (
        <li className={classes.NavigationItem}>
            <NavLink
                to={props.link}
                exact={props.exact}
                activeClassName={classes.active}
                aria-current={props.currentRoute ? 'page' : null}
            >
                {props.children}
            </NavLink>
        </li>
    );
}

export default NavigationItem;
