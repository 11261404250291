import React from 'react';
import { NavLink } from 'react-router-dom';

import error404Image from '../../assets/images/other/404-page.jpg';

import classes from './NotFound.module.css';

const NotFound = ( props ) => {
    return (
        <div className={classes.NotFound}>
                <div className={classes.ImageWrapper}>
                    <img
                        src={error404Image}
                        alt={'404 Error'}
                    />
                    <div className={classes.ImageSmoothEdge}></div>
                </div>
                <div className={classes.LeadText}>
                    <h1>404 - Page Not Found</h1>
                    <span>We're sorry....We love Bold adventurers, but we cannot find the page you were hoping for....</span>
                </div>
            <section className={classes.HelpfulLinksSection}>
                <span>Consider the following to help you on the path to awesome resources:</span>
                <ul className={classes.HelpfulLinks}>
                    <li>Check out our <NavLink to="/about">About</NavLink> page to learn about our Mission and Values and get to know our awesome team better.</li>
                    <li>To see how we have delivered excellent results, take a look at our <NavLink to="/results">Results</NavLink> page.</li>
                    <li>Browse through our informative resources for <NavLink to="/buyers">Buyers</NavLink> and <NavLink to="/sellers">Sellers</NavLink>.</li>
                    <li>You can also start fresh at our <NavLink to="/">Home</NavLink> page.</li>
                    <li>To get personalized assistance from one of our excellent team members, send us a note using the contact form below.</li>
                </ul>
            </section>
        </div>
    );
}

export default NotFound;
