import jimCarynPhoto from '../assets/images/team/Jim-and-Caryn-Kommers.jpg';
import kuhmanPhoto from '../assets/images/team/Kuhman.jpg';
import tammyPhoto from '../assets/images/team/Tammy Pugh.jpg';
export const teamData = {
    heading: 'Meet the Bold Realty Team',
    teamDetails: [
        {
            id: 0,
            name: 'Caryn and Jim Kommers',
            imageSrc: jimCarynPhoto,
            imageAlt: 'Caryn and Jim Kommers',
            members: [
                {id: 0, name: 'Caryn Kommers', title: 'Owner and Designated Broker'},
                {id: 1, name: 'Jim Kommers', title: 'Partner'}
            ],
            bio: 'First licensed in January 1995, Caryn Kommers has worked full time in residential real estate since.  In the first years, she moved with her spouse around the country and so restarted her business in four different cities.  She\'s very happy to be permanently in the Phoenix metro area since 2000.  99% of Caryn\'s business is referrals and repeat clients.  Through the work she does she\'s made many, many friends out of relationships that started purely as business.  She even met her husband, Jim, through a real estate transaction in 2009.  Caryn has a heart for dogs, cooking, travel, home improvement projects, her church and people.  Jim has been around real estate for his entire relationship with Caryn.  Their official real estate partnership is now in full-force.  On the side Jim is a Patient Advocate assisting families with treatment options for cancers and serious illnesses, navigating insurance dramas and providing hope during very scary journeys to getting a loved one well.',
            contactName: 'Caryn and Jim Kommers',
            phoneNumber: '6023730115',
            phoneStr: '(602) 373-0115',
            spokenPhone: '6 0 2 . 3 7 3 . 0 1 1 5',
            email: 'carynkommers@gmail.com',
            spokenEmail: 'caryn kommers @gmail.com'
        },
        {
            id: 1,
            name: 'Jody and Tom Kuhman',
            imageSrc: kuhmanPhoto,
            imageAlt: 'Jody and Tom Kuhman',
            members: [
                {name: 'Jody & Tom Kuhman', title: 'Partners'}
            ],
            bio: 'Jody and Tom work full time as a team to provide clients with the best real estate experience.  Their combined 20+ years in the business has resulted in the knowledge and understanding necessary to assist clients with nearly every real estate need they have. Relationships, referrals, trust, and strong negotiating skills are at the core of the excellent service they provide, ensuring every client receives the best service and outcome for their real estate goals.',
            contactName: 'Jody and Tom Kuhman',
            phoneNumber: '4804426841',
            phoneStr: '(480) 442-6841',
            spokenPhone: '4 8 0 . 4 4 2 . 6 8 4 1',
            email: 'jodykuhman@gmail.com',
            spokenEmail: 'jody kuhman @gmail.com'
        },
        {
            id: 2,
            name: 'Tammy Pugh',
            imageSrc: tammyPhoto,
            imageAlt: 'Tammy Pugh',
            members: [
                {name: 'Tammy Pugh', title: 'Associate Realtor'}
            ],
            bio: 'First licensed in 2007, Tammy brings lots of experience in real estate, specializing in the East Valley of the Phoenix metro area.  She brings excellent customer service and a commitment to work hard, listen and follow through. She provides quality service to build relationships with clients and, more importantly, maintain those relationships by communicating effectively. Clients appreciate her ability to hear what a client wants; her excellent negotiation skills and effective communication skills result in the achievement of the client\'s dream of home ownership or home sale.',
            contactName: 'Tammy Pugh',
            phoneNumber: '4802341888',
            phoneStr: '(480) 234-1888',
            spokenPhone: '4 8 0 . 2 3 4 . 1 8 8 8',
            email: 'ask4Tammy@gmail.com',
            spokenEmail: 'ask the number 4 Tammy @gmail.com'
        }
    ]
}
