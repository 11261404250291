import closing1Image from '../assets/images/recent-closings/closed-1.jpg';
import closing2Image from '../assets/images/recent-closings/closed-2.jpg';
import closing3Image from '../assets/images/recent-closings/closed-4.jpg';
import closing4Image from '../assets/images/recent-closings/closed-4 copy.jpg';
import closing5Image from '../assets/images/recent-closings/closed-3.jpg';
import closing6Image from '../assets/images/recent-closings/closed-6.jpg';
import closing7Image from '../assets/images/recent-closings/closed-7.jpg';
import closing8Image from '../assets/images/recent-closings/closed-8.jpg';
import closing9Image from '../assets/images/recent-closings/closed-9.jpg';
import closing10Image from '../assets/images/recent-closings/closed-10.jpg';
import closing11Image from '../assets/images/recent-closings/closed-11.jpg';

export const closings = {
    allClosings: [
        {
            id: 0,
            image: closing1Image,
            dwellingType: 'Home',
            salePrice: '3,120,000',
            numberOfBedrooms: 6,
            numberOfBathrooms: 7.5,
            sqFootage: '7,945',
            area: 'Paradise Valley'
        },
        {
            id: 1,
            image: closing2Image,
            dwellingType: 'Home',
            salePrice: '738,500',
            numberOfBedrooms: 5,
            numberOfBathrooms: 3.5,
            sqFootage: '3,888',
            area: 'Mesa'
        },
        {
            id: 2,
            image: closing4Image,
            dwellingType: 'Home',
            salePrice: '410,000',
            numberOfBedrooms: 4,
            numberOfBathrooms: 2,
            sqFootage: '1,766',
            area: 'Tempe'
        },
        {
            id: 3,
            image: closing5Image,
            dwellingType: 'Townhouse',
            salePrice: '550,000',
            numberOfBedrooms: 3,
            numberOfBathrooms: 2,
            sqFootage: '1,667',
            area: 'Scottsdale'
        },
        {
            id: 4,
            image: closing6Image,
            dwellingType: 'Home',
            salePrice: '680,000',
            numberOfBedrooms: 4,
            numberOfBathrooms: 3,
            sqFootage: '3,686',
            area: 'Mesa'
        },
        {
            id: 5,
            image: closing10Image,
            dwellingType: 'Home',
            salePrice: '439,900',
            numberOfBedrooms: 4,
            numberOfBathrooms: 2.5,
            sqFootage: '2,992',
            area: 'San Tan Valley'
        },
        {
            id: 6,
            image: closing7Image,
            dwellingType: 'Home',
            salePrice: '275,500',
            numberOfBedrooms: 2,
            numberOfBathrooms: 2,
            sqFootage: '1,431',
            area: 'Phoenix'
        },
        {
            id: 7,
            image: closing8Image,
            dwellingType: 'Home',
            salePrice: '485,000',
            numberOfBedrooms: 3,
            numberOfBathrooms: 2,
            sqFootage: '1,960',
            area: 'Mesa'
        },
        {
            id: 8,
            image: closing11Image,
            dwellingType: 'Home',
            salePrice: '360,000',
            numberOfBedrooms: 3,
            numberOfBathrooms: 2,
            sqFootage: '1,681',
            area: 'Glendale'
        },
        {
            id: 9,
            image: closing9Image,
            dwellingType: 'Home',
            salePrice: '415,000',
            numberOfBedrooms: 3,
            numberOfBathrooms: 2,
            sqFootage: '1,322',
            area: 'Gilbert'
        }
    ],
    sliderClosings: [
        {
            id: 0,
            image: closing1Image,
            imageAlt: 'Home in Paradise Valley closed at 3,120,000 dollars',
            salePrice: '3,120,000',
            area: 'Paradise Valley',
            overlayTextColor: '#D0B052',
            overlayTextShadowColor: '#F07023'
        },
        {
            id: 1,
            image: closing3Image,
            imageAlt: 'Home in Tempe closed at 410,000 dollars',
            salePrice: '410,000',
            area: 'Tempe',
            overlayTextColor: '#0428ED',
            overlayTextShadowColor: 'yellow'
        },
        {
            id: 2,
            image: closing2Image,
            imageAlt: 'Home in Mesa closed at 738,500 dollars',
            salePrice: '738,500',
            area: 'Mesa',
            overlayTextColor: '#F07023',
            overlayTextShadowColor: '#04386D'
        },
        {
            id: 3,
            image: closing10Image,
            imageAlt: 'Home in San Tan Valley closed at 439,900 dollars',
            salePrice: '439,900',
            area: 'San Tan Valley',
            overlayTextColor: '#F07023',
            overlayTextShadowColor: '#04386D'
        }
    ]
}
