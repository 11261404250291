import equalHousingLogo from '../assets/images/icons/white-equal-housing-opportunity-transparent.png';
import realtorLogo from '../assets/images/icons/white-realtor-transparent.png';
import mlsLogo from '../assets/images/icons/white-mls-transparent.png';

export const footerData = {
    requiredLogoGroupImages: [
        {
            id: 0,
            src: equalHousingLogo,
            alt: 'Equal Housing Opportunity logo'
        },
        {
            id: 1,
            src: realtorLogo,
            alt: 'Realtor logo'
        },
        {
            id: 2,
            src: mlsLogo,
            alt: 'MLS logo'
        }
    ],
    companyContactInfo: {
        physicalAddress: {
            googleMapUrl: 'https://goo.gl/maps/nfs5RHzrKVt92Xnu8',
            streetAddress1: '4745 S Burma Rd',
            streetAddress2: 'Gilbert, AZ  85297',
            spokenAddress: '4 7 4 5 S Burma Rd, Gilbert, Arizona  8 5 2 9 7'
        },
        faxNumber: '4802125467',
        faxStr: '(480) 212-5467',
        spokenFax: '4 8 0 . 2 1 2 . 5 4 6 7'
    }
}
