import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';

import CollapsibleSubmenu from '../../../components/Navigation/CollapsibleSubmenu/CollapsibleSubmenu';
import Backdrop from '../../../components/Backdrop/Backdrop';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
import ScrollContext from '../../../context/ScrollContext';

import classes from './SideNavPanel.module.css';

const SideNavPanel = ({
    viewName,
    mainContent
}) => {
    const [activeSectionKey, setActiveSectionKey] = useState(null);
    const [isMobileWidth, setIsMobileWidth] = useState(false);
    const [showMobileNav, setShowMobileNav] = useState(false);
    const mobileButtonRef = useRef();
    const sideNavWrapperRef = useRef();
    const sideNavItemsRef = useRef();
    const windowDimensions = useWindowDimensions();
    const { hideHeaderOnScroll } = useContext(ScrollContext);

    const closeMobileNav = useCallback(() => {
        setShowMobileNav(false);
    }, [setShowMobileNav]);

    useEffect(() => {
        const links = sideNavItemsRef.current.getElementsByTagName('a');
        const firstLink = links[0];

        const keyDownHandler = (event) => {
            if (!event.keyCode || event.keyCode === 27) {
                closeMobileNav();
                mobileButtonRef.current.focus();
            }
            if (event.key === 'Tab' || event.keyCode === 9) {
                if (event.shiftKey) {
                    if (document.activeElement === firstLink) {
                        event.preventDefault();
                        mobileButtonRef.current.focus();
                    }
                }
                else {
                    if (document.activeElement === mobileButtonRef.current) {
                        event.preventDefault();
                        firstLink.focus();
                    }
                }
            }
        }

        const clickHandler = (event) => {
            // if (!mobileButtonRef.current.contains(event.target) && !sideNavWrapperRef.current.contains(event.target)) {
            if (!sideNavWrapperRef.current.contains(event.target)) {
                closeMobileNav();
            }
        }

        if (showMobileNav && isMobileWidth) {
            document.addEventListener('keydown', keyDownHandler);
            document.addEventListener('click', clickHandler);
        }
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
            document.removeEventListener('click', clickHandler);
        }
    }, [showMobileNav, isMobileWidth, closeMobileNav]);

    useEffect(() => {
        if (windowDimensions.width > 1100) {
            setIsMobileWidth(false);
        }
        else {
            setIsMobileWidth(true);
        }
    }, [windowDimensions]);

    const handleSectionNavClicked = (sectionKey) => {
        setActiveSectionKey(sectionKey);
        if (Object.keys(mainContent[sectionKey].subsections).length <= 1) {
            closeMobileNav();
            mobileButtonRef.current.focus();
        };
        hideHeaderOnScroll();
    }

    const toggleShowMobileNav = () => {
        setShowMobileNav((showMobileNav) => !showMobileNav);
    }

    const handleMobileToggleButtonClick = () => {
        toggleShowMobileNav();
    }

    const handleSubsectionNavClicked = () => {
        hideHeaderOnScroll();
        setShowMobileNav(false);
    }

    const sideNavPanelClasses = [classes.SideNavPanel];
    if (showMobileNav && isMobileWidth) {
        sideNavPanelClasses.push(classes.Open);
    }
    else {
        sideNavPanelClasses.push(classes.Closed);
    }

    return (
        <>
            <div className={sideNavPanelClasses.join(' ')}>
                <div className={classes.SideNavWrapper} ref={sideNavWrapperRef}>
                    <span className={classes.SideNavHeading}>{`Explore ${viewName}`}</span>
                    <ul className={classes.SideNavItems} ref={sideNavItemsRef}>
                        {Object.keys(mainContent).map(
                            (sectionKey) => {
                                const section = mainContent[sectionKey];
                                return (
                                    section.hash &&
                                    <li className={classes.SideNavWrapperItem} key={sectionKey}>
                                        <CollapsibleSubmenu
                                            sectionNavClicked={() => handleSectionNavClicked(sectionKey)}
                                            subsectionNavClicked={() => {
                                                // subsectionNavClicked();
                                                handleSubsectionNavClicked();
                                                mobileButtonRef.current.focus();
                                            }}
                                            screenReaderVisible={!isMobileWidth || (isMobileWidth && showMobileNav)}
                                            data={section}
                                            showSubmenu={activeSectionKey === sectionKey}
                                        />
                                    </li>
                                )
                            }
                        )}
                    </ul>
                </div>
                <button
                    ref={mobileButtonRef}
                    className={classes.MobileToggle}
                    onClick={handleMobileToggleButtonClick}
                    aria-label={isMobileWidth && showMobileNav ? 'Close button.' : 'Explore button. Press the Enter key to get quick links to explore this page.'}
                    tabIndex="0"
                >
                    <span className={classes.MobileToggleSpan}>
                        {(showMobileNav && isMobileWidth)
                            ? 'Close'
                            : 'Explore'
                        }
                    </span>
                </button>
            </div>
            <Backdrop
                show={showMobileNav && isMobileWidth}
                clicked={() => {mobileButtonRef.current.focus()}}
            />
        </>
    );
}

export default SideNavPanel;
