import React from 'react';

import classes from './Closing.module.css';

const Closing = ( props ) => {
    const spokenNumberOfBathrooms = (props.baths % 1 === 0.5) ? `${Math.trunc(props.baths)} and a half` : `${Math.trunc(props.baths)}`;
    const spokenBedsAndBaths = `${props.beds} beds and ${spokenNumberOfBathrooms} baths`;
    const spokenSquareFootage = `${props.sqFootage} approximate square feet`;
    const spokenClosingDetails = `${props.dwellingType} in ${props.area} with ${spokenBedsAndBaths}, and ${spokenSquareFootage} closed at ${props.salePrice} dollars`;

    return (
        <div
            className={classes.Closing}
            tabIndex='0'
            aria-label={spokenClosingDetails}
        >
            <div className={classes.ImageContainer}>
                <img
                    src={props.image}
                    alt={spokenClosingDetails}
                />
            </div>
            <div className={classes.Details}>
                <div>
                    <b>Closing Price:</b>
                    <span aria-label={`${props.salePrice} dollars`}>${props.salePrice}</span>
                </div>
                <div>
                    <b aria-label={'Number of beds and number of baths'}>Beds/Baths:</b>
                    <span aria-label={spokenBedsAndBaths}>{`${props.beds}/${props.baths}`}</span>
                </div>
                <div>
                    <b aria-label={'Approximate square feet'}>Approx. Square Feet:</b>
                    <span aria-label={spokenSquareFootage}>{props.sqFootage}</span>
                </div>
                <div>
                    <b>Area:</b>
                    <span>{props.area}</span>
                </div>
            </div>
        </div>
    );
}

export default Closing;
