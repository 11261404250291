import React from 'react';

import warningIcon from '../../../assets/images/statuses/warning-icon.png';
import classes from './Input.module.css';

const input = ({
    id,
    name,
    label,
    elementType,
    elementConfig,
    inputRef,
    changed,
    validationRules,
    errMessage,
    onKeyUp,
    clicked,
    blurred,
    screenReaderText
}) => {
    let inputElement = null;
    const inputClasses = [classes.Input];
    const inputElementClasses = [classes.InputElement];

    if (errMessage) {
        inputElementClasses.push(classes.Invalid);
    }

    let isRequiredInput = null;
    if (validationRules) {
        const requiresValue = (element) => element.required === true;
        isRequiredInput = validationRules.some(requiresValue);
    }

    switch( elementType ) {
        case ( 'text' ):
            inputElement = <input
                id={id}
                name={name}
                className={inputElementClasses.join(' ')}
                {...elementConfig}
                value={elementConfig.value}
                ref={inputRef}
                onChange={changed}
                onFocus={(event) => event.target.removeAttribute('placeholder')}
                onBlur={(event) => {event.target.setAttribute('placeholder', elementConfig.placeholder); blurred(event);}}
                onKeyUp={onKeyUp}
                onClick={clicked}
                aria-label={screenReaderText}
                aria-required={isRequiredInput}
                />;
            break;
        case ( 'textarea' ):
            inputElement = <textarea
                id={id}
                name={name}
                className={inputElementClasses.join(' ')}
                {...elementConfig}
                value={elementConfig.value}
                rows="5"
                onChange={changed}
                onFocus={(event) => event.target.removeAttribute('placeholder')}
                onBlur={(event) => {event.target.setAttribute('placeholder', elementConfig.placeholder); blurred(event);}}
                aria-label={screenReaderText}
                aria-required={isRequiredInput}
                />;
            break;
        default:
            inputElement = null;
    }

    return (
        <div className={inputClasses.join(' ')}>
            <div className={classes.LabelSpan}>
                <label htmlFor={id}>
                    {label}
                    {isRequiredInput
                        ?   <span className={classes.RequiredField} aria-hidden='true'>*</span>
                        :   null
                    }
                </label>
                {errMessage
                    ?   <div className={classes.ErrorMessageContainer}>
                            <img
                                className={classes.WarningIcon}
                                src={warningIcon}
                                alt='warning icon'
                            />
                            <span className={classes.ErrorMessage}>
                                {errMessage}
                            </span>
                        </div>
                    :   null
                }
            </div>
            {inputElement}
        </div>
    );
};

export default input;
