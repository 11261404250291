import React, { useContext } from 'react';

import ImageSlider from '../../components/ImageSlider/ImageSlider';
import TestimonialsCarousel from '../../components/TestimonialsCarousel/TestimonialsCarousel';
import ImageGallery from '../../components/UI/ImageGallery/ImageGallery';
import StyledLink from '../../components/UI/StyledLink/StyledLink';
import ContactFormContext from '../../context/ContactFormContext';
import TextButton from '../../components/UI/TextButton/TextButton';

import { data } from '../../data/Home';
import { testimonialData } from '../../data/Testimonials';
import { closings } from '../../data/Closings';

import classes from './Home.module.css';

const Home = ( props ) => {
    const { scrollToContactForm } = useContext(ContactFormContext);

    const textButtonStyle = {
        fontSize: 'inherit',
        fontWeight: 600,
        fontStyle: 'italic'
    };

    return (
        <>
            <div className={classes.SoldSlider}>
                <ImageSlider closings={closings.sliderClosings} />
            </div>
            <div className={classes.Home}>
                <h1>{data.mainHeading}</h1>
                <div className={classes.MiddleContent}>
                    <div className={classes.RealtyReqs}>
                        <span>Serving the Greater Phoenix metro area</span>
                        <ImageGallery images={data.requiredLogoGroup} />
                        <div className={classes.ContactUs}>
                            <span>Looking for your dream home?</span>
                            <span>Need to sell your home?</span>
                            <span>Need a reliable handyman, pool service, or other contractor?</span>
                            <span>Call our team at&nbsp;
                                <a href="tel:6023730115">
                                    (602) 373-0115
                                </a>
                                &nbsp;or&nbsp;
                                <TextButton
                                    buttonStyles={ textButtonStyle }
                                    clicked={scrollToContactForm}
                                >
                                    Send Us a Note
                                </TextButton>
                            </span>
                            <section className={classes.MyHomeIQContainer}>
                                <h2>Thinking of Buying a Home?</h2>
                                <p>See how much home you can afford</p>
                                <iframe
                                    src="https://myhomeiq.com/wiget-buyer/?user=ssmith0&partner=carynkommers&hex=2681ff&text=Get%20Started"
                                    title={`Homebuyer's tool to check loan qualification`}
                                    name="myhomeiq"
                                    height="auto"
                                    width="100%"
                                    allowFullScreen
                                ></iframe>
                            </section>
                            <section className={classes.MyHomeIQContainer}>
                                <h2>How much is your home worth?</h2>
                                <p>Get your free Home Equity Report</p>
                                <iframe
                                    src="https://myhomeiq.com/wiget/?user=carynkommers&hex=2681ff"
                                    title={`Homeowner's tool to obtain a home equity report`}
                                    name="myhomeiq"
                                    height="auto"
                                    width="100%"
                                    allowFullScreen
                                >
                                </iframe>
                            </section>
                        </div>
                    </div>
                    <div className={classes.About}>
                        <div className={classes.Company}>
                            {data.intro.paragraphs.map( (para) => <p key={para.id}>{para.text}</p>)}
                            <div className={classes.LinkContainer}>
                                <StyledLink
                                    path='/about'
                                    hash='why-choose-bold'
                                    screenReaderText='Why Bold?  Click to learn more about why you should choose Bold Realty for your home purchase or sale.'
                                >
                                    Why Bold?
                                </StyledLink>
                                <StyledLink
                                    path='/about'
                                    hash='our-team'
                                    screenReaderText='Meet Our Team.  Click to get to know our team members and their expertise.'
                                >
                                    Meet Our Team
                                </StyledLink>
                            </div>
                        </div>
                        <div className={classes.Team}>
                            {data.realtors.map( (realtor) =>
                                 (
                                    <div className={classes.Realtor} key={realtor.id}>
                                        <div className={classes.PhotoContainer}>
                                            <img src={realtor.photo} alt={realtor.alt} />
                                        </div>
                                        <span>{realtor.name}</span>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <TestimonialsCarousel carouselHeader={data.testimonialCarousel.leadText} testimonialSlider={testimonialData} />
        </>
    );
}

export default Home;
