import heroImage from '../assets/images/other/home-for-sale.jpg';
import eyeIcon from '../assets/images/icons/icon-eye-FFFFFF.png';
import eyeIconHover from '../assets/images/icons/icon-eye-B09032.png';
import eyeIconActive from '../assets/images/icons/icon-eye-D0B052.png';
import eyeIconClicked from '../assets/images/icons/icon-eye-E0C062.png';
import resourcesIcon from '../assets/images/icons/icon-tools-FFFFFF.png';
import resourcesIconHover from '../assets/images/icons/icon-tools-B09032.png';
import resourcesIconActive from '../assets/images/icons/icon-tools-D0B052.png';
import resourcesIconClicked from '../assets/images/icons/icon-tools-E0C062.png';
import faqsIcon from '../assets/images/icons/icon-help-FFFFFF.png';
import faqsIconHover from '../assets/images/icons/icon-help-B09032.png';
import faqsIconActive from '../assets/images/icons/icon-help-D0B052.png';
import faqsIconClicked from '../assets/images/icons/icon-help-E0C062.png';
import agentQuestionsImage from '../assets/images/other/Questions-to-ask-every-real-estate-agent-before-hiring-them.png';
import questionsToAskEveryRealEstateAgentPdf from '../assets/documents/Questions-To-Ask-Every-Real-Estate-Agent-Before-Hiring-Them.pdf';

export const data = {
    viewTitle: 'Seller Resources',
    view: 'Sellers',
    hero: {
        image: heroImage,
        imageAlt: 'Home for sale sign in front of home',
        spokenHeadline: 'Bold Sales, Best Value',
        headline: 'Bold Sales....Best Value',
        headlinePos: 'bottomRight',
        headlineColor: '#D0B052',
        headlineShadowColor: '#04386D'
    },
    leadText: {
        name: 'Considering Selling Your Home?',
        content: {
            cont001: {
                meta: 'paragraph',
                content: [
                    'It you are currently thinking about selling a home, you have probably been deluged with the opinions of family, friends and the news about the housing market and may be concerned about whether now—is the right time to sell.',
                    'With mortgage rates at historic lows buyers are plentiful—so—whether you are looking to upsize, downsize or get out from under an upside down property it would be our pleasure to offer you a free consultation to help you determine if now is the right time—for you.'
                ]
            }
        }
    },
    sections: {
        sect001: {
            icon: eyeIcon,
            hoverIcon: eyeIconHover,
            activeIcon: eyeIconActive,
            clickIcon: eyeIconClicked,
            alt: 'Overview',
            displayName: 'Overview',
            readableName: 'Overview',
            hash: 'overview',
            subsections: {
                subsect001: {
                    displayName: 'Marketing Strategy for Your Home',
                    readableName: 'Marketing Strategy for Your Home',
                    hash: 'marketing-strategy',
                    content: {
                        cont001: {
                            meta: 'heading',
                            content: 'Hire a Qualified Professional'
                        },
                        cont002: {
                            meta: 'paragraph',
                            content: [
                                'Hiring the right professional to represent you in one of the largest financial transactions you will make in your lifetime is important. As a seller, one of the most critical areas of competence you will need to evaluate is how an agent intends to market your home and what service will be provided during the listing.'
                            ]
                        },
                        cont003: {
                            meta: 'heading',
                            content: 'Marketing Strategy'
                        },
                        cont004: {
                            meta: 'paragraph',
                            content: [
                                'At Bold Realty we have developed an eight point marketing strategy that we know will best market your home and provide you with the superior service you deserve.'
                            ]
                        },
                        cont005: {
                            meta: 'orderedListBoldItemParagraph',
                            content: {
                                cont001: {
                                    listItem: 'Timely and Consistent Communication',
                                    paragraphs: [
                                        'Keep you informed and up-to-date on market conditions in your area. You will be connected to our unique MLS Seller Portal to ensure you receive immediate email notifications of new properties that are introduced to the market in your neighborhood as well as all price changes on existing listings.'
                                    ],
                                    unorderedListItems: [
                                        {
                                            heading: 'Consistent Communication',
                                            paragraph:
                                                'Provide you with weekly updates detailing our marketing efforts, including comments from the prospective buyers and agents who have visited your home when feedback is available.'

                                        }
                                    ]
                                },
                                cont002: {
                                    listItem: 'Preparing Your Home for Sale',
                                    paragraphs: [
                                        'Suggest effective changes to your home to make it more appealing to interested buyers.'
                                    ],
                                    unorderedListItems: [
                                        {
                                            heading: 'Home Staging',
                                            paragraph:
                                                'Present you with professional advice to promote an exceptional presentation of you home to encourage a quick sale at the highest possible value. A correctly staged home will cast a positive light on the features most important to buyers.'

                                        },
                                        {
                                            heading: 'Price your home strategically',
                                            paragraph:
                                                'A correctly priced house that is competitive with the market will typically sell quicker and for a higher value than a house that is initially overpriced.'

                                        }
                                    ]
                                },
                                cont003: {
                                    listItem: 'Promote Your Home to Real Estate Professionals',
                                    paragraphs: [
                                        ' Submit your home to all active agents in the Arizona Regional MLS (ARMLS) which then spreads your listing over 250+ other websites such as Zillow, Homes.com, Redfin, Realtor.com, etc.'
                                    ]
                                },
                                cont004: {
                                    listItem: 'Promoting Your Home on the Internet',
                                    paragraphs: [
                                        'Optimize your home’s internet presence by posting information in the Arizona Regional Multiple Listing Service (ARMLS) which automatically syndicates to more than 35 of the major search engines including Google, AOL, Yahoo and Trulia.  Prepare a unique photo tour for your home so that buyers can experience the uniqueness of your home 24 hours a day.'
                                    ]
                                },
                                cont005: {
                                    listItem: 'Promoting Your Home in your Neighborhood',
                                    paragraphs: [
                                        'Send a personalized postcard to residents in your neighborhood promoting the features and lifestyle benefits of your home and encouraging them to tell family and friends who may be interested or know someone who is looking for a home in your neighborhood.'
                                    ]
                                },
                                cont006: {
                                    listItem: 'Advertising your Home with a QR code',
                                    paragraphs: [
                                        'QR is short for Quick Response. A QR Code is a unique bar code, consisting of black and white squares that can be read/scanned by cell phones. A QR Code is powerful because it can deliver instant information about your home. Buyers will be able to instantly access listing information on a smartphone with the simple click of a button.'
                                    ],
                                    unorderedListItems: [
                                        {
                                            heading: 'Custom Flyer',
                                            paragraph:
                                                'Create a custom flyer of features and lifestyle benefits of your home for use by cooperating agents and potential buyers who have toured your home. A colorful and professional brochure helps to keep your property in the forefront of their mind.'

                                        },
                                        {
                                            heading: 'Open House',
                                            paragraph:
                                                'As technology advances, and more and more fast and effective marketing becomes available, open houses are becoming less and less popular with real estate agents because of the significant time commitment they require. However, despite their decreasing popularity, they remain a tremendously effective marketing tool. Bold Realty continues to embrace a wide diversity of marketing approaches and will create for you a custom open house schedule to fully showcase your home to the buying public.'

                                        },
                                        {
                                            heading: 'Target active buyers and investors in our database',
                                            paragraph:
                                                'Bold Realty maintains a very active and robust database of potential buyers and past clients who may be looking for homes in your price range and area. At the time of listing we will notify everyone in our database that your home is new on the market.'

                                        }
                                    ]
                                },
                                cont007: {
                                    listItem: 'Custom Listing Book',
                                    paragraphs: [
                                        'Create a custom “listing book” to be placed in your home for buyers to reference home features, lot, utility, and tax information, neighborhood benefits, schools, shopping, medical, and other buyer benefits.'
                                    ],
                                    unorderedListItems: [
                                        {
                                            heading: 'Easy Access for Showings',
                                            paragraph:
                                                'Enhance convenience of buyer viewing by placing home on a lockbox.'

                                        },
                                        {
                                            heading: 'Professional Signs',
                                            paragraph:
                                                'Maximize showing exposure through professional signage.'

                                        }
                                    ]
                                }
                            }
                        }
                    }
                },
                subsect003: {
                    displayName: 'Understanding Pricing',
                    readableName: 'Understanding Pricing',
                    hash: 'understanding-pricing',
                    content: {
                        cont001: {
                            meta: 'paragraph',
                            content: ['When it comes time to sell a home, most people want the property to sell quickly for the highest possible return. Setting the correct listing price is the first and most important step in reaching this goal. Price a property too low and it might sell quickly, but you could pocket less profit. Set it too high and you run the risk of pricing yourself out of the market.']
                        },
                        cont002: {
                            meta: 'heading',
                            content: 'How to set the right price for a home'
                        },
                        cont003: {
                            meta: 'paragraph',
                            content: [
                                'It’s best to have a real estate professional work with you to set a reasonable price. According to a study done by the National Association of REALTORS®, homes that were sold using a real estate agent netted an average of $25,000 more than those without agent representation.',
                                'There are a number of factors that your agent will consider when determining a sales price for your home. Here’s a quick overview:'
                            ]
                        },
                        cont004: {
                            meta: 'listItemParagraph',
                            listItem: 'Comparable Sales',
                            content: [
                                'One of the best guides to pricing your home is knowing what recent buyers were willing to pay for similar homes in your area. So, one of the first things your agent will do is prepare a Comparative Market Analysis (CMA). A CMA is a written analysis of houses in the community that are currently for sale, homes that have recently sold, and homes that were offered for sale but did not sell. While no two homes are identical, the report highlights those homes that most resemble yours. The CMA is an important tool in helping to determine a price range that will be appropriate for your home.'
                            ]
                        },
                        cont005: {
                            meta: 'listItemParagraph',
                            listItem: 'Unique Property Features',
                            content: [
                                'Since no two homes are exactly alike, looking at comparable sales is just one part of the equation. Many properties have distinctive features that add to their overall value when it comes to pricing.'
                            ]
                        },
                        cont006: {
                            meta: 'listItemParagraph',
                            listItem: 'Current market Conditions',
                            content: [
                                'The real estate market is constantly fluctuating, and those cycles have a direct impact on pricing. Here are some of the market conditions Bold Realty will consider when evaluating how to price your home:'
                            ]
                        },
                        cont007: {
                            meta: 'orderedList',
                            content: [
                                'Are home prices trending up or down?',
                                'How quickly are homes selling?',
                                'Is the inventory of homes on the market tight or plentiful?',
                                'Are interest rates attractive?',
                                'Condition of the home and surrounding area compared to the competition'
                            ]
                        },
                        cont008: {
                            meta: 'heading',
                            content: [
                                'Why overpricing a home is risky'
                            ]
                        },
                        cont009: {
                            meta: 'paragraph',
                            content: [
                                'Some sellers want to list their home at an inflated value, believing that they can always lower the price down the road if needed. But this can be a risky strategy. New listings generally get the greatest exposure in the first two-to-four weeks on the market, so setting a realistic price from day one is critical. If a home is priced too high, your strongest pool of prospective buyers is eliminated because they think it’s out of their price range. Conversely, buyers who can afford it will compare it to other homes that have been fairly priced and decide that they can get more home for their money elsewhere.',
                                'Once it has been decided to reduce the price, you’ve unnecessarily lost time and money. Your strongest prospective buyers may have found another home, while the over-inflated price could result in a negative impression amongst agents and buyers who are still in the market. Not to mention, reengaging buyers after those first critical few weeks can be very challenging. As the saying goes, “time is money”; so the longer a home is on the market, the lower the selling price will likely be in relation to the initial listing price.'
                            ]
                        },
                        cont010: {
                            meta: 'heading',
                            content: [
                                'Setting a home price too high has other costs'
                            ]
                        },
                        cont011: {
                            meta: 'paragraph',
                            content: [
                                'When a home languishes on the market, the seller loses in a number of ways. Each month the home goes unsold is another month of costs to the owner in mortgage payments, taxes, and maintenance—expenses that are not recovered when the home is sold. Furthermore, until the house is sold, the owner is on hold and can’t move forward with whatever plans prompted the decision to sell. If the seller is still living in the home, it can also be fatiguing to keep the property in ready-to-show condition month after month.'
                            ]
                        },
                    }
                }
            }
        },
        sect002: {
            icon: resourcesIcon,
            hoverIcon: resourcesIconHover,
            activeIcon: resourcesIconActive,
            clickIcon: resourcesIconClicked,
            alt: 'Resources',
            displayName: 'Resources',
            readableName: 'Resources',
            hash: 'resources',
            subsections: {
                subsect001: {
                    displayName: 'Choosing the Right Realtor',
                    readableName: 'Choosing the Right Realtor',
                    hash: 'choosing-the-right-realtor',
                    content: {
                        cont001: {
                            meta: 'paragraph',
                            content: ['If you are considering working with real estate agents, you should carefully vet them. It has been our experience that too few people take the time to interview agents. Instead, they often work with the first person they encounter or a family member or friend, never knowing how skilled and experienced that person is.']
                        },
                        cont002: {
                            meta: 'quotedReference',
                            content: ['You wouldn’t invest your money with a part-time or inexperienced financial planner-so why would you trust your largest asset to an inexperienced or unskilled real estate agent?']
                        },
                        cont003: {
                            meta: 'paragraph',
                            content: ['The questions in the document below will help you identify the right person for you. Of course, if you are buying or selling real estate in the near future, we at Bold Realty would love an opportunity to earn your business!']
                        },
                        cont004: {
                            meta: 'files',
                            content: {
                                cont001: {
                                    meta: 'file',
                                    file: {
                                        name: 'Questions to Ask Every Real Estate Agent Before Hiring Them',
                                        image: agentQuestionsImage,
                                        pdfFile: questionsToAskEveryRealEstateAgentPdf,
                                        htmlTo: 'questions-to-ask-every-real-estate-agent-before-hiring-them',
                                        screenReaderPdfAnnouncement: 'Press the Enter key to open, in a new tab, a PDF version of Questions To Ask Every Real Estate Agent Before Hiring Them',
                                        screenReaderHtmlAnnouncement: 'Press the Enter key to open, in a new tab, a Web Page version of Questions To Ask Every Real Estate Agent Before Hiring Them'
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        sect003: {
            icon: faqsIcon,
            hoverIcon: faqsIconHover,
            activeIcon: faqsIconActive,
            clickIcon: faqsIconClicked,
            alt: 'FAQS',
            displayName: 'FAQs',
            readableName: 'Frequently Asked Questions',
            hash: 'faqs',
            subsections: {
                subsect001: {
                    content: {
                        cont001: {
                            meta: 'paragraph',
                            content: ['Have questions about selling your home?  Below, we have categorized many frequently asked questions.  Contact us for a more thorough understanding of the home selling process.']
                        },
                        cont002: {
                            meta: 'accordion',
                            config: {
                                id: 'getting-started',
                                buttonText: 'Getting Started',
                                nested: [
                                    {
                                        config: {
                                            id: 'can-the-seller-receive-any-proceeds-from-the-sale',
                                            buttonText: 'Can the seller receive any proceeds from the sale?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['No. Generally the seller’s creditor will not allow the seller to receive any benefit from the sale.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'the-seller-is-current-on-all-home-loan-payments-is-the-seller-eligible-for-a-short-sale',
                                            buttonText: 'The seller is current on all home loan payments.  Is the seller eligible for a short sale?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Possibly. Each lender establishes their individual investor guidelines.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'will-the-seller-be-required-to-pay-any-money-at-closing',
                                            buttonText: 'Will the seller be required to pay any money at closing?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Perhaps. It is possible that the lender will require the seller to pay fees or costs above those the lender is willing to absorb.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'how-long-does-the-lender-have-to-approve-the-short-sale-contract',
                                            buttonText: 'How long does the lender have to approve the short sale contract?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['There is no limit to how long the seller’s creditors may take and there is no way to know in advance how long the approval process will be. Each lender has their own process with some taking as little as two weeks and others twelve or more weeks.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'is-the-sellers-creditor-required-to-approve-the-short-sale',
                                            buttonText: 'Is the seller\'s creditor required to approve the short sale?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['No. The seller’s creditor has total discretion as to whether or not they approve the short sale.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'when-the-lender-is-reviewing-a-short-sale-contract-for-approval-is-the-trustee-sale-automatically-postponed',
                                            buttonText: 'When the lender is reviewing a short sale contract for approval, is the trustee sale automatically postponed?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['No. The Trustee Sale can be held on schedule even if there is a contract under consideration.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'will-the-trustee-sale-be-postponed-once-the-short-sale-contract-is-approved',
                                            buttonText: 'Will the trustee sale be postponed once the short sale contract is approved?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Not automatically. The negotiation of the short sale and the trustee sale are handled by different divisions of the bank so a request to postpone the Trustee Sale must be made to the appropriate department within the bank. It is possible, for a variety of different reasons, that the Trustee Sale could still take place despite the approval of the short sale.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'is-the-buyer-still-entitled-to-purchase-the-property-under-the-terms-of-the-short-sale-contract-after-the-trustee-sale-is-held',
                                            buttonText: 'Is the buyer still entitled to purchase the property under the terms of the short sale contract after the trustee sale is held?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['No. The lender is not bound to the short sale contract if the property is conveyed at Trustee Sale.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'the-seller-has-a-1st-and-a-2nd-loan-on-the-property-What-does-that-mean-in-a-short-sale',
                                            buttonText: 'The seller has a 1st and a 2nd loan on the property.  What does that mean in a short sale?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['Both of the seller’s loans will need to be released, therefore both loans will have to be negotiated and both lenders will need to approve the short sale.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'i-have-heard-that-the-IRS-will-forgive-any-tax-obligation-resulting-from-any-forgiven-debt-from-the-short-sale',
                                            buttonText: 'I have heard that the IRS will forgive any tax obligation resulting from any forgiven debt from the short sale.',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['This may or may not be the case and depends on a number of factors. Never make any assumptions regarding taxes; always seek professional advice. Your real estate agents are specifically prohibited from offering any tax or legal advice.']
                                                }
                                            }
                                        }
                                    },
                                    {
                                        config: {
                                            id: 'is-it-true-that-after-the-sale-closes-the-seller-will-not-owe-anything-to-the-lenders',
                                            buttonText: 'Is it true that after the sale closes, the seller will not owe anything to the lender(s)?',
                                            panelContent: {
                                                cont001: {
                                                    meta: 'paragraph',
                                                    content: ['This will be determined by the seller’s creditors, and the seller should always satisfy themselves that they are comfortable with the lender’s short sale terms. The seller is always encouraged to seek legal advice regarding potential liability for any short sale deficiency.']
                                                }
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            }
        }
    }
}
