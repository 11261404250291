import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Testimonial from '../Testimonial/Testimonial';
import StyledLink from '../../components/UI/StyledLink/StyledLink';

import classes from './TestimonialsCarousel.module.css';

function TestimonialsCarousel (props) {
    const history = useHistory();
    const [intervalRunning, setIntervalRunning] = useState(true);
    const [index, setIndex] = useState(0);
    const [switchSlide, setSwitchSlide] = useState(false);
    const [readSelectedTestimonial, setReadSelectedTestimonial] = useState(false);
    const sliderData = props.testimonialSlider;

    const maxNumSlides = 4;
    const chosenTestimonials = sliderData.slice(0, maxNumSlides);
    const currentTestimonial = chosenTestimonials[index];

    const goToTestimonial = (id) => {
        history.push({
            pathname: `/results/testimonials`,
            state: { resourceId: id }
        });
    }

    useEffect(() => {
        let switchContentTimer = null;

        const increaseIndex = () => {
            setSwitchSlide((switchSlide) => !switchSlide);

            setTimeout(() => {
                setSwitchSlide((switchSlide) => !switchSlide);
            }, 2000);

            switchContentTimer = setTimeout(() => {
                setIndex(index => (index === chosenTestimonials.length - 1) ? 0 : index + 1);
            }, 1000);
        }

        let intervalId = null;
        if (intervalRunning) {
            intervalId = setInterval(() => {increaseIndex()}, 8000);
        }
        else {
            clearInterval(intervalId);
            intervalId = null;
            clearTimeout(switchContentTimer);
            switchContentTimer = null;
        }
        return () => {
            clearInterval(intervalId);
            intervalId = null;
            clearTimeout(switchContentTimer);
            switchContentTimer = null;
        }
    }, [index, intervalRunning, chosenTestimonials.length]);

    const toggleTimer = () => {
        setIntervalRunning((interval) => !interval);
    }

    const findTestimonial = (id) => {
        let testimonialFound = chosenTestimonials.find(nextTestimonial => {
            return nextTestimonial.id === id;
        });
        const indexFound = chosenTestimonials.findIndex((whichTestimonial) => whichTestimonial.id === testimonialFound.id)
        setIndex(indexFound);
    }

    let testimonialWrapperClasses = [];
    if (switchSlide) {
        testimonialWrapperClasses = [classes.TestimonialWrapper, classes.FadeText];
    }
    else {
        testimonialWrapperClasses = [classes.TestimonialWrapper];
    }

    const spokenTestimonial = `a testimonial, by ${currentTestimonial.sourceName}
        ${(currentTestimonial.sourceLocation !== null) ? `, in ${currentTestimonial.sourceLocation}` : ''},
        on ${currentTestimonial.headline}, saying ${currentTestimonial.review}`;

    return (
        <div className={classes.TestimonialsCarouselWrapper}>
            <div className={classes.TestimonialsCarousel}
                onMouseEnter={() => toggleTimer()}
                onMouseLeave={() => toggleTimer()}>
                <b className={classes.TestimonialsCarouselLeadText}>{props.carouselHeader}</b>
                <div className={testimonialWrapperClasses.join(' ')}>
                    <Testimonial
                        testimonialDetails={currentTestimonial}
                        onMobileViewport={true}
                        clicked={() => goToTestimonial(currentTestimonial.id)}
                        focused={() => {setIntervalRunning(false);}}
                        blurred={() => {setIntervalRunning(true);}}
                    />
                </div>
                <ul className={classes.TestimonialIndicators}>
                    {chosenTestimonials.map((nextTestimonial) =>
                        <li key={nextTestimonial.id.toString()}>
                            <button
                                className={[(nextTestimonial.id === currentTestimonial.id
                                    ? classes.ActiveSlideIndicator
                                    : classes.InactiveSlideIndicator)].join(' ')}
                                onClick={() => {findTestimonial(nextTestimonial.id); setReadSelectedTestimonial(true);}}
                                onFocus={() => {setIntervalRunning(false);}}
                                onBlur={() => {setIntervalRunning(true); setReadSelectedTestimonial(false);}}
                                aria-label={
                                    (readSelectedTestimonial)
                                        ? spokenTestimonial
                                        : `button for testimonial number ${nextTestimonial.id} of ${chosenTestimonials.length} testimonials`
                                }
                            >
                            </button>
                        </li>
                    )}
                </ul>
                <div className={classes.MoreTestimonialsLinkContainer}>
                    <StyledLink
                        path='/results/testimonials'
                        routeState={{ scrollTo: 'true', screenReaderAnnouncement: 'Showing Testimonials' }}
                        screenReaderText='More Testimonials.  Click to see more testimonials for the Bold Realty team.'
                    >
                        More Testimonials
                    </StyledLink>
                </div>
            </div>
        </div>
    )
}

export default TestimonialsCarousel;
