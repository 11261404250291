import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './StyledLink.module.css';

const StyledLink = ({
    path,
    routeState,
    hash,
    exact,
    screenReaderText,
    children
}) => {

    return (
        <div className={classes.StyledLink}>
            <NavLink
                to={{
                    pathname: path,
                    state: (routeState) ? routeState : null,
                    hash: hash
                }}
                exact={exact}
                activeClassName={classes.active}
                aria-label={screenReaderText}
            >
                {children}
            </NavLink>
        </div>
    );
}

export default StyledLink;
