import React, { useEffect, useState, useRef, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Hero from '../../components/Hero/Hero';
import Tabs from '../../components/Tabs/Tabs';
import Testimonial from '../../components/Testimonial/Testimonial';
import Closing from '../../components/Closing/Closing';
import TextButton from '../../components/UI/TextButton/TextButton';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import ScrollContext from '../../context/ScrollContext';
import ContactFormContext from '../../context/ContactFormContext';

import { data } from '../../data/Results';
import { testimonialData } from '../../data/Testimonials';
import { closings } from '../../data/Closings';

import classes from './Results.module.css';

const Results = () => {
    const windowDimensions = useWindowDimensions();
    const { scrollAndFocus } = useContext(ScrollContext);
    const { scrollToContactForm } = useContext(ContactFormContext);
    const [isMobileViewportWidth, setIsMobileViewportWidth] = useState();
    const tabsRef = useRef();
    const tabItemRef = useRef();
    const testimonialContentRef = useRef();
    let itemSelectedRef = useRef();

    const location = useLocation();
    const { activeTab } = useParams();

    const tabsRefs = {
        tabsRef: tabsRef,
        tabItemRef: tabItemRef,
        itemSelectedRef: itemSelectedRef
    };

    useEffect( () => {
        if (location.state && location.state.resourceId) {
            scrollAndFocus(itemSelectedRef, testimonialContentRef);
        }
        else if (activeTab && location.state && location.state.scrollTo) {
            scrollAndFocus(tabsRef, tabItemRef);
        }
    }, [location.state, activeTab]);

    useEffect( () => {
        setIsMobileViewportWidth(windowDimensions.width < 600);
    }, [windowDimensions.width]);

    const textButtonStyle = {
        fontSize: 'inherit',
        fontWeight: 600
    };

    const resourceId = (location.state && location.state.resourceId)
        ? location.state.resourceId
        : null;

    const testimonials =
        testimonialData.map( (testimonial) =>
            <Testimonial
                id={testimonial.id}
                ref={(testimonial.id === resourceId) ? testimonialContentRef : null}
                testimonialDetails={testimonial}
                expand={testimonial.id === resourceId}
                onMobileViewport={isMobileViewportWidth}
            />
        );

    const recentClosings =
        closings.allClosings.map( (closing) =>
            <Closing
                image={closing.image}
                imageAlt={closing.imageAlt}
                area={closing.area}
                beds={closing.numberOfBedrooms}
                baths={closing.numberOfBathrooms}
                sqFootage={closing.sqFootage}
                salePrice={closing.salePrice}
            />
        );

    const tabItems =
        [
            {id: 0, name: 'Testimonials', routeParam: 'testimonials', content: testimonials, itemsPerPage: 3},
            {id: 1, name: 'Recent Closings', routeParam: 'recent-closings', content: recentClosings, itemsPerPage: 4}
        ];

    return (
        <>
            <Hero {...data.hero} />
            <div className={classes.Results}>
                <p className={classes.LeadParagraph}>
                    {data.leadParagraph}
                </p>
                <div className={classes.Content}>
                    <div>
                        <Tabs
                            ref={tabsRefs}
                            itemsConfig={tabItems}
                            navigateToId={resourceId}
                            pageButtonSelected={(callback) => scrollAndFocus(tabsRef, tabItemRef, callback)}
                        />
                    </div>
                    <div>
                        <img
                            className={classes.Image}
                            src={data.contactUsBlock.contactUsImage.image}
                            alt={data.contactUsBlock.contactUsImage.imageAlt}
                        />
                        <div>
                            <span>{data.contactUsBlock.contactUsSpan1}</span>
                            <TextButton
                                buttonStyles={ textButtonStyle }
                                clicked={scrollToContactForm}
                                screenReaderText={data.contactUsBlock.screenReaderText}
                            >
                                {data.contactUsBlock.contactUsButtonText}
                            </TextButton>
                            <span>{data.contactUsBlock.contactUsSpan2}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Results;
