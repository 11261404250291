import React, { useState, useEffect, forwardRef } from 'react';

import StyledLink from '../UI/StyledLink/StyledLink';
import classes from './ImageSlider.module.css';

const ImageSlider = forwardRef(( {
    closings
}, closingsSliderRef ) => {
    const [index, setIndex] = useState(0);
    const [intervalRunning, setIntervalRunning] = useState(true);

    const increaseIndex = () => {
        setIndex((index) => index === closings.length - 1 ? 0 : index += 1);
    }

    const closingDisplayed = closings[index];
    const defaultOverlayTextColor = '#d59e59';
    const defaultOverlayTextShadowColor = '#04386D';

    const overlayTextShadowCss = (closingDisplayed.overlayTextShadowColor)
        ? `0px 1px 0 ${closingDisplayed.overlayTextShadowColor}, 0px 2px 0 #000000`
        : `0px 1px 0 ${defaultOverlayTextShadowColor}, 0px 2px 0 #000000`;

    let overlayTextColor = (closingDisplayed.overlayTextColor)
        ? closingDisplayed.overlayTextColor
        : defaultOverlayTextColor;

    useEffect(() => {
        let intervalId = null;
        if (intervalRunning) {
            intervalId = setInterval(() => {increaseIndex()}, 9000);
        }
        return () => {
            clearInterval(intervalId);
            intervalId = null;
        }
    });

    const toggleTimer = () => {
        setIntervalRunning((interval) => !interval);
    }

    return (
        <div
            className={classes.ImageSlider}
            ref={closingsSliderRef}
            tabIndex='0'
            aria-label='Recent Bold Realty closings'
            onMouseEnter={toggleTimer}
            onMouseLeave={toggleTimer}
        >
            {
                closings.map ((closing) => {
                    return (
                        <div
                            className={[classes.SlideContainer, (closing.id === index) ? classes.FadeIn : classes.FadeOut].join(' ')}
                            key={closing.id}
                        >
                            <img
                                src={closing.image}
                                alt={closing.imageAlt} />
                            <div
                                className={classes.Details}
                                style={ {
                                    color: overlayTextColor,
                                    textShadow: overlayTextShadowCss } }
                            >
                                <span>SOLD</span>
                                <span>${closing.salePrice}</span>
                                <span>{closing.area}</span>
                            </div>
                        </div>
                    )
                })
            }
            <div className={classes.MoreClosingsLinkContainer}>
                <StyledLink
                    path='/results/recent-closings'
                    routeState={{ scrollTo: true }}
                    screenReaderText='Recent Closings.  Click to see more closings by the Bold Realty team.'
                >
                    More Closings
                </StyledLink>
            </div>
        </div>
    );
})

export default ImageSlider;
