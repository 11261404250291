import React, { useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import Hero from '../../components/Hero/Hero';
import Team from './Team/Team';
import TestimonialsCarousel from '../../components/TestimonialsCarousel/TestimonialsCarousel';
import TextButton from '../../components/UI/TextButton/TextButton';

import ScrollContext from '../../context/ScrollContext';
import ContactFormContext from '../../context/ContactFormContext';

import { data } from '../../data/About';
import { testimonialData } from '../../data/Testimonials';

import classes from './About.module.css';

const About = () => {
    const { scrollAndFocus } = useContext(ScrollContext);
    const { scrollToContactForm } = useContext(ContactFormContext);
    let whyBoldRef = useRef();
    let teamRef = useRef();
    const location = useLocation();

    useEffect( () => {
        if (location.hash === '#our-team') {
            scrollAndFocus(teamRef, teamRef);
        }
        else if (location.hash === '#why-choose-bold') {
            scrollAndFocus(whyBoldRef, whyBoldRef);
        }
    }, [location]);

    const textButtonStyle = {
        fontSize: 'inherit',
        fontWeight: 600
    };

    return (
        <>
            <Hero {...data.hero} />
            <div className={classes.About}>
                <h1>
                    {data.mainHeading}
                </h1>
                <div className={classes.Content}>
                    <div
                        className={classes.Sections}
                        ref={whyBoldRef}
                    >
                        {Object.keys(data.sections).map( (sectKey) => {
                            const section = data.sections[sectKey];
                            return (
                                <section
                                    key={sectKey}
                                >
                                    <h2>{section.heading}</h2>
                                    {section.meta === 'paragraph' && Object.keys(section.paragraphs).map( (paraKey) => {
                                        const paragraph = section.paragraphs[paraKey];
                                        return <p key={paraKey}>{paragraph.content}</p>
                                    })}
                                    {section.meta === 'unorderedList' &&
                                    <ul>
                                        {Object.keys(section.unorderedList).map( (itemKey) => {
                                            const listItem = section.unorderedList[itemKey];
                                            return <li key={itemKey}>{listItem.content}</li>
                                        })}
                                    </ul>
                                    }
                                    {Object.keys(section.subsections).map( (subsectKey) => {
                                        const subsection = section.subsections[subsectKey];
                                        return (
                                            <div className={classes.Subsection} key={subsectKey} >
                                                <h3>{subsection.heading}</h3>
                                                {subsection.meta === 'paragraph' && Object.keys(subsection.paragraphs).map( (paraKey) => {
                                                    const paragraph = subsection.paragraphs[paraKey];
                                                    return <p key={paraKey}>{paragraph.content}</p>
                                                })}
                                                {subsection.meta === 'unorderedList' &&
                                                <ul>
                                                    {Object.keys(subsection.unorderedList).map( (itemKey) => {
                                                        const listItem = subsection.unorderedList[itemKey];
                                                        return <li key={itemKey}>{listItem.content}</li>
                                                    })}
                                                </ul>
                                                }
                                            </div>
                                        )
                                    })}
                                </section>
                            )
                        })}
                    </div>
                    <div>
                        <img className={classes.Image} src={data.contactUsBlock.contactUsImage.image} alt={data.contactUsBlock.contactUsImage.imageAlt} />
                        <div>
                            <span>{data.contactUsBlock.contactUsSpan1}</span>
                            <TextButton
                                buttonStyles={ textButtonStyle }
                                clicked={scrollToContactForm}
                                screenReaderText={data.contactUsBlock.screenReaderText}
                            >
                                {data.contactUsBlock.contactUsButtonText}
                            </TextButton>
                            <span>{data.contactUsBlock.contactUsSpan2}</span>
                        </div>
                    </div>
                </div>
                <Team ref={teamRef} />
            </div>
            <TestimonialsCarousel carouselHeader={data.testimonialCarousel.leadText} testimonialSlider={testimonialData} />
        </>
    );
}

export default About;
