import React, { forwardRef } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import TabItemLink from './TabItemLink/TabItemLink';
import Pagination from '../Pagination/Pagination';
import NotFound from '../../views/NotFound/NotFound';

import classes from './Tabs.module.css';

const Tabs = forwardRef(( props, tabsRefs ) => {

    return (
        <div
            className={classes.Tabs}
            ref={tabsRefs.tabsRef}
        >
            <ul className={classes.TabItemLinks}>
                {props.itemsConfig.map( (tabItem, tabItemIdx) => {
                    const tabItemName = tabItem.name;
                    const tabItemRouteParam = tabItem.routeParam;
                    return (
                        <li
                            className={classes.TabItemLinkItem}
                            key={tabItemIdx}
                        >
                            <TabItemLink
                                tabItemParam={tabItemRouteParam}
                            >
                                {tabItemName}
                            </TabItemLink>
                        </li>
                    );
                })}
            </ul>
            <div
                className={classes.TabItemContainer}
                ref={tabsRefs.tabItemRef}
            >
                <Switch>
                    {props.itemsConfig.map( (tabItem, tabItemIdx) => {
                        return (
                            <Route
                                exact
                                path={`/results/${tabItem.routeParam}`}
                                key={tabItemIdx}
                            >
                                <Pagination
                                    key={tabItemIdx}
                                    id={tabItem.id}
                                    ref={tabsRefs.itemSelectedRef}
                                    items={tabItem.content}
                                    itemCountPerPage={tabItem.itemsPerPage}
                                    pageButtonClicked={(callback) => props.pageButtonSelected(callback)}
                                    navigateToId={props.navigateToId}
                                />
                            </Route>
                        );
                    })}
                    <Redirect
                        exact
                        from='/results'
                        to={{ pathname: '/results/testimonials', state: { scrollTo: false } }}
                    />
                    <Route>
                        <NotFound />
                    </Route>
                </Switch>
            </div>
        </div>
    );
});

export default Tabs;
