import React, { useState, useRef } from 'react';

import ScrollToTop from '../../hooks/ScrollToTop/ScrollToTop';

import Header from '../Header/Header';
import HideableHeader from '../HideableHeader/HideableHeader';
import SkipLinks from '../UI/SkipLinks/SkipLinks';
import MainContent from '../MainContent/MainContent';
import Footer from '../Footer/Footer';

import ScrollContext from '../../context/ScrollContext';
import ContactFormContext from '../../context/ContactFormContext';

import useUpdateDocumentTitle from '../../hooks/useUpdateDocumentTitle';
import useDeclarativeScrollAndFocus from '../../hooks/useDeclarativeScrollAndFocus';

import classes from './Layout.module.css';

const Layout = ( props ) => {
    const [showSkipLinks, setShowSkipLinks] = useState(false);
    const skipLinksRef = useRef();
    const mainRef = useRef();
    const footerRef = useRef();
    const contactUsButtonRef = useRef();
    const contactFormRef = useRef();
    const firstInputRef = useRef();
    const { scrollTo, scrollAndFocus, focusOnTarget, hideHeader, setHideHeader, enableHideHeader, hideHeaderOnScroll} = useDeclarativeScrollAndFocus();
    useUpdateDocumentTitle();

    const contactFormRefs = {
        contactFormRef: contactFormRef,
        firstInputRef: firstInputRef,
        footerRef: footerRef
    };

    const MINIMUM_VERTICAL_SCROLL = 60;
    const TIMEOUT_DELAY = 30;

    const linkConfig = {
        0: { name: 'Skip to Main Content', screenReaderText: '', href: '#main', targetRef: mainRef},
        1: { name: 'Skip to Footer', screenReaderText: '', href: '#footer', targetRef: footerRef}
    }

    const scrollCallback = (callbackData) => {
        const { previousScrollTop, currentScrollTop } = callbackData;
        const isScrolledDown = previousScrollTop < currentScrollTop;
        const isMinimumScrolled = currentScrollTop > MINIMUM_VERTICAL_SCROLL;
        setTimeout(() => {
            setHideHeader((isScrolledDown && isMinimumScrolled));
        }, TIMEOUT_DELAY);
    };

    const scrollToContactForm = async(event) => {
        scrollAndFocus(contactFormRef, firstInputRef);
    }

    const scrollToContactFormResult = async(callback) => {
        scrollTo(contactFormRef, callback);
    }

    const handleClickSkipLink = (event, targetRef) => {
        event.target.blur();
        setTimeout(()=>{
            scrollAndFocus(targetRef, targetRef);
        }, 800);
    }

    const handleViewLoad = () => {
        focusOnTarget(skipLinksRef);
    }

    return (
        <ScrollContext.Provider value={{scrollTo, scrollAndFocus, focusOnTarget, hideHeaderOnScroll, setHideHeader}}>
            <ScrollToTop normalPageLoad={handleViewLoad} />
            <SkipLinks
                ref={skipLinksRef}
                config={linkConfig}
                showSkipLinks={showSkipLinks}
                handleShowSkipLinks={() => setShowSkipLinks(true)}
                handleHideSkipLinks={() => setShowSkipLinks(false)}
                skipLinkClicked={(event, focusRef) => handleClickSkipLink(event, focusRef)}
            />
            <HideableHeader hasScrollTracking={enableHideHeader} scrollCallback={scrollCallback}>
                <Header hide={hideHeader} showSkipLinks={showSkipLinks} />
            </HideableHeader>
            <button
                ref={contactUsButtonRef}
                aria-label="Contact Us"
                className={classes.ContactUsButton}
                onClick={scrollToContactForm}
            >
                Contact Us
            </button>
            <ContactFormContext.Provider value={{ scrollToContactForm, scrollToContactFormResult }}>
                <MainContent ref={mainRef} showSkipLinks={showSkipLinks}>
                    {props.children}
                </MainContent>
                <Footer ref={contactFormRefs} />
            </ContactFormContext.Provider>
        </ScrollContext.Provider>
    );
}

export default Layout;
