import React, { forwardRef } from 'react';

import { NavLink } from 'react-router-dom';

import geoIcon from '../../assets/images/icons/location-24.png';
import emailIcon from '../../assets/images/icons/email-white-24.png';
import phoneIcon from '../../assets/images/icons/phone-white-24.png';
import faxIcon from '../../assets/images/icons/fax-white-24.png';
import magnetWebDesignsLogo from '../../assets/images/logos/transparent-magnet-logo.png';

import ContactForm from '../../components/ContactForm/ContactForm';
import Logo from '../../components/Logo/Logo';
import ImageGallery from '../../components/UI/ImageGallery/ImageGallery';
import NavigationItems from './Navigation/NavigationItems/NavigationItems';

import { footerData } from '../../data/Footer';
import { teamData } from '../../data/Team';
import classes from './Footer.module.css';

const Footer = forwardRef(( {props}, contactFormRefs ) => {
    const currentYearCopyrightStr = `\u00A9 ${new Date().getFullYear()} Bold Realty. All rights reserved.`;

    return (
        <footer>
            <ContactForm ref={contactFormRefs} />
            <div className={classes.MainFooter} ref={contactFormRefs.footerRef}>
                <div className={classes.Company}>
                    <div>
                        <Logo size='large' />
                        <ImageGallery images={footerData.requiredLogoGroupImages} />
                    </div>
                    <div className={classes.CompanyContactInfo}>
                        <div className={classes.ContactInfo}>
                            <img
                                src={geoIcon}
                                alt="geo location"
                            />
                            <a
                                target= "_blank"
                                rel="noopener noreferrer"
                                href={footerData.companyContactInfo.physicalAddress.googleMapUrl}
                                aria-label={`our business address is ${footerData.companyContactInfo.physicalAddress.spokenAddress}`}
                                className={classes.PhysicalAddress}
                            >
                                    <span>{footerData.companyContactInfo.physicalAddress.streetAddress1}</span>
                                    <span>{footerData.companyContactInfo.physicalAddress.streetAddress2}</span>
                            </a>
                        </div>
                        <div className={classes.ContactInfo}>
                            <img
                                src={faxIcon}
                                alt="fax"
                            />
                            <a
                                href={"fax:" + footerData.companyContactInfo.faxNumber}
                                aria-label={`our fax number is ${footerData.companyContactInfo.spokenFax}`}
                            >
                                {footerData.companyContactInfo.faxStr}
                            </a>
                        </div>
                    </div>
                </div>
                <div className={classes.Realtors}>
                    {teamData.teamDetails.map( (realtor) =>
                        <div className={classes.Realtor} key={realtor.id} >
                            <span>{realtor.name}</span>
                            <div className={classes.ContactInfo}>
                                <img
                                    src={phoneIcon}
                                    alt="phone"
                                />
                                <a
                                    href={"tel:" + realtor.phoneNumber}
                                    aria-label={`call ${realtor.contactName} at ${realtor.spokenPhone}`}
                                >
                                    {realtor.phoneStr}
                                </a>
                            </div>
                            <div className={classes.ContactInfo}>
                                <img
                                    src={emailIcon}
                                    alt="email"
                                />
                                <a
                                    href={"mailto:" + realtor.email}
                                    aria-label={`email ${realtor.contactName} at ${realtor.spokenEmail}`}
                                >
                                    {realtor.email}
                                </a>
                            </div>
                        </div>
                    )}
                </div>
                <NavigationItems />
            </div>
            <div className={classes.SubFooter}>
                <div className={classes.OtherLinks}>
                    <NavLink
                        to="/terms-of-service"
                        activeClassName={classes.active}
                    >
                        Terms of Service
                    </NavLink>
                    <span>|</span>
                    <NavLink
                        to="/privacy-policy"
                        activeClassName={classes.active}
                    >
                        Privacy Policy
                    </NavLink>
                    <span>|</span>
                    <NavLink
                        to="/sitemap"
                        activeClassName={classes.active}
                    >
                        Site Map
                    </NavLink>
                </div>
                <div className={classes.Mentions}>
                    <span className={classes.OwnerMention}>{currentYearCopyrightStr}</span>
                    <span className={classes.WebDesignerMention}>
                        <i>Powered by</i>
                        <img src={magnetWebDesignsLogo} alt='Magnet Web Designs LLC logo' />
                        <a
                            href='http://www.magnetwebdesigns.com'
                            target= "_blank"
                            rel="noopener noreferrer"

                        >
                            Magnet Web Designs
                        </a>
                    </span>
                </div>
            </div>
        </footer>
    )
})

export default Footer;
