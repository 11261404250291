import React, { useEffect, useRef } from 'react';

import Backdrop from '../../Backdrop/Backdrop';
import SideDrawerNavItems from './SideDrawerNavItems/SideDrawerNavItems';
import classes from './SideDrawer.module.css';

const SideDrawer = ({
    exact,
    show,
    close
}) => {

    const sideDrawerRef = useRef();
    const closeButtonRef = useRef();

    useEffect(() => {
        const links = sideDrawerRef.current.getElementsByTagName('a');
        const firstLink = links[0];
        const lastLink = links[links.length - 1];

        const escCloseModal = (event) => {
            if ( !event.keyCode || event.keyCode === 27 ) {
                event.preventDefault();
                close();
            }
            const isTabKeyPressed = event.key === 'Tab' || event.keyCode === 9;

            if (!isTabKeyPressed) {
                return;
            }
            if (event.shiftKey) {
                if (document.activeElement === closeButtonRef.current) {
                    event.preventDefault();
                    lastLink.focus();
                }
            }
            else {
                if (document.activeElement === lastLink) {
                    event.preventDefault();
                    closeButtonRef.current.focus();
                }
                else if (!sideDrawerRef.current.contains(event.target)) {
                    event.preventDefault();
                    firstLink.focus();
                }
            }
        }

        if (show) {
            document.addEventListener('keydown', escCloseModal);
        }
        return () => {
            document.removeEventListener('keydown', escCloseModal);
        }
    }, [show, close]);

    let sideDrawerClasses;

    if (show) {
        sideDrawerClasses = [classes.SideDrawer, classes.Open];
    }
    else {
        sideDrawerClasses = [classes.SideDrawer, classes.Closed];
    }

    return (
        <>
            <div ref={sideDrawerRef} className={sideDrawerClasses.join(' ')}>
                <div className={classes.TopPanel}>
                    <button
                        ref={closeButtonRef}
                        className={classes.CloseButton}
                        onClick={close}
                        aria-label='close site navigation drawer'
                        tabIndex={show ? null : '-1'}
                    >
                        &#10005;
                    </button>
                </div>
                <nav className={classes.NavigationItems}>
                    <SideDrawerNavItems
                        exact={exact}
                        navItemClicked={close}
                        screenReaderVisible={show}
                    />
                </nav>
            </div>
            <Backdrop show={show} clicked={close} />
        </>
    )
}

export default SideDrawer;
