import React, { forwardRef } from 'react';

import classes from './DrawerToggle.module.css';

const drawerToggle = forwardRef(( props, drawerToggleRef ) => {

    return (
        <button
            ref={drawerToggleRef}
            className={[(props.toggledOpen ? classes.Active : null), classes.DrawerToggle].join(' ')}
            onClick={props.clicked}
            aria-label={'site navigation drawer toggle'}
        >
            <div></div>
            <div></div>
            <div></div>
        </button>
    );
})

export default drawerToggle;
