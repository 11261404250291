import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './SideDrawerNavItem.module.css';

const SideDrawerNavItem = ({
    link,
    exact,
    clicked,
    currentRoute,
    readerVisible,
    children
}) => {

    return (
        <li className={classes.NavigationItem} >
            <NavLink
                onClick={clicked}
                to={link}
                exact={exact}
                activeClassName={classes.active}
                aria-current={currentRoute ? 'page' : null}
                tabIndex={readerVisible ? null : '-1'}
            >
                {children}
            </NavLink>
        </li>
    );
}

export default SideDrawerNavItem;
