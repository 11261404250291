import React from 'react';

import classes from './ImageGallery.module.css';

function ImageGallery (props) {

    return (
        <div className={classes.ImageGallery}>
            {props.images.map((image) => {
                return <img src={image.src} alt={image.alt} key={image.id} />
            })}
        </div>
    )
}

export default ImageGallery;
