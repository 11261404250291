import heroImage from '../assets/images/other/home-closing-signing.jpg';
import homeTransactionImage from '../assets/images/other/home-transaction.jpg';

export const data = {
    viewTitle: 'Bold Results',
    hero: {
        image: heroImage,
        imageAlt: 'Document being signed for home transaction',
        spokenHeadline: 'Results That Hit Home',
        headline: 'Results That Hit Home',
        headlinePos: 'bottomLeft',
        headlineColor: '#B09032',
        headlineShadowColor: '#04386D'
    },
    leadParagraph: 'As top REALTORS® with more than two decades of experience, Bold Realty is committed to delivering elite performance so you can experience elite results.  Take a look below at what our customers are saying and check out our recent closings.',
    contactUsBlock: {
        contactUsImage: {
            image: homeTransactionImage,
            imageAlt: 'home closing transaction'
        },
        contactUsSpan1: 'If you are ready to start the home buying process we can make it easy!  ',
        contactUsButtonText: 'Contact Us',
        screenReaderText: 'Contact Us Button. Press the Enter key to navigate to our Contact Us form.',
        contactUsSpan2: ' for a confidential no-obligation consultation to discuss your unique circumstances.'
    }
}
