import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import Home from './views/Home/Home';
import About from './views/About/About';
import Results from './views/Results/Results';
import Buyers from './views/Buyers/Buyers';
import Sellers from './views/Sellers/Sellers';
import TermsOfService from './views/TermsOfService/TermsOfService';
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy';
import SiteMap from './views/SiteMap/SiteMap';
import NotFound from './views/NotFound/NotFound';
import QuestionsToAskEveryRealEstateAgent from './views/ResourcesHTML/QuestionsToAskEveryRealEstateAgent/QuestionsToAskEveryRealEstateAgent';

const App = () => {
    return (
        <BrowserRouter>
            <Layout>
                <Switch>
                    <Route path='/' exact>
                        <Home />
                    </Route>
                    <Route path='/about'>
                        <About />
                    </Route>
                    <Route path='/results/:activeTab?'>
                        <Results />
                    </Route>
                    <Route path='/buyers'>
                        <Buyers />
                    </Route>
                    <Route path='/sellers'>
                        <Sellers />
                    </Route>
                    <Route path='/terms-of-service'>
                        <TermsOfService />
                    </Route>
                    <Route path='/privacy-policy'>
                        <PrivacyPolicy />
                    </Route>
                    <Route path='/questions-to-ask-every-real-estate-agent-before-hiring-them'>
                        <QuestionsToAskEveryRealEstateAgent />
                    </Route>
                    <Route path='/sitemap'>
                        <SiteMap />
                    </Route>
                    <Route>
                        <NotFound />
                    </Route>
                </Switch>
            </Layout>
        </BrowserRouter>
    );
}

export default App;
