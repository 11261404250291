import React from 'react';
import { Link } from 'react-router-dom';

import SideNavPanel from '../../components/Navigation/SideNavPanel/SideNavPanel';
import Accordion from '../../components/UI/Accordion/Accordion';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import classes from './StickyNavContent.module.css';

const StickyNavContent = ( props ) => {
    const windowDimensions = useWindowDimensions();

    return (
        <div
            className={classes.StickyNavContent}
        >
            <SideNavPanel
                viewName={props.view}
                mainContent={props.mainContent}
            />
            <div className={classes.Content}>
                {props.leadContent &&
                    <div className={classes.LeadContent}>
                        <h1>{props.leadContent.name}</h1>
                        {Object.keys(props.leadContent.content).map(
                            (leadContentKey) => {
                                const leadCont = props.leadContent.content[leadContentKey];
                                return (leadCont.meta === 'paragraph') && leadCont.content.map((paragraph, paragraphIndex) =>
                                    <p key={paragraphIndex}>{paragraph}</p>
                                )
                            }
                        )}
                    </div>
                }
                {
                    Object.keys(props.mainContent).map(
                        (sectionKey) => {
                            const section = props.mainContent[sectionKey];
                            return <section
                                id={section.hash}
                                className={classes.Section}
                                key={sectionKey}>
                                <h2>{section.displayName}</h2>
                                {section.subsections && Object.keys(section.subsections).map(
                                    (subsectionId, subsectionIndex) => {
                                        const subsection = section.subsections[subsectionId];
                                        return <section
                                            id={subsection.hash}
                                            className={classes.Subsection}
                                            key={subsectionIndex}>
                                            {subsection.displayName && <h3>{subsection.displayName}</h3>}
                                            <div className={classes.SubsectionContent}>
                                                {Object.keys(subsection.content).map(
                                                    (contentId, subsectionContentIndex) => {
                                                        const cont = subsection.content[contentId];
                                                        if (cont.meta === 'paragraph') {
                                                            return cont.content.map(
                                                                (paragraph, paragraphIndex) => <p key={paragraphIndex}>{paragraph}</p>
                                                            )
                                                        }
                                                        else if (cont.meta === 'heading') {
                                                            return <h4 key={subsectionContentIndex}>{cont.content}</h4>
                                                        }
                                                        else if (cont.meta === 'boldParagraph') {
                                                            return <p key={subsectionContentIndex}><b>{cont.content}</b></p>
                                                        }
                                                        else if (cont.meta === 'boldItalicParagraph') {
                                                            return <p key={subsectionContentIndex}><b><i>{cont.content}</i></b></p>
                                                        }
                                                        else if (cont.meta === 'unorderedList') {
                                                            return <ul key={subsectionContentIndex}>
                                                                {cont.content.map(
                                                                    (listItem, listItemIndex) => <li key={listItemIndex}>{listItem}</li>
                                                                )}
                                                            </ul>
                                                        }
                                                        else if (cont.meta === 'files') {
                                                            return (
                                                                <ul className={classes.FileList} key={subsectionContentIndex}>
                                                                    {Object.keys(cont.content).map(
                                                                        (itemId, itemIndex) => {
                                                                            const item = cont.content[itemId];
                                                                            if (item.meta === 'file') {
                                                                                const file = item.file;
                                                                                return (
                                                                                    <li key={itemIndex}>
                                                                                        <div className={classes.File}>
                                                                                            <img src={file.image} alt={file.name} />
                                                                                            <b className={classes.FileName}>{file.name}</b>
                                                                                            <div className={classes.FileLinks}>
                                                                                                <a
                                                                                                    href={file.pdfFile}
                                                                                                    target='_blank'
                                                                                                    rel='noopener noreferrer'
                                                                                                    aria-label={file.screenReaderPdfAnnouncement}
                                                                                                >
                                                                                                    PDF
                                                                                                </a>
                                                                                                <b>|</b>
                                                                                                <Link
                                                                                                    to={file.htmlTo}
                                                                                                    target='_blank'
                                                                                                    rel='noopener noreferrer'
                                                                                                    aria-label={file.screenReaderHtmlAnnouncement}
                                                                                                >
                                                                                                    Web Page
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return null;
                                                                            }
                                                                        }
                                                                    )}
                                                                </ul>
                                                            )
                                                        }
                                                        else if (cont.meta === 'listItemParagraph') {
                                                            return (
                                                                <div className={classes.ListItemParagraph} key={subsectionContentIndex}>
                                                                    {cont.content.map( (para, paragraphIndex) => <p key={paragraphIndex}><span>{cont.listItem}.</span>{para}</p>)}
                                                                </div>
                                                            )
                                                        }
                                                        else if (cont.meta === 'orderedList') {
                                                            return (
                                                                <ol key={subsectionContentIndex}>
                                                                    {cont.content.map( (listItem, listItemIndex) => {
                                                                        return <li key={listItemIndex}>{listItem}</li>
                                                                    })}
                                                                </ol>
                                                            )
                                                        }
                                                        else if (cont.meta === 'orderedListBoldItemParagraph') {
                                                            return (
                                                                <ol key={subsectionContentIndex}>
                                                                    {Object.values(cont.content).map( (item, itemIndex) => {
                                                                        return (
                                                                            <li
                                                                                className={classes.ListItemParagraph}
                                                                                key={itemIndex}>
                                                                                {
                                                                                    item.paragraphs.map( (paragraph, paragraphIndex) => {
                                                                                        return <p key={paragraphIndex}><span>{item.listItem}.</span>{paragraph}</p>
                                                                                    }
                                                                                )}
                                                                                {
                                                                                    <ul>
                                                                                    {item.unorderedListItems && item.unorderedListItems.map( (listItem, listItemIndex) => {
                                                                                        return (
                                                                                            <li
                                                                                                className={classes.ListItemParagraph}
                                                                                                key={listItemIndex}>
                                                                                                <p><span>{listItem.heading}.</span>{listItem.paragraph}</p>
                                                                                            </li>
                                                                                        )
                                                                                    })}
                                                                                    </ul>
                                                                                }
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ol>
                                                            )
                                                        }
                                                        else if (cont.meta === 'accordion') {
                                                            return <Accordion
                                                                config={cont.config}
                                                                viewportWidth={windowDimensions.width}
                                                                key={`${subsectionIndex}-${contentId}`}
                                                            />
                                                        }
                                                        else {
                                                            return null;
                                                        }
                                                    }
                                                )}
                                            </div>
                                        </section>
                                    }
                                )}
                            </section>
                        }
                    )
                }
            </div>
        </div>
    );
}

export default StickyNavContent;
