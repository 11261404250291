import React from 'react';
import { useLocation } from 'react-router-dom';

import SideDrawerNavItem from './SideDrawerNavItem/SideDrawerNavItem';

import classes from './SideDrawerNavItems.module.css';

const SideDrawerNavItems = ({
    exact,
    navItemClicked,
    screenReaderVisible
}) => {
    const {pathname} = useLocation();

    return (
        <ul className={classes.NavigationItems}>
            <SideDrawerNavItem link="/about" exact={exact} clicked={navItemClicked} currentRoute={(pathname === '/about')} readerVisible={screenReaderVisible}>About</SideDrawerNavItem>
            <SideDrawerNavItem link="/results" exact={exact} clicked={navItemClicked} currentRoute={(pathname === '/results')} readerVisible={screenReaderVisible}>Results</SideDrawerNavItem>
            <SideDrawerNavItem link="/buyers" exact={exact} clicked={navItemClicked} currentRoute={(pathname === '/buyers')} readerVisible={screenReaderVisible}>Buyers</SideDrawerNavItem>
            <SideDrawerNavItem link="/sellers" exact={exact} clicked={navItemClicked} currentRoute={(pathname === '/sellers')} readerVisible={screenReaderVisible}>Sellers</SideDrawerNavItem>
        </ul>
    );
}

export default SideDrawerNavItems;
