import React, { forwardRef } from 'react';

import classes from './Button.module.css';

const Button = forwardRef((
    {
        types,
        italicFont = false,
        clicked = () => {},
        screenReaderText = '',
        children
    }, buttonRef) => {

    const handleClick = (event) => {
        clicked(event);
    }

    const buttonClasses = [classes.Button];

    if (!types) {
        buttonClasses.push(classes.Small, classes.Contained);
    }
    else {
        if (types.includes('small')) {
            buttonClasses.push(classes.Small);
        }
        else if (types.includes('large')) {
            buttonClasses.push(classes.Large);
        }
        else {
            buttonClasses.push(classes.Small);
        }

        if (types.includes('text')) {
            buttonClasses.push(classes.Unfilled);
        }
        else if (types.includes('contained')) {
            buttonClasses.push(classes.Contained);
        }
        else {
            buttonClasses.push(classes.Contained);
        }
    }

    return (
        <button
            ref={buttonRef}
            className={buttonClasses.join(' ')}
            onClick={handleClick}
            aria-label={screenReaderText}
            style={{ fontStyle: (italicFont ? 'italic' : 'normal')}}
            type='button'
        >
            {children}
        </button>
    );
});

export default Button;
