import { useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

const useUpdateDocumentTitle = () => {
    const location = useLocation();
    const [viewName, setviewName] = useState('');

    useEffect(() => {
        const viewConfig =
            {
                home: {
                    basePath: '',
                    viewName: 'Welcome Home'
                },
                about: {
                    basePath: 'about',
                    viewName: 'Why Go Bold'
                },
                results: {
                    basePath: 'results',
                    viewName: 'Bold Results'
                },
                buyers: {
                    basePath: 'buyers',
                    viewName: 'Buyer Resources'
                },
                sellers: {
                    basePath: 'sellers',
                    viewName: 'Seller Resources'
                },
                questionsToAskEveryRealEstateAgent: {
                    basePath: 'questions-to-ask-every-real-estate-agent-before-hiring-them',
                    viewName: 'Questions to Ask Every Real Estate Agent'
                },
                privacy: {
                    basePath: 'privacy-policy',
                    viewName: 'Privacy Policy'
                },
                terms: {
                    basePath: 'terms-of-service',
                    viewName: 'Terms of Service'
                },
                sitemap: {
                    basePath: 'sitemap',
                    viewName: 'Site Map'
                },
                404: {
                    viewName: '404'
                }
            }

        const translateRoutePathToName = (location) => {
            let updatedViewName;

            const pathNameWithoutLeadingSlash = (location.pathname.indexOf('/') === 0)
                ? location.pathname.substring(1)
                : location.pathname;

            let pathArray = pathNameWithoutLeadingSlash.split('/', 1);

            let basePath;
            if (pathArray && pathArray.length > 0) {
                basePath = pathArray[0];
            }

            Object.values(viewConfig).forEach(configItem => {
                if (basePath === configItem.basePath) {
                    updatedViewName = configItem.viewName;
                }
            })
            updatedViewName = (!updatedViewName) ? '404' : updatedViewName;
            setviewName(updatedViewName);
        }

        translateRoutePathToName(location);
    }, [location]);



    document.title = viewName + ' - Bold Realty';
};

export default useUpdateDocumentTitle;
