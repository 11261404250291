import React from 'react';

import { data } from '../../../data/QuestionsToAskEveryAgent';

import classes from './QuestionsToAskEveryRealEstateAgent.module.css';

const QuestionsToAskEveryRealEstateAgent = ( props ) => {

    return (
        <div className={classes.QuestionsToAskEveryRealEstateAgent}>
            <div className={classes.LeadImageContainer}>
                <img
                    className={classes.LeadImage}
                    src={data.leadImage}
                    alt={data.leadImageAltText}
                />
            </div>
            <div className={classes.Contacts}>
                <b>CarynKommers@gmail.com
                </b>
                <b>602-373-0115
                </b>
            </div>
            <h1>Questions to Ask <u>Every</u> Real Estate Agent Before Hiring Them</h1>
            <div className={classes.LeadText}>
                {
                    data.leadText.map( paragraphContent => {
                        return <p>{paragraphContent}</p>;
                    })
                }
                <span className={classes.LeadTextSignature}>-- Caryn</span>
            </div>
            <div className={classes.MainContent}>
                {
                    Object.keys(data.sections).map(sectionKey => {
                        const section = data.sections[sectionKey];
                        return Object.keys(section.subsections).map(subsectionKey => {

                            const subsection = section.subsections[subsectionKey];
                            return Object.keys(subsection.content).map(contentKey => {

                                const subsectionContent = subsection.content[contentKey];

                                if (subsectionContent.meta === 'heading') {
                                    return <h2>{subsectionContent.content}</h2>
                                }
                                else if (subsectionContent.meta === 'paragraph') {
                                    if (subsectionContent.composite) {
                                        return (
                                            <p>
                                                {subsectionContent.content.map(cont => {
                                                    if (cont.type === 'span') {
                                                        return <span>{cont.content}</span>
                                                    }
                                                    else if (cont.type === 'bold') {
                                                        return <b>{cont.content}</b>
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                })}
                                            </p>
                                        );
                                    }
                                    else {
                                        return subsectionContent.content.map( para => {
                                            return <p>{para}</p>
                                        })
                                    }
                                }
                                else if (subsectionContent.meta === 'unorderedList') {
                                    return <ul className={classes.ListNoBullets}>
                                        {
                                            subsectionContent.content.map(listItem => {
                                                return <li>{listItem}</li>
                                            })
                                        }
                                    </ul>;
                                }
                                else {
                                    return null;
                                }
                            })
                        })
                    })
                }
            </div>
        </div>
    );
}

export default QuestionsToAskEveryRealEstateAgent;
