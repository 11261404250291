import jimCarynPhoto from '../assets/images/team/Jim-and-Caryn-Kommers.jpg';
import jodyPhoto from '../assets/images/team/Kuhman.jpg';
import tammyPhoto from '../assets/images/team/Tammy Pugh.jpg';
import equalHousingLogo from '../assets/images/icons/housing-logo.png';
import realtorLogo from '../assets/images/icons/realtor-logo.png';
import mlsLogo from '../assets/images/icons/mls-logo-transparent.png';
import companyCardLogo from '../assets/images/logos/bold-card-logo.jpg';

export const data = {
    viewTitle: 'Welcome Home!',
    mainHeading: 'Welcome to Bold Realty',
    requiredLogoGroup: [
        {
            id: 0,
            src: equalHousingLogo,
            alt: 'Equal Housing Opportunity logo'
        },
        {
            id: 1,
            src: realtorLogo,
            alt: 'Realtor logo'
        },
        {
            id: 2,
            src: mlsLogo,
            alt: 'MLS logo'
        }
    ],
    testimonialSlider: {
        header: 'What My Clients Are Saying...'
    },
    realtors: [
        {
            id: 0,
            photo: jimCarynPhoto,
            alt: 'Realtors Caryn and Jim Kommers',
            name: 'Jim & Caryn Kommers'
        },
        {
            id: 1,
            photo: jodyPhoto,
            alt: 'Realtors Jody and Tom Kuhman',
            name: 'Jody & Tom Kuhman'
        },
        {
            id: 2,
            photo: tammyPhoto,
            alt: 'Realtor Tammy Pugh',
            name: 'Tammy Pugh'
        }
    ],
    intro: {
        paragraphs: [
            {
                id: 0,
                text: 'If you are in the market to buy or sell a home in the greater Phoenix real estate market, you’ve come to the right place. Designed with buyers and sellers just like you in mind, our website has plenty of information to make your transaction smooth. From first-time homebuyers to seasoned investors who have bought and sold property for decades, it would be our great pleasure to assist you with all your real estate needs, big and small. As top Realtors® with more than two decades of experience, at Bold Realty we are committed to delivering excellent performance so you can experience excellent results.'
            },
            {
                id: 1,
                text: 'With hundreds of communities offering just about any kind of lifestyle or property you can imagine, you are sure to find the Phoenix area home that not only meets your needs but also exceeds your expectations.  The purchase or sale of real estate is one of the biggest decisions and events of your lifetime. Place your trust in a knowledgeable and experienced real estate team who can ensure your best interests are being promoted and protected at all times. Whether you are looking to sell your real estate or would like to browse the many Phoenix Metro homes for sale currently available to you, at Bold Realty we stand ready to assist you. We look forward to hearing from you and welcome the opportunity to earn your trust and business. Let’s get started today!'
            }
        ]
    },
    testimonialCarousel: {
        leadText: 'From My Clients...'
    },
    companyLogo: companyCardLogo
}
