import React from 'react';

import emailIcon from '../../../../assets/images/icons/email-black-24.png';
import phoneIcon from '../../../../assets/images/icons/phone-black-24.png';
import classes from './TeamCard.module.css';

const TeamCard = (props) => {
    const { id, imageSrc, imageAlt, members, bio, phoneNumber, contactName, spokenPhone, phoneStr, email, spokenEmail } = props.memberDetails;
    const screenReaderPhoneContact = `call ${contactName} at ${spokenPhone}`;
    const screenReaderEmailContact = `email ${contactName} at ${spokenEmail}`;

    return (
        <div className={classes.TeamCard}>
            <img
                className={classes.MemberPhoto}
                src={imageSrc}
                alt={imageAlt}
            />
            <div className={classes.MemberDetails}>
                {
                    members && (members.length > 1
                        ? members.map(member => (
                            <div
                                className={classes.Multiple}
                                key={member.id}
                            >
                                <span className={classes.BoldText}>{member.name},</span>
                                <span className={classes.BoldItalicText}>{member.title}</span>
                            </div>
                        ))
                        : (
                            <div
                                className={classes.Single}
                                key={id}
                            >
                                <span className={classes.BoldText}>{members[0].name}</span>
                                <span className={classes.BoldItalicText}>{members[0].title}</span>
                            </div>
                        )
                    )
                }
                <p>{bio}</p>
                <div className={classes.MemberContacts}>
                    <div className={classes.MemberContactInfo}>
                        <img
                            src={phoneIcon}
                            alt="phone"
                        />
                        <a
                            href={'tel:' + phoneNumber}
                            aria-label={screenReaderPhoneContact}
                        >
                            {phoneStr}
                        </a>
                    </div>
                    <div className={classes.MemberContactInfo}>
                        <img
                            src={emailIcon}
                            alt="email"
                        />
                        <a
                            href={'mailto:' + email}
                            aria-label={screenReaderEmailContact}
                        >
                            {email}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamCard;
