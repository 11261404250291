import React, { useState, useRef, useEffect } from 'react';

import { ChevronRight } from '../Icons/Icons';

import classes from './Accordion.module.css';

const Accordion = (
    {
        config,
        focusable = true,
        viewportWidth
    }
) => {
    const [showPanel, setShowPanel] = useState(false);
    const [panelButtonHovered, setPanelButtonHovered] = useState(false);
    const [panelButtonFocused, setPanelButtonFocused] = useState(false);
    const buttonRef = useRef();
    const panelRef = useRef();
    const accordionRef = useRef();

    const {
        id,
        name,
        buttonText,
        panelContent,
        nested
    } = config;

    const spokenPanelContent = panelContent
        ? Object.keys(panelContent).map(contentKey => {
            const contentArray = panelContent[contentKey].content;
            return contentArray.join(' ');
        })
        : '';

    useEffect(() => {
        const determineNestingLevels = ( config, level = 1 ) => {
            config.nestingLevel = level;
            if (Object.keys(config).includes('nested')) {
                config.nested.forEach(item => {
                    const nextLevel = level + 1;
                    if (item.config.nested) {
                        determineNestingLevels(item.config, nextLevel);
                    }
                    else {
                        item.config.nestingLevel = nextLevel;
                    }
                })
            }
        }

        if (config) {
            determineNestingLevels(config, 1);
        }
    });

    const nestedAccordions = (nested || []).map((nestedAccordion, index) => {
        return <Accordion
                    config={nestedAccordion.config}
                    focusable={showPanel}
                    viewportWidth={viewportWidth}
                    key={index}
                />;
    });

    const handleButtonClick = () => {
        setShowPanel(showPanel => !showPanel);
    }

    const panelToggleButtonClasses = [classes.PanelToggleButton];
    let iconColor;
    if (showPanel) {
        panelToggleButtonClasses.push(classes.PanelToggleButtonActive);
        iconColor = '#0000B0';
    }
    else if (panelButtonHovered  || panelButtonFocused) {
        panelToggleButtonClasses.push(classes.PanelToggleButtonHovered);
        iconColor = '#000000';
    }
    else {
        panelToggleButtonClasses.push(classes.PanelToggleButtonInactive);
        iconColor = '#000000';
    }

    const panelClasses = [classes.Panel];
    if (showPanel) {
        panelClasses.push(classes.PanelOpen);
    }
    else {
        panelClasses.push(classes.PanelClosed);
    }

    const accordionClasses = [classes.Accordion];
    if (showPanel && panelContent) {
        accordionClasses.push(classes.AccordionExpanded);
    }
    else if (showPanel && nested) {
        accordionClasses.push(classes.InnerAccordion);
    }
    else {
        accordionClasses.push(classes.AccordionCollapsed);
    }

    const buttonId = `${name}-${config.nestingLevel}`;

    return (
        <div
            className={accordionClasses.join(' ')}
            ref={accordionRef}
        >
            <button
                id={buttonId}
                tabIndex={focusable ? '0' : '-1'}
                className={panelToggleButtonClasses.join(' ')}
                ref={buttonRef}
                onMouseEnter={(event) => setPanelButtonHovered(true)}
                onMouseLeave={() => setPanelButtonHovered(false)}
                onFocus={() => setPanelButtonFocused(true)}
                onBlur={() => setPanelButtonFocused(false)}
                onClick={() => handleButtonClick()}
                aria-controls={id}
                aria-label={showPanel ? spokenPanelContent : buttonText}
            >
                <div className={classes.PanelToggleButtonIconContainer}>
                    <ChevronRight
                        styleClasses={[ (showPanel ? classes.IconPanelOpen : classes.IconPanelClosed) ]}
                        fillColor={iconColor}
                    />
                </div>
                    <span>{buttonText}</span>
            </button>
            <div
                id={id}
                className={panelClasses.join(' ')}
                ref={panelRef}
                aria-expanded={showPanel}
                aria-labelledby={buttonId}
            >
                <div className={panelContent ? classes.PanelContent : ''}>
                    {panelContent &&
                    Object.keys(panelContent).map( (contentKey, contentIndex) => {
                        const contentItem = panelContent[contentKey];
                        if (contentItem.meta === 'paragraph') {
                            return contentItem.content.map((paragraph, paragraphIndex) => <p key={paragraphIndex}>{paragraph}</p>)
                        }
                        else if (contentItem.meta === 'ordered-list') {
                            return (
                                <ol key={contentIndex}>
                                    {contentItem.content.map((listItem, listItemIndex) => <li key={listItemIndex}>{listItem}</li>)}
                                </ol>
                            )
                        }
                        else {
                            return null;
                        }
                    })}
                    {nestedAccordions}
                </div>
            </div>
        </div>
    );
}

export default Accordion;
