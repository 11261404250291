import React from 'react';
import { useLocation } from 'react-router-dom';

import NavigationItem from './NavigationItem/NavigationItem';
import classes from './NavigationItems.module.css';

const NavigationItems = (props) => {
    const {pathname} = useLocation();

    return (
        <ul className={classes.NavigationItems}>
            <NavigationItem link="/about" currentRoute={(pathname === '/about')}>About</NavigationItem>
            <NavigationItem link="/results" currentRoute={(pathname === '/results')}>Results</NavigationItem>
            <NavigationItem link="/buyers" currentRoute={(pathname === '/buyers')}>Buyers</NavigationItem>
            <NavigationItem link="/sellers" currentRoute={(pathname === '/sellers')}>Sellers</NavigationItem>
        </ul>
    )
}

export default NavigationItems;
