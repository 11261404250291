import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

import classes from './TabItemLink.module.css';

const TabItemLink = ({ tabItemParam, exact, screenReaderText, children }) => {
    const params = useParams();

    return (

            <NavLink
                className={classes.TabItemLink}
                to={{
                    pathname: `/results/${tabItemParam}`,
                    state: { scrollTo: true }
                }}
                exact={exact}
                isActive={() => (params.activeTab === tabItemParam)}
                activeClassName={classes.active}
                aria-label={screenReaderText}
            >
                {children}
            </NavLink>

    );
}

export default TabItemLink;
